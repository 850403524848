const validation = (data, type) => {
    let isError = false;
    let incorrect = {};
    const softSkills = data.softSkills || [];
    const tools = data.tools || [];
    const mainTech = data.mainTech || [];
    const regexNumber =  /^[0-9]*$/; // Regular expression to validate integers

    // Specific validation for "First Name" field
    if (!data.firstName.trim()) {
    incorrect.firstName = "First name cannot be empty.";
    isError = true;
    }

    // Specific validation for "SoftSkills" field
    if (softSkills.length <= 0) {
    incorrect.softSkills = "At least one soft skill is required";
    isError = true;
    }

    

    // Validate Location
    if (!data.location.trim()) {
    incorrect.location = "Location is required";
    isError = true;
    }

    // Validate Experience Description
    if (!data.experienceDescription.trim()) {
    incorrect.experienceDescription = "Experience description is required";
    isError = true;
    }

    // Validate English Level
    if (!data.englishLevel) {
    incorrect.englishLevel = "English level is required";
    isError = true;
    }

    // Validate Role
    if (!data.role) {
    incorrect.role = "Role is required";
    isError = true;
    }

    // Validate Technical Level
    if (!data.technicalLevel) {
    incorrect.technicalLevel = "Technical level is required";
    isError = true;
    }

    // Specific validation for "Salary Expectations" field
    if (!data.salaryExpectations) {
    incorrect.salaryExpectations = "Salary cannot be empty.";
    isError = true;
    } else if (!regexNumber.test(data.salaryExpectations)) {
    incorrect.salaryExpectations = "Only numbers are allowed.";
    isError = true;
    } else if (data.salaryExpectations <= 0) {
    incorrect.salaryExpectations = "Salary must be greater than 0.";
    isError = true;
    }

    // Specific validation for "Years of Experience" field
    if (!data.yearsOfExperience) {
    incorrect.yearsOfExperience = "Years of experience cannot be empty.";
    isError = true;
    } else if (!regexNumber.test(data.yearsOfExperience)) {
    incorrect.yearsOfExperience = "Only numbers are allowed.";
    isError = true;
    }else if (data.yearsOfExperience <= 0) {
    incorrect.yearsOfExperience = "Years of experience must be greater than 0.";
    isError = true;
    }else if (data.yearsOfExperience > 12) {
    incorrect.yearsOfExperience = "Years of experience must be less than 12.";
    isError = true;
    }

    if(tools.length === 0){
    incorrect.tools = "At least one tool is required";
    isError = true;
    }

    if (!data.country.trim()) {
        incorrect.country = "Country is required";
        isError = true;
    }

    if(mainTech.length === 0){
    incorrect.mainTech = "At least one main technology is required";
    isError = true;
    }
    if (!type) {
        if(!data.resume){
            incorrect.cv = "CV is required";
            isError = true;
    }
}

    // You can add more validations for other fields here

    return isError ? incorrect : null;
};

export default validation