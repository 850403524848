import axios from 'axios'
import ACTION_TYPES from '../../actionTypes'


const getOpenJobsForRecruiterSuccess = (openJobs) => {
    return {
        type: ACTION_TYPES.GET_OPEN_JOBS_FOR_RECRUITER_SUCCESS,
        payload: openJobs
    }
}

const getOpenJobsForRecruiterFailure = (error) => {
    return {
        type: ACTION_TYPES.GET_OPEN_JOBS_FOR_RECRUITER_FAILURE,
        payload: error
    }
}
// const { companyId , userId } = data;
export const getOpenJobsForRecruiter = ({userId, companyId}) => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/open-jobs/?userId=${userId}&companyId=${companyId}`)
            const openJobs = response.data
            dispatch(getOpenJobsForRecruiterSuccess(openJobs))
        } catch (error) {
            dispatch(getOpenJobsForRecruiterFailure(error.message))
        }
    }
}


