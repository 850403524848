import { FaRegCheckCircle, FaRegThumbsUp, FaMoneyBillWave, FaRocket } from "react-icons/fa";
import { FiUserPlus, FiFileText, FiCpu } from "react-icons/fi";
import imagen from "../../assets/LANDING/ILLUSTRATION/illus2.jpg";
import imagenOne from "../../assets/LANDING/PNG/01.png";
import { Link } from "react-router-dom";
import EnriqueMagallon from "../../assets/personas/EnriqueMagallon.jpg"
import ErickMartinez from "../../assets/personas/ErickMartinez.jpg"
import JoseHernandez from "../../assets/personas/JoseHernandez.jpg"
import JuanCarlosCatro from "../../assets/personas/JuanCarlosCatro.jpg"
import LuisSoto from "../../assets/personas/LuisSoto.jpg"
import RicardoMedrano from "../../assets/personas/RicardoMedrano.jpg"
import Carousel from "../../components/Carousel/Carousel";


import carrusel1 from "../../assets/Logosclientes/AgileEngine.png";
import carrusel2 from "../../assets/Logosclientes/ArkusNexus.png";
import carrusel3 from "../../assets/Logosclientes/DataCo.jpg";
import carrusel4 from "../../assets/Logosclientes/Encora.jpg";
import carrusel5 from "../../assets/Logosclientes/HouseCallPro.png";
import carrusel6 from "../../assets/Logosclientes/Inceptio.jpg";
import carrusel7 from "../../assets/Logosclientes/Integon.jpg";
import carrusel8 from "../../assets/Logosclientes/Michelada.png";
import carrusel9 from "../../assets/Logosclientes/Mirigos.jpg";
import carrusel10 from "../../assets/Logosclientes/Moonshot.jpg";
import carrusel11 from "../../assets/Logosclientes/NESTO.jpg";
import carrusel12 from "../../assets/Logosclientes/Never8.jpg";
import carrusel13 from "../../assets/Logosclientes/Plannet.png";
import carrusel14 from "../../assets/Logosclientes/Syscap.jpg";
import carrusel15 from "../../assets/Logosclientes/T2O.jpg";
import carrusel16 from "../../assets/Logosclientes/Teamwork.jpg";


const HomePage = () => {

  const logos = [
    { src: carrusel1, alt: 'Integon' },
    { src: carrusel2, alt: 'Housecall Pro' },
    { src: carrusel3, alt: 'Encora' },
    { src: carrusel4, alt: 'Arkus Nexus' },
    { src: carrusel5, alt: 'Housecall Pro' },
    { src: carrusel6, alt: 'Encora' },
    { src: carrusel7, alt: 'Arkus Nexus' },
    { src: carrusel8, alt: 'Housecall Pro' },
    { src: carrusel9, alt: 'Encora' },
    { src: carrusel10, alt: 'Arkus Nexus' },
    { src: carrusel11, alt: 'Housecall Pro' },
    { src: carrusel12, alt: 'Encora' },
    { src: carrusel13, alt: 'Arkus Nexus' },
    { src: carrusel14, alt: 'Housecall Pro' },
    { src: carrusel15, alt: 'Encora' },
    { src: carrusel16, alt: 'Arkus Nexus' },
  ];

  return (
    <div className="bg-white text-gray-900">
      {/* Hero Section */}
      <section className="text-center py-20">
        <div className="container mx-auto px-8 flex flex-col md:flex-row items-center">
          <div className="md:w-1/2">
            <h1 className="text-5xl font-extrabold mb-6 leading-tight">
              RysConnect.io, your AI Tech Recruiter
            </h1>
            <p className="text-xl font-light mb-8">
              Hire the best tech talent in LATAM in record time and save on recruitment costs.
            </p>
            <div className="flex space-x-4 text-center">
              <button>
                <Link target="_blank" to="/signup" className="mt-6 bg-blue-600 hover:bg-blue-700 text-white font-bold py-4 px-10 rounded-lg">
                  Get Started Now!
                </Link>
              </button>
              <button>
                <Link target="_blank" to="https://calendly.com/sergio_razo_ryscode/30min" className="bg-gray-100 hover:bg-gray-200 text-gray-700 text-center font-bold py-4 px-10 rounded-lg">
                  Request a Demo
                </Link>
              </button>
            </div>
          </div>
          <div className="md:w-1/2 mt-10 md:mt-0">
            <img src={imagenOne} alt="AI Tech Recruitment" />
          </div>
        </div>
      </section>

      <section>
      <Carousel logos={logos} />
      </section>

      {/* Value Proposition */}
      <section className="bg-white py-20">
        <div className="container mx-auto px-8 grid lg:grid-cols-2 gap-12 items-center">
        <img src={imagen} alt="AI Tech Recruitment" />
          <div className="flex justify-center">
          <div>
            <h3 className="text-3xl font-bold mb-6">Why RysConnect.io?</h3>
            <p className="text-lg mb-8">
              At RysConnect.io, we understand that as a startup or scaleup, you need fast and efficient solutions. Our intelligent AI recruiter learns from your company and vacancies to connect you only with candidates who truly fit. Forget tedious processes and high costs; with us, you save time and money without sacrificing quality.
            </p>
            <button>
              <Link target="_blank" to="/signup" className="mt-6 bg-blue-600 hover:bg-blue-700 text-white font-bold py-4 px-10 rounded-lg">
                Get Started Now!
              </Link>
            </button>
          </div> 
          </div>
        </div>
      </section>

      {/* Key Benefits */}
      <section className="bg-gray-100 py-20">
        <div className="container mx-auto text-center">
          <h3 className="text-3xl font-bold mb-12">Key Benefits</h3>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-12">
            <div className="p-8 bg-white rounded-lg shadow-lg">
              <FaRegCheckCircle className="text-blue-600 text-5xl mb-4 mx-auto" />
              <h4 className="font-semibold text-xl mb-4">Real Results with Advanced AI</h4>
              <p>Our AI evaluates both technical and cultural skills, ensuring a perfect fit.</p>
            </div>
            <div className="p-8 bg-white rounded-lg shadow-lg">
              <FaRegThumbsUp className="text-green-500 text-5xl mb-4 mx-auto" />
              <h4 className="font-semibold text-xl mb-4">Time and Cost Savings</h4>
              <p>Reduce hiring time by up to 50% and save on operational costs.</p>
            </div>
            <div className="p-8 bg-white rounded-lg shadow-lg">
              <FaMoneyBillWave className="text-yellow-500 text-5xl mb-4 mx-auto" />
              <h4 className="font-semibold text-xl mb-4">Access to Our Talent Pool & Evaluation</h4>
              <p>Access our pool of over 25,000 tech professionals in LATAM. Integrate and allow RysConnect.io to evaluate candidates from your website, Hireline, LinkedIn, OCC, etc.</p>
            </div>
            <div className="p-8 bg-white rounded-lg shadow-lg">
              <FaRocket className="text-red-500 text-5xl mb-4 mx-auto" />
              <h4 className="font-semibold text-xl mb-4">Enhanced Candidate Experience</h4>
              <p>Automatic, personalized feedback keeps candidates engaged.</p>
            </div>
          </div>
        </div>
      </section>

      {/* How It Works */}
      <section className="bg-white py-20">
        <div className="container mx-auto text-center">
          <h3 className="text-3xl font-bold mb-12">How It Works</h3>
          <div className="grid lg:grid-cols-3 gap-12">
            <div className="p-8 bg-gray-50 rounded-lg shadow-lg">
              <FiUserPlus className="text-blue-600 text-5xl mb-4 mx-auto" />
              <h4 className="font-semibold text-xl mb-4">Create Your Profile & Post Jobs</h4>
              <p>Sign up in minutes and share the details of your open positions.</p>
            </div>
            <div className="p-8 bg-gray-50 rounded-lg shadow-lg">
              <FiFileText className="text-green-500 text-5xl mb-4 mx-auto" />
              <h4 className="font-semibold text-xl mb-4">Our AI Learns from Your Culture</h4>
              <p>We analyze your company and requirements to understand exactly what you need.</p>
            </div>
            <div className="p-8 bg-gray-50 rounded-lg shadow-lg">
              <FiCpu className="text-red-500 text-5xl mb-4 mx-auto" />
              <h4 className="font-semibold text-xl mb-4">RysConnect Attracts and Connects</h4>
              <p>Our system finds ideal candidates, creates and assigns custom technical and cultural tests.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials */}
      <section className="bg-gray-100 py-20">
  <div className="container mx-auto text-center">
    <h3 className="text-3xl font-bold mb-12">Customer Testimonials</h3>
    <div className="grid lg:grid-cols-2 gap-12">
      {/* Existing testimonials */}
      <div className="bg-white p-8 rounded-lg shadow-lg flex items-center space-x-6">
        <img src="https://i.pinimg.com/474x/7e/2e/12/7e2e129ddb43977eb8d2c9c1c9e4a6f1.jpg" alt="María G." className="rounded-full w-24 h-24 object-cover" />
        <div className="text-left">
          <p className="text-lg italic mb-4">"Thanks to RysConnect.io, we hired a senior developer in less than a week. An innovative and efficient solution!"</p>
          <h5 className="font-bold">— María G., CEO of TechStart</h5>
        </div>
      </div>
      <div className="bg-white p-8 rounded-lg shadow-lg flex items-center space-x-6">
        <img src="https://media.istockphoto.com/id/1347005975/es/foto/retrato-de-un-joven-musulm%C3%A1n-serio-mirando-a-la-c%C3%A1mara.jpg?s=612x612&w=0&k=20&c=2Bc-M2bRJ52_1mQSh0ywJURjFaa1zuUssyE5OcB7jZQ=" alt="Juan P." className="rounded-full w-24 h-24 object-cover" />
        <div className="text-left">
          <p className="text-lg italic mb-4">"The process was simple, and I received instant feedback. RysConnect.io truly cares about candidates."</p>
          <h5 className="font-bold">— Juan P., Full Stack Developer</h5>
        </div>
      </div>

      {/* New testimonials from candidates */}
      <div className="bg-white p-8 rounded-lg shadow-lg flex items-center space-x-6">
        <img src="https://img.freepik.com/foto-gratis/hombre-tomando-selfie_23-2148518145.jpg" alt="Carlos Jimenez" className="rounded-full w-24 h-24 object-cover" />
        <div className="text-left">
          <p className="text-lg italic mb-4">"My experience with RysConnect was excellent. They were always very attentive throughout the process, demonstrating a very human approach. The interview process was clear and transparent at all times. I would definitely recommend their services."</p>
          <h5 className="font-bold">— Carlos Jimenez, Automation & IA Analyst</h5>
        </div>
      </div>
      <div className="bg-white p-8 rounded-lg shadow-lg flex items-center space-x-6">
        <img src="https://media.gq.com.mx/photos/609c0fdeee4372271f0b9056/4:3/w_2664,h_1998,c_limit/salir%20guapo%20en%20fotos-605380757.jpg" alt="Omar Aguilar" className="rounded-full w-24 h-24 object-cover" />
        <div className="text-left">
          <p className="text-lg italic mb-4">"The process with RysConnect was very fast and transparent. I am very grateful for the support they provided, even after the hiring, answering all my questions. It was a very professional experience, and I would definitely trust them again."</p>
          <h5 className="font-bold">— Omar Aguilar, Sr DevOps Engineer</h5>
        </div>
      </div>
      <div className="bg-white p-8 rounded-lg shadow-lg flex items-center space-x-6">
        <img src={JuanCarlosCatro} alt="Juan Carlos Castro" className="rounded-full w-24 h-24 object-cover" />
        <div className="text-left">
          <p className="text-lg italic mb-4">"RysConnect provided me with an exceptional experience. Everything was very clear and transparent, and I felt supported at every stage of the process. Without a doubt, it has been one of the best hiring experiences I have had. I am very satisfied with their service."</p>
          <h5 className="font-bold">— Juan Carlos Castro, Technical Writer</h5>
        </div>
      </div>
      <div className="bg-white p-8 rounded-lg shadow-lg flex items-center space-x-6">
        <img src={EnriqueMagallon} alt="Enrique Magallon" className="rounded-full w-24 h-24 object-cover" />
        <div className="text-left">
          <p className="text-lg italic mb-4">"My experience with RysConnect was excellent. From the beginning, they matched me with vacancies that perfectly aligned with my profile and expectations. In less than a month, I was already working at one of the companies I was offered to. The treatment and advice I received were top-notch."</p>
          <h5 className="font-bold">— Enrique Magallon, Staff React Engineer I</h5>
        </div>
      </div>

      {/* New testimonials from clients */}
      <div className="bg-white p-8 rounded-lg shadow-lg flex items-center space-x-6">
        <img src={RicardoMedrano} alt="Ricardo Medrano" className="rounded-full w-24 h-24 object-cover" />
        <div className="text-left">
          <p className="text-lg italic mb-4">"Our experience with RysConnect has been very good. They provided candidates on time and matched the requested profiles. What we liked most was the speed and personalized attention. Congratulations on the work done; they have become our go-to tool."</p>
          <h5 className="font-bold">— Ricardo Medrano, CEO of Inceptio</h5>
        </div>
      </div>
      <div className="bg-white p-8 rounded-lg shadow-lg flex items-center space-x-6">
        <img src={ErickMartinez} alt="Erick Martinez" className="rounded-full w-24 h-24 object-cover" />
        <div className="text-left">
          <p className="text-lg italic mb-4">"The experience with RysConnect was exceptional. What impressed us most was the quality, attention, and communication they provide. Without a doubt, it is the best technological recruitment tool. They helped us enormously during a critical moment when we needed data engineers, developers, and SDETs. They were a great help."</p>
          <h5 className="font-bold">— Erick Martinez, IT Sourcer and External Recruiting Coordinator at Encora</h5>
        </div>
      </div>
      <div className="bg-white p-8 rounded-lg shadow-lg flex items-center space-x-6">
        <img src={LuisSoto} alt="Luis Soto" className="rounded-full w-24 h-24 object-cover" />
        <div className="text-left">
          <p className="text-lg italic mb-4">"RysConnect impressed us with the speed and agility with which they presented candidates for interviews. The personalized attention is something we truly appreciate. Their service is efficient and always on time."</p>
          <h5 className="font-bold">— Luis Soto, Director at Fibotech</h5>
        </div>
      </div>
      <div className="bg-white p-8 rounded-lg shadow-lg flex items-center space-x-6">
        <img src={JoseHernandez} alt="José Hernandez" className="rounded-full w-24 h-24 object-cover" />
        <div className="text-left">
          <p className="text-lg italic mb-4">"RysConnect has been key to finding the best talent for our company. They have demonstrated professionalism and an excellent ability to connect with high-quality candidates. They have been a fundamental pillar in our hiring team."</p>
          <h5 className="font-bold">— José Hernandez, HR Manager LATAM at Teamwork Commerce</h5>
        </div>
      </div>
    </div>
  </div>
</section>

      {/* Final CTA */}
      <section className="text-center py-20">
        <h3 className="text-3xl font-bold mb-6">Start Now and Transform Your Recruitment!</h3>
        <Link target="_blank" to="/signup" className="mt-6 bg-blue-600 hover:bg-blue-700 text-white font-bold py-4 px-10 rounded-lg">
          Get Started Now!
        </Link>
      </section>
    </div>
  );
};

export default HomePage;
