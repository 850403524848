import React, { useState } from 'react';

const CandidateInfo = ({ candidate, vacancies = [] }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedVacancy, setSelectedVacancy] = useState('');

  // Función para mostrar/ocultar el modal
  const toggleModal = () => setShowModal(!showModal);

  // Función para manejar la invitación
  const handleInvite = () => {
    // Lógica para invitar al candidato
    console.log(`Candidate invited to: ${selectedVacancy}`);
    setShowModal(false); // Cierra el modal después de invitar
  };

  return (
    <div className="relative">
      {/* Información del candidato */}
      <div className='flex justify-around flex-wrap max-w-xl'>
        <div>
        <h2 className="text-3xl font-semibold text-gray-900">
        {candidate.firstName || candidate.tbl_user.firstName} {candidate.lastName || candidate.tbl_user.secondName}
      </h2>
      <p className="text-blue-600 text-lg">{candidate.location}. {candidate.country}</p>
      <p className="text-gray-600 text-lg">{candidate.email || candidate.tbl_user.email}</p>
      <p className="text-gray-800 mt-2">{candidate.experienceDescription}</p>
        </div>
      {/* Botón de Invitar alineado a la derecha */}
      <div>

      </div>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <h3 className="text-xl font-semibold mb-4">Select a Vacancy to Invite</h3>

            {/* Select para elegir la vacante */}
            <select
              value={selectedVacancy}
              onChange={(e) => setSelectedVacancy(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-lg mb-4"
            >
              <option value="" disabled>Select a vacancy</option>
              {vacancies.length > 0 ? (
                vacancies.map((vacancy) => (
                  <option key={vacancy.id} value={vacancy.id}>{vacancy.name}</option>
                ))
              ) : (
                <option disabled>No vacancies available</option>
              )}
            </select>

            {/* Botones dentro del modal */}
            <div className="flex justify-end space-x-4">
              <button
                className="bg-gray-400 text-white px-4 py-2 rounded-lg hover:bg-gray-500"
                onClick={toggleModal}
              >
                Cancel
              </button>
              <button
                className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700"
                onClick={handleInvite}
              >
                Invite
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CandidateInfo;