import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { getJobById } from '../../redux/actions/Candidate/getJobById';
import { interviewStageOptions } from '../FormJob/interviewStageOptions';
import { countryOptions } from '../FormJob/countryOptions';
import { technologyOptions } from '../FormJob/technologyOptions';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'dompurify'; 
import validation from '../FormJob/validation';

const VacancyEdit = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const job = useSelector(state => state.job)
    const navigate = useNavigate()
    const user = useSelector(state => state.user) || null;
    const [jobDescription,setJobDescription] = useState('')
    const [responsibilities, setResponsibilities] = useState('')
    const [errors, setErrors] = useState({})
    const [qualificationsAndSkills, setQualificationsAndSkills] = useState('')
    const [formData, setFormData] = useState({
        jobName: '',
        locationAvailability: [],
        salary: '',
        mustHaveTechnologies: [],
        niceToHaveTechnologies: [],
        yearsOfExperience: '',
        seniority: '',
        workingScheme: '',
        jobType: '',
        contractTerm: '',
        englishLevel: '',
        additionalInformation: '',
        interviewStages: [],
        userId: user.id,
        recruiterId: ''
    });
    
    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'bullet' }],
    ]
    
    

    useEffect(() => {

        if(id){
            dispatch(getJobById(id))
        }

    }, []);

    useEffect(() => {
        if (job) {
    
            setFormData({
                jobName: job.jobName,
                locationAvailability: job.locationAvailability,
                salary: job.salary,
                mustHaveTechnologies: job.mustHaveTechnologies,
                niceToHaveTechnologies: job.niceToHaveTechnologies,
                yearsOfExperience: job.yearsOfExperience,
                seniority: job.seniority,
                workingScheme: job.workingScheme,
                jobType: job.jobType,
                contractTerm: job.contractTerm,
                englishLevel: job.englishLevel,
                additionalInformation: job.additionalInformation,
                interviewStages: Array.isArray(job.interviewStages) ? job.interviewStages : job.interviewStages.split(','),
                userId: user.id,
                recruiterId: ''
            });
            setJobDescription(job.jobDescription)
            setResponsibilities(job.responsibilities)
            setQualificationsAndSkills(job.qualificationsAndSkills)
        }
    }, [job]);


    const handleQuillChange = (data,name) =>{
        const sanitizedData = DOMPurify.sanitize(data)
        if(name === 'jobDescription'){
            setJobDescription(sanitizedData)
        }else if(name === 'responsibilities'){
            setResponsibilities(sanitizedData)
        }else if(name === 'qualificationsAndSkills'){
            setQualificationsAndSkills(sanitizedData)
        }
    }
    


    const jobTypeOptions = [
        { value: 'Full-Time', label: 'Full-Time' },
        { value: 'Part-time', label: 'Part-time' },
        { value: 'Hourly', label: 'Hourly' },
    ];

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleMultiSelectChange = (selectedOptions, name) => {
        setFormData({
            ...formData,
            [name]: selectedOptions ? selectedOptions.map(option => option.value) : []
        });
    };

    const handleSingleSelectChange = (selectedOption, name) => {
        setFormData({
            ...formData,
            [name]: selectedOption ? selectedOption.value : ''
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!id) {
            toast.error("Vacancy ID is missing.");
            return;
        }
    
        const err = validation({
            ...formData,
            jobDescription,
            responsibilities,
            qualificationsAndSkills
        })
        if (err === null) {
            try {
                const dataToSend = {
                    ...formData,
                    locationAvailability: Array.isArray(formData.locationAvailability) ? formData.locationAvailability.join(', '): formData.locationAvailability,
                    interviewStages: formData.interviewStages.join(', '),
                    jobDescription: jobDescription,
                    responsibilities: responsibilities,
                    qualificationsAndSkills: qualificationsAndSkills
                };
                const response = await axios.put(`/update-job/${id}`, dataToSend);
                if (response.status === 200) {
                    toast.success('Vacancy updated successfully',{
                        autoClose: 1500,
                        onClose: () => {
                            navigate(`/vacancy-view/${id}`);
                        }
                    });
                }
            } catch (error) {
                if (error.response) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error("An error occurred in the request. Please try again.");
                }
            }
        }else{
            setErrors(err)
            toast.error("First fix the mistakes")
        }
        
    };
    

    return (
        <div className="bg-gray-50 min-h-screen flex items-center justify-center">
        <div className="container mx-auto p-4">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-2xl mx-auto">
            <div className="text-center mb-6">
                <h1 className="text-2xl font-bold text-blue-600">Update your job registration</h1>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700">Job Name <span className="text-red-500">*</span></label>
                    {errors.jobName && (
                    <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                        <span>{errors.jobName}</span>
                    </div>
                    )}

                    <input
                    type="text"
                    name="jobName"
                    placeholder="Job Name"
                    className="w-full border border-gray-300 rounded-lg p-2"
                    value={formData.jobName}
                    onChange={handleChange}
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700">Location Availability <span className="text-red-500">*</span></label>
                    {errors.locationAvailability && (
                    <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                        <span>{errors.locationAvailability}</span>
                    </div>
                    )}              <Select
                    isMulti
                    name="locationAvailability"
                    options={countryOptions}
                    className="w-full border border-gray-300 rounded-lg p-2"
                    value={countryOptions.filter(option => formData.locationAvailability.includes(option.value))}
                    onChange={selectedOptions => handleMultiSelectChange(selectedOptions, 'locationAvailability')}
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700">Salary (USD) <span className="text-red-500">*</span></label>
                    {errors.salary && (
                    <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                        <span>{errors.salary}</span>
                    </div>
                    )}
                    <input
                    type="text"
                    name="salary"
                    placeholder="Salary"
                    className="w-full border border-gray-300 rounded-lg p-2"
                    value={formData.salary}
                    onChange={handleChange}
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700">Must-Have Technologies <span className="text-red-500">*</span></label>
                    {errors.mustHaveTechnologies && (
                    <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                        <span>{errors.mustHaveTechnologies}</span>
                    </div>
                    )}
                    <Select
                    name="mustHaveTechnologies"
                    options={technologyOptions}
                    className="w-full border border-gray-300 rounded-lg p-2"
                    value={technologyOptions.filter(option => formData.mustHaveTechnologies.includes(option.value))}
                    onChange={(selectedOptions) => handleMultiSelectChange(selectedOptions, 'mustHaveTechnologies')}
                    isMulti
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700">Nice-to-Have Technologies</label>
                    <Select
                    name="niceToHaveTechnologies"
                    options={technologyOptions}
                    className="w-full border border-gray-300 rounded-lg p-2"
                    value={technologyOptions.filter(option => formData.niceToHaveTechnologies.includes(option.value))}
                    onChange={(selectedOptions) => handleMultiSelectChange(selectedOptions, 'niceToHaveTechnologies')}
                    isMulti
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700">Years of Experience <span className="text-red-500">*</span></label>
                    {errors.yearsOfExperience && (
                    <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                    <span>{errors.yearsOfExperience}</span>
                </div>
                    )}
                    <select
                    name="yearsOfExperience"
                    className="w-full border border-gray-300 rounded-lg p-2"
                    value={formData.yearsOfExperience}
                    onChange={handleChange}
                    >
                    <option value="" disabled>Select...</option>
                    <option value="6 Months - 1 Year">6 Months - 1 Year</option>
                    <option value="1 Year - 2 Years">1 Year - 2 Years</option>
                    <option value="3 Years - 4 Years">3 Years - 4 Years</option>
                    <option value="5 Years - 6 Years">5 Years - 6 Years</option>
                    <option value="7 Years - 8 Years">7 Years - 8 Years</option>
                    <option value="+9 Years">+9 Years</option>
                    </select>
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700">Seniority <span className="text-red-500">*</span></label>
                    {errors.seniority && (
                    <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                        <span>{errors.seniority}</span>
                    </div>
                    )}
                    <select
                    name="seniority"
                    className="w-full border border-gray-300 rounded-lg p-2"
                    value={formData.seniority}
                    onChange={handleChange}
                    >
                    <option value="" disabled>Select...</option>
                    <option value="Trainee">Trainee</option>
                    <option value="Junior">Junior</option>
                    <option value="Mid Senior">Mid Senior</option>
                    <option value="Senior">Senior</option>
                    <option value="Architect">Architect</option>
                    </select>
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700">Working Scheme <span className="text-red-500">*</span></label>
                    {errors.workingScheme && (
                    <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                        <span>{errors.workingScheme}</span>
                    </div>
                    )}
                    <select
                    name="workingScheme"
                    className="w-full border border-gray-300 rounded-lg p-2"
                    value={formData.workingScheme}
                    onChange={handleChange}
                    >
                    <option value="" disabled>Select...</option>
                    <option value="Remote">Remote</option>
                    <option value="On-site">On-site</option>
                    <option value="Hybrid">Hybrid</option>
                    </select>
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700">Job Type <span className="text-red-500">*</span></label>
                    {errors.jobType && (
                    <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                        <span>{errors.jobType}</span>
                    </div>
                    )}
                    <select
                    name="jobType"
                    className="w-full border border-gray-300 rounded-lg p-2"
                    value={formData.jobType}
                    onChange={handleChange}
                    >
                    <option value="" disabled>Select...</option>
                    <option value="Full-Time">Full-Time</option>
                    <option value="Part-time">Part-time</option>
                    <option value="Hourly">Hourly</option>
                    </select>
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700">Contract Term <span className="text-red-500">*</span></label>
                    {errors.contractTerm && (
                    <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                        <span>{errors.contractTerm}</span>
                    </div>
                    )}
                    <select
                    name="contractTerm"
                    className="w-full border border-gray-300 rounded-lg p-2"
                    value={formData.contractTerm}
                    onChange={handleChange}
                    >
                    <option value="" disabled>Select...</option>
                    <option value="fixed-term">Fixed-term</option>
                    <option value="unfixed-term">Unfixed-term</option>
                    </select>
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700">English Level <span className="text-red-500">*</span></label>
                    {errors.englishLevel && (
                    <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                        <span>{errors.englishLevel}</span>
                    </div>
                    )}
                    <select
                    name="englishLevel"
                    className="w-full border border-gray-300 rounded-lg p-2"
                    value={formData.englishLevel}
                    onChange={handleChange}
                    >
                    <option value="" disabled>Select...</option>
                    <option value="A1">A1</option>
                    <option value="A2">A2</option>
                    <option value="B1">B1</option>
                    <option value="B2">B2</option>
                    <option value="C1">C1</option>
                    <option value="C2">C2</option>
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Job Description <span className="text-red-500">*</span></label>
                    {errors.jobDescription && (
                    <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                    <span>{errors.jobDescription}</span>
                </div>
                    )}
                    <ReactQuill
                    
                    value={jobDescription}
                    onChange={(data)=>handleQuillChange(data,'jobDescription')}
                    className="bg-white text-black rounded-lg h-40 mb-16"
                    modules={{
                        toolbar: toolbarOptions,
                    }}
                    placeholder="Write a description..."
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Responsibilities <span className="text-red-500">*</span></label>
                    {errors.responsibilities && (
                    <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                        <span>{errors.responsibilities}</span>
                    </div>
                    )}

                    <ReactQuill
                    value={responsibilities}
                    onChange={(data)=>handleQuillChange(data,'responsibilities')}
                    className="bg-white text-black rounded-lg h-40 mb-16"
                    modules={{
                        toolbar: toolbarOptions,
                    }}
                    placeholder="Write responsibilities..."
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Qualifications and Skills</label>

                    <ReactQuill
                    value={qualificationsAndSkills}
                    onChange={(data)=>handleQuillChange(data,'qualificationsAndSkills')}
                    className="bg-white text-black rounded-lg h-40 mb-16"
                    modules={{
                        toolbar: toolbarOptions,
                    }}
                    placeholder="Write qualifications and skills..."
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Additional Information</label>
                    <textarea
                    name="additionalInformation"
                    placeholder="Additional Information"
                    className="w-full border border-gray-300 rounded-lg p-2"
                    value={formData.additionalInformation}
                    onChange={handleChange}
                    />
                </div>

                <div className="mb-4">
                <label className="block text-gray-700">Interview Stages (Please select by order.) <span className="text-red-500">*</span></label>
                {errors.interviewStages && (
                    <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                    <span>{errors.interviewStages}</span>
                    </div>
                )}
                <Select
                    name="interviewStages"
                    options={interviewStageOptions}
                    className="w-full border border-gray-300 rounded-lg p-2"
                    value={formData.interviewStages.map(stage => ({ value: stage, label: stage }))}
                    onChange={(selectedOptions) => handleMultiSelectChange(selectedOptions, 'interviewStages')}
                    isMulti
                />
                </div>


                <div className="text-center">
                    <button className="bg-blue-500 text-white py-2 px-4 rounded w-full">Update vacancy</button>
                </div>
            </form>
            </div>
            <ToastContainer />
        </div>
        </div>
    );
};

export default VacancyEdit;