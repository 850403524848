import axios from 'axios'
import ACTION_TYPES from '../../actionTypes'


const getAssessmentsSuccess = (assessments) => {
    return {
        type: ACTION_TYPES.GET_ASSESSMENTS_SUCCESS,
        payload: assessments
    }
}

const getAssessmentsFailure = (error) => {
    return {
        type: ACTION_TYPES.GET_ASSESSMENTS_FAILURE,
        payload: error
    }
}

export const getAssessments = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/get-assessments-on-db`)
            const assessments = response.data
            dispatch(getAssessmentsSuccess(assessments))
        } catch (error) {
            dispatch(getAssessmentsFailure(error.message))
        }
    }
}

