import React, { useEffect } from 'react';
import JobCard from './JobCard/JobCard';


function JobList({jobs}) {

    return (
        <main className="w-3/4 p-4 space-y-4 w-full">
            {jobs?.map((job, index) => (
                
                <JobCard key={index} job={job} /> 
            ))}
        </main>
    );
}

export default JobList;
