import React from 'react';
import { Link } from 'react-router-dom';

const EditButton = ({ candidateId }) => (
  <div className="text-center">
    <Link 
      to={`/edit-profile/${candidateId}`} 
      className="inline-block bg-blue-500 text-white px-6 py-2 rounded-lg shadow hover:bg-blue-700 transition duration-200">
      Edit Profile
    </Link>
  </div>
);

export default EditButton;
