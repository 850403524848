import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyById } from '../../redux/actions/Company/getCompanyById';
import { Link } from 'react-router-dom';



const CompanyProfile = () => {

    const user = useSelector((state) => state.user);
    const company = useSelector((state) => state.company);

    const dispatch = useDispatch();

    useEffect(() => {
        if (user && user.id) {
            dispatch(getCompanyById(user.id));
        }    
    }, [dispatch, user.id]);

    
    if (!company) {
        return (
          <div className="flex items-center justify-center min-h-screen">
            <p className="text-xl font-semibold text-gray-700">Loading company details...</p>
          </div>
        );
      }


    return (
        <div>
            <div className="min-h-screen bg-gray-100 py-12 px-4 md:px-8">
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg p-8">
        <div className="flex items-center space-x-4 mb-8">
          <img src={company.logo} alt="Company Logo" className="h-24 w-24 rounded-full shadow-md" />
          <div>
            <h1 className="text-3xl font-bold text-blue-600">{company.companyName}</h1>
            <p className="text-gray-500">{company.country}</p>
            <p className="text-gray-500">{company.industry} | {company.stage}</p>
          </div>
        </div>

        <div className="space-y-6">
          <div>
            <h2 className="text-2xl font-semibold text-blue-600">About the company</h2>
            <p className="text-gray-700 mt-2">{company.companyDescription}</p>
          </div>
          <div>
            <h2 className="text-2xl font-semibold text-blue-600">Work culture</h2>
            <p className="text-gray-700 mt-2">{company.aboutYourWorkCulture}</p>
          </div>
          <div>
            <h2 className="text-2xl font-semibold text-blue-600">Core values</h2>
            <div className="flex space-x-2 mt-2">
              {company.coreValue.map((value, index) => (
                <span key={index} className="bg-blue-100 text-blue-600 px-3 py-1 rounded-full text-sm font-medium shadow-md">
                  {value}
                </span>
              ))}
            </div>
          </div>
          <div>
            <h2 className="text-2xl font-semibold text-blue-600">Perks and benefits</h2>
            <div className="flex space-x-2 mt-2">
              {company.perksAndBenefits.map((perk, index) => (
                <span key={index} className="bg-green-100 text-green-600 px-3 py-1 rounded-full text-sm font-medium shadow-md">
                  {perk}
                </span>
              ))}
            </div>
          </div>
          <div>
            <h2 className="text-2xl font-semibold text-blue-600">Web site</h2>
            <p className="text-blue-500 mt-2">
              <a href={company.website} target="_blank" rel="noopener noreferrer" className="hover:underline">
                {company?.companyLink}
              </a>
            </p>
          </div>
        </div>
        <div className='m-6 flex justify-center items-center'>
          <Link to={`/edit-company-profile/${company.id}`}>
            <button className="bg-blue-500 text-white px-6 py-3 rounded-2xl shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
                Edit
            </button>
          </Link>
        </div>
        
      </div>
    </div>
        </div>
    );
}

export default CompanyProfile;
