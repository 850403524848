import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import JobTableHeader from '../../../components/JobTable/JobTableHeader';
import JobTableInfoMessage from '../../../components/JobTable/JobTableInfoMessage';
import JobTableBody from '../../../components/JobTable/JobTableBody';
import { getJobsForAdmin } from '../../../redux/actions/Jobs/getJobsForAdmin';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const CompaniesJobsForAdmin = () => {
const { idCompany } = useParams()

const user = useSelector(state => state.user);
const jobsFA = useSelector(state => state.jobsFA);
const dispatch = useDispatch();

useEffect(() => {
    if (user) {
        dispatch(getJobsForAdmin(idCompany));
    }
}, [user, dispatch]);

const handleJobClose = async (jobId) => {
    if (confirm('Are you sure you want to close this vacancy?')) {
    try {
        const result = await axios.put(`/close-job/?jobId=${jobId}`)
        if (result.status === 200) {
        if (result.data.message === 'Update successful') {
            toast.success('Job closed successfully, refreshing!',
            {
                autoClose: 2000,
                onClose: () => {
                dispatch(getJobsForAdmin(idCompany));
                }
            }
            );  
            
        }else{
            toast.error('Nothing was update')
        }
        }
    } catch (error) {
        if (error.response) {
        toast.error(error.response.data.error);
        } else {
        toast.error('An error occurred in the request. Please try again.')
        }
    }
    }else{
        return false;
    }
}

return (
    <div className="container mx-auto mt-8 mb-80 px-4 sm:px-6 lg:px-8">
    <JobTableHeader />
    <JobTableInfoMessage />
    <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden">
        <thead className="bg-gray-100">
            <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Posting Title</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Num. candidates</th>
                <th colSpan={4} className="py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
        </thead>
        <JobTableBody jobs={jobsFA} user={user} handleJobClose={handleJobClose}/>
        </table>
    </div>
    <ToastContainer />
    </div>
);
};

export default CompaniesJobsForAdmin;
