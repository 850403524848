import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ErrorAlert from './ErrorAlert';

const PhoneNumberInput = ({ value, onChange, error }) => (
    <div className='mt-3'>
        <label htmlFor="phoneNumber" className="sr-only">Phone Number</label>
        {error && (
            <ErrorAlert message={error} />
        )}
        <PhoneInput
            country={'us'}
            value={value}
            onChange={onChange}
            inputProps={{
                name: 'phoneNumber',
                required: true,
                autoFocus: true,
            }}
            inputStyle={{
                width: '100%'
            }}
        />
    </div>
);

export default PhoneNumberInput;
