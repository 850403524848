import React from 'react';
import { Link } from 'react-router-dom';

const Modalinfo = ({ candidate }) => {
    return (
        <div className="bg-white rounded-lg shadow-md max-w-lg w-full p-6 mx-auto max-h-screen overflow-y-auto">
            <div className="flex flex-col items-center space-y-4">
                <div className="text-center">
                    <h2 className="text-2xl font-semibold text-gray-800">
                        {candidate.firstName || candidate.tbl_user.firstName} {candidate.lastName || candidate.tbl_user.secondName}
                    </h2>
                    <p className="text-gray-500">{candidate.role}</p>
                    <p className="text-gray-500">{candidate.location}. {candidate.country}</p>
                </div>
                
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="bg-gray-100 p-4 rounded-lg text-center">
                        <p className="text-gray-600">Main Technology</p>
                        <p className="text-gray-800 font-medium">{candidate.mainTech?.join(', ')}</p>
                    </div>
                    <div className="bg-gray-100 p-4 rounded-lg text-center">
                        <p className="text-gray-600">Secondary Technology</p>
                        <p className="text-gray-800 font-medium">{candidate.secondaryTech?.join(', ')}</p>
                    </div>
                    <div className="bg-gray-100 p-4 rounded-lg text-center">
                        <p className="text-gray-600">Seniority</p>
                        <p className="text-gray-800 font-medium">{candidate.technicalLevel}</p>
                    </div>
                    <div className="bg-gray-100 p-4 rounded-lg text-center">
                        <p className="text-gray-600">Years of Experience</p>
                        <p className="text-gray-800 font-medium">{candidate.yearsOfExperience}</p>
                    </div>
                    <div className="bg-gray-100 p-4 rounded-lg text-center">
                        <p className="text-gray-600">Role</p>
                        <p className="text-gray-800 font-medium">{candidate.role}</p>
                    </div>
                    <div className="bg-gray-100 p-4 rounded-lg text-center">
                        <p className="text-gray-600">English Level</p>
                        <p className="text-gray-800 font-medium">{candidate.englishLevel}</p>
                    </div>
                    <div className="bg-gray-100 p-4 rounded-lg text-center">
                        <p className="text-gray-600">Work Scheme</p>
                        <p className="text-gray-800 font-medium">{candidate.workScheme}</p>
                    </div>
                    <div className="bg-gray-100 p-4 rounded-lg text-center">
                        <p className="text-gray-600">CV</p>
                        <Link to={candidate.resume} target="_blank">
                            <button className='p-2 w-auto bg-blue-600 text-white rounded-lg'>View resume</button>
                        </Link>
                    </div>
                    <div className="bg-gray-100 p-4 rounded-lg text-center">
                        <p className="text-gray-600">Salary Expectation (USD)</p>
                        <p className="text-gray-800 font-medium">{candidate.salaryExpectations}</p>
                    </div>
                </div>
                <Link target='_blank' rel='noopener' to={`/candidate-detail/${candidate.id}`} className="bg-blue-500 text-white px-4 py-2 rounded-lg mt-4">View full profile</Link>              
            </div>
        </div>
    );
}

export default Modalinfo;
