import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const statusOptions = [
    { value: 'pending', label: 'Pendiente' },
    { value: 'contacted', label: 'Contactado' },
    { value: 'applied', label: 'Postulado' }
];

const customStyles = {
control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? '#2563EB' : provided.borderColor,
    boxShadow: state.isFocused ? '0 0 0 1px #2563EB' : provided.boxShadow,
    '&:hover': {
        borderColor: state.isFocused ? '#2563EB' : provided.borderColor,
    },
    }),
option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#2563EB' : state.isFocused ? '#BFDBFE' : provided.backgroundColor,
    color: state.isSelected ? 'white' : provided.color,
    '&:hover': {
        backgroundColor: state.isSelected ? '#2563EB' : '#BFDBFE',
        color: state.isSelected ? 'white' : provided.color,
    },
    }),
};

const CompanyJobDetailForAdmin = ({ vacancyName }) => {

const { id } = useParams() //id del trabajo

const user = useSelector(state => state.user)
const [candidates, setCandidates] = useState([]);

useEffect(() => {
    // Fetch data from your API endpoint
    if (user) {
        axios.get(`applications/?jobId=${id}&userId=${user.id}`).then(response => setCandidates(response.data));
    }
}, []);


return (
    <div className="container mx-auto mt-10 mb-auto">
        <h1 className="text-3xl font-bold mb-6 text-center">{vacancyName}</h1>
        <table className="min-w-full bg-white border rounded-lg overflow-hidden">
            <thead className="bg-gray-50">
                <tr>
                <th className="py-3 px-4 text-left">Full name</th>
                <th className="py-3 px-4 text-left">Mail</th>
                <th className="py-3 px-4 text-left">Status of test</th>
                <th className="py-3 px-4 text-left">Score</th>
                <th className="py-3 px-4 text-left">Report</th>
                </tr>
            </thead>
            <tbody>
                {candidates
                .slice() // Crear una copia de la lista para no mutar la original
                .sort((a, b) => (b.application.score || -Infinity) - (a.application.score || -Infinity))
                .map(candidate => (
                    <tr key={candidate.id} className="border-t">
                    <td className="py-3 px-4">{candidate.application.fullName}</td>
                    <td className="py-3 px-4">{candidate.candidate.tbl_user.email}</td>
                    <td className="py-3 px-4">
                        {candidate.application.statusOfTest ? 'Completed' : 'Pending'}
                    </td>
                    <td className="py-3 px-4">{candidate.application.score || 'N/A'}</td>
                    <td className="py-3 px-4">
                        {!candidate.application.score ? 'N/A' : (
                        <Link to={`/admin-reporting-ev/${candidate.candidate.id}/${id}`}>
                            <button className="text-white bg-blue-600 p-2 rounded-xl">
                                View details
                            </button>
                        </Link>
                        )}
                    </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);
};

export default CompanyJobDetailForAdmin;
