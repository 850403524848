import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { updateUserInformation } from '../../redux/actions/User/updateUserInfo';
import axios from 'axios'
import { getCompanyById } from '../../redux/actions/Company/getCompanyById';
import EditCompanyFrom from '../../components/CompleteCompany/EditCompanyFrom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const INITIAL_FORM = {
    companyName: '',
    logo:null,
    country: '',
    location: '',
    companyRepresentative: '',
    nameOfCompanyRepresentative: '',
    industry: '',
    stage: '',
    companyDescription: '',
    aboutYourWorkCulture:'',
    coreValue: [],
    perksAndBenefits:[],
    companyLink: ''
    }

const EditCompanyProfile = () => {

        const { id } = useParams()
        const [form, setForm] = useState(INITIAL_FORM)
        const [coreValueSelect, setCoreValueSelect] = useState('')
        const [perksSelect, setPerksSelect] = useState('')
        const [errors, setErrors] = useState({})
        const company = useSelector((state) => state?.company)
        
        

        const user = useSelector((state) => state?.user)
        const dispatch = useDispatch()
        const navigate = useNavigate()
        const latamCountries = [
        'Argentina', 'Bolivia', 'Brazil', 'Chile', 'Colombia', 'Costa Rica', 'Cuba', 
        'Dominican Republic', 'United States', 'Ecuador', 'El Salvador', 'Guatemala', 'Honduras', 
        'Mexico', 'Nicaragua', 'Panama', 'Paraguay', 'Peru', 'Puerto Rico', 
        'Uruguay', 'Venezuela'
        ];
        
        const options = useMemo(() => {
        const allCountries = countryList().getData();
        return allCountries.filter(country => latamCountries.includes(country.label));
        }, []);
    

        useEffect(() => {
            if (user && user.id) {
                dispatch(getCompanyById(user.id));
            }
        }, [dispatch, user.id]);
        
        useEffect(() => {
            if (company) {
                setForm({
                    companyName: company.companyName,
                    country: company.country,
                    location: company.location,
                    industry: company.industry,
                    stage: company.stage,
                    companyDescription: company.companyDescription,
                    aboutYourWorkCulture: company.aboutYourWorkCulture,
                    coreValue: company.coreValue,
                    perksAndBenefits: company.perksAndBenefits,
                    companyLink: company.companyLink
                });
            }
        }, [company]);

        const handleChange = (event) => {
        const { name, value } = event.target
        setForm({...form, [name]: value})
        }

        const handleCountryChange = (selectedOption) => {
            setForm({ ...form, country: selectedOption.label });
        };



        const handleChangeSelects = (event) => {
            if(!form[event.target.name].includes(event.target.value)){
            if (event.target.name === 'coreValue') {
                setCoreValueSelect(event.target.value)
            }else if (event.target.name === 'perksAndBenefits') {
                setPerksSelect(event.target.value)
            }
            setForm({...form, [event.target.name]:[...form[event.target.name], event.target.value]})
            }
        }

        const onHandleClickTags = ({data, type}) => {
        if (type === 'coreValue') {
            if (form.coreValue.includes(data)) {
            const result = form.coreValue.filter(core => core != data) 
            setForm({...form, coreValue: result})
            setCoreValueSelect('')
            }
        }else if (type === 'perksAndBenefits') {
            if (form.perksAndBenefits.includes(data)) {
            const result = form.perksAndBenefits.filter(perk => perk != data) 
            setForm({...form, perksAndBenefits: result})
            setPerksSelect('')
            }
        }
        }

        const handleForm = async (event) => {
            event.preventDefault();
            const err = validation(form);
        
            if (err === null) {
                try {
                    setErrors({});
                    const formData = {
                        ...form,
                        userId: user.id
                    };
                    const response = await axios.put(`/update-company-information/${id}`, formData);
        
                    if (response.status === 200) {
                        
                            
                        toast.success('Update successfully', {
                            position: "top-center",
                            autoClose: 3000,
                            onClose: () => {
                                navigate('/company-profile');
                            }
                        });
                    
                    }
                } catch (error) {
                    if (error.response) {
                        toast.error(error.response.data.error || 'An error occurred.');
                    } else {
                        toast.error("An error occurred in the request. Please try again.");
                    }
                }
            } else {
                setErrors(err);
                toast.error("First fix the mistakes");
            }
        };
        






    const validation = (data)=>{
        let isError = false
        let incorrect = {}
        let companyName = data.companyName.trim()
        let country = data.country.trim()
        let location = data.location.trim()
        // let companyRepresentative = data.companyRepresentative.trim()
        // let nameOfCompanyRepresentative = data.nameOfCompanyRepresentative.trim()
        let industry = data.industry.trim() //select normal
        let stage = data.stage.trim() //select normal
        // let companyDescription = data.companyDescription.trim()
        // let aboutYourWorkCulture = data.aboutYourWorkCulture.trim()
        let coreValue = data.coreValue
        let companyLink = data.companyLink.trim()
    
        const regexLetters = RegExp(/^[ a-zA-ZñÑáéíóúÁÉÍÓÚ,.]+$/)
        const regexURL =  RegExp(/^(ftp|http|https):\/\/[^ "]+$/)
        
        
            if (!companyName){
                incorrect.companyName = "Company name cannot be empty."
                isError = true
            }else if(!regexLetters.test(companyName)){
                incorrect.companyName = "Only letters"
                isError = true
            }
        
            if (!country){
                incorrect.country = "Country cannot be empty."
                isError = true
            }

            if (!location) {
                incorrect.location = "Location cannot be empty."
                isError = true
            }else if(!regexLetters.test(location)){
                incorrect.location = "Only letters"
                isError = true
            }

            if (!industry){
                incorrect.industry = "Industry cannot be empty."
                isError = true
            }

            if (!stage){
                incorrect.stage = "Stage cannot be empty."
                isError = true
            }

            if (coreValue.length <= 0) {
                incorrect.coreValue = "Core value cannot be empty."
                isError = true
            }

            if (companyLink) {
                if(!regexURL.test(companyLink)){
                incorrect.companyLink = "URL format."
                isError = true
                }
            }
            
        
        return isError ? incorrect : null
    }

    if (!company) {
        return <p>Loading...</p>;
    }      



    return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl w-full space-y-8 bg-white p-10 rounded-xl shadow-lg">
            <div className="text-center">
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                Update your company profile
            </h2>
            </div>
      {/* Aqui va el Form */}
        <EditCompanyFrom
        form={form}
        onHandleClickTags= {onHandleClickTags}
        handleForm={handleForm}
        setForm={setForm}
        errors={errors}
        options={options}
        handleCountryChange={handleCountryChange}
        handleChangeSelects={handleChangeSelects}
        handleChange= {handleChange}
        coreValueSelect= {coreValueSelect}
        perksSelect= {perksSelect}
        />
        </div>
        <ToastContainer />
        </div>
    );
};

export default EditCompanyProfile;
