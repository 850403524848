import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Carousel = ({ logos }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="bg-gradient-to-b from-gray-50 to-gray-100 py-12 mb-12">
      <div className="max-w-7xl mx-auto px-4">
        <Slider {...settings}>
          {logos.map((logo, index) => (
            <div key={index} className="p-4">
              <img
                src={logo.src}
                alt={logo.alt}
                className="mx-auto max-w-full h-24 object-contain rounded-md shadow-md hover:scale-110 transition-transform duration-500 hover:shadow-lg"
              />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default Carousel;

