import React from 'react';
import Select from 'react-select';

const CoreValue = ({errors, form, coreValueSelect, handleChangeSelects, onHandleClickTags }) => {
    return (
        <div className="col-span-1">
        <label className="block text-gray-700">Core Value <span className="text-red-500">*</span></label>
        {errors.coreValue && (
        <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
            <span>{errors.coreValue}</span>
        </div>
        
        )}

        <div className='flex flex-column justify-center items-center flex-wrap'>
                {form?.coreValue.length > 0 && form.coreValue.map((core) => {
                    return (
                    <div key={core} className='bg-blue-600 text-white rounded-xl p-1 m-1 cursor-pointer' onClick={() => onHandleClickTags({data:core, type:'coreValue'})}>
                        <span>{core}</span>
                    </div>
                    )
                })}
        </div>

        <select name="coreValue" className="block w-full p-2 pl-4 pr-12 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white" onChange={handleChangeSelects} value={coreValueSelect}>
        <option value="" disabled>Select...</option>
        <option value="Commitment">Commitment</option>
        <option value="Tolerance">Tolerance</option>
        <option value="Solidarity">Solidarity</option>
        <option value="Responsibility">Responsibility</option>
        <option value="Effort">Effort</option>
        <option value="Respect">Respect</option>
        <option value="Loyalty">Loyalty</option>
        <option value="Adaptability">Adaptability</option>
        <option value="Leadership">Leadership</option>
        <option value="Trust">Trust</option>
        <option value="Generosity">Generosity</option>

        </select>
    </div>
    );
}

export default CoreValue;
