import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { authenticatedFailure, authenticatedSuccess } from '../redux/actions/SignIn/postSignIn';
import { logout } from '../redux/actions/Logout/Logout';
import { setUserErrorNull } from '../redux/actions/Error/setUserErrorNull';

const useAuth = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await axios.get('/verify-token', { withCredentials: true });
        const { id, firstName, lastName, email, userType, isCompleteInfo } = response.data;
        dispatch(authenticatedSuccess({ id, firstName, lastName, email, userType, isCompleteInfo }));
      } catch (error) {
        dispatch(authenticatedFailure(error.message));
        dispatch(setUserErrorNull());
        dispatch(logout());
      } finally {
        setIsLoading(false);
      }
    };

    if (!isAuthenticated) {
      verifyToken();
    } else {
      setIsLoading(false);
    }
  }, [dispatch, isAuthenticated]);

  return { isLoading };
};

export default useAuth;
