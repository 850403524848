import React from 'react';
import DefaultImage from '../../assets/default.jpg';

const JobHeader = ({ job }) => {
  return (
    <div className="flex justify-start items-center mb-4">
      {job.tbl_company.logo ? (
        <img src={job.tbl_company.logo} alt="Company logo" className="h-20 mr-8" />
      ) : (
        <img src={DefaultImage} alt="Default logo" className="h-20 mr-8" />
      )}
      <div>
        <h1 className="text-3xl font-bold text-blue-600">{job.jobName}</h1>
        <p className="text-lg font-bold">{job.companyName || job.tbl_company.companyName}</p>
        <p className="text-lg text-gray-700">{job.salary <= 0 ? '': '$' + job.salary}</p>
        <a href={job.tbl_company.companyLink} className="text-lg text-blue-600">{job.tbl_company.companyLink}</a>
      </div>
    </div>
  );
};

export default JobHeader;