import React from 'react';
import { Link } from 'react-router-dom';

const ApplyButton = ({ handleSubmit, user }) => {
  return (
    <div className="py-12 bg-gray-50 w-full">
      <div className="max-w-6xl mx-auto px-4 text-center">
        <h2 className="text-2xl font-bold text-blue-600 mb-4">Apply Now and Shape Your Future!</h2>
        <p className="text-gray-600">{user.isAuthenticated ? 'A team member will contact you to start the process' : 'To apply for this vacancy you must sign in'}</p>

        {user.isAuthenticated ? (
          <button
            onClick={handleSubmit}
            className="mt-8 px-8 py-3 bg-blue-600 text-white font-bold rounded-full shadow-lg hover:bg-yellow-600 w-3/6"
          >
            Submit Your Application
          </button >
        ) : (
          <Link to="/signin">
            <button className="mt-8 px-8 py-3 bg-blue-600 text-white font-bold rounded-full shadow-lg hover:bg-yellow-600 w-3/6">
              Sign In
            </button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default ApplyButton;