import React from 'react';
import { Link } from 'react-router-dom';


function JobCard({ job }) {
    return (
        <Link className="p-6 bg-white rounded-lg shadow-lg flex flex-col sm:flex-row items-start sm:items-center hover:shadow-xl transition-shadow duration-200" to={`/job-description/${job.id}`}>
            <div className="w-full">
                <div className='flex justify-between items-center'>
                    <h2 className="text-xl font-semibold text-gray-800">{job.jobName}</h2>
                    <span className="font-semibold text-xs px-3 py-1 rounded-full bg-black text-white">{job.tbl_company.companyName}</span>
                </div>
                <hr className='my-3 border-gray-200'/>
                <div className="flex flex-wrap">
                    <div className="flex flex-col w-full sm:w-1/2 md:w-1/3 lg:w-1/4 mb-4">
                        <span className="text-gray-700 font-medium">Technologies</span>
                        <div className='flex flex-wrap'>
                            {job?.mustHaveTechnologies.map((tech, index) => (
                                <span key={index} className="text-gray-500 text-sm mr-2 mb-1 px-2 py-1 bg-gray-100 rounded-lg">{tech}</span>
                            ))}
                        </div>
                    </div>
                    <div className="flex flex-col w-full sm:w-1/2 md:w-1/3 lg:w-1/4 mb-4">
                        <span className="text-gray-700 font-medium">Salary (USD)</span>
                        <span className="text-gray-600">${job.salary}</span>
                    </div>
                    <div className="flex flex-col w-full sm:w-1/2 md:w-1/3 lg:w-1/4 mb-4">
                        <span className="text-gray-700 font-medium">Seniority</span>
                        <span className="text-gray-600">{job.seniority}</span>
                    </div>
                    <div className="flex flex-col w-full sm:w-1/2 md:w-1/3 lg:w-1/4 mb-4">
                        <span className="text-gray-700 font-medium">Working Scheme</span>
                        <span className="text-gray-600">{job.workingScheme}</span>
                    </div>
                    <div className="flex flex-col w-full sm:w-1/2 md:w-1/3 lg:w-1/4 mb-4">
                        <span className="text-gray-700 font-medium">English Level</span>
                        <span className="text-gray-600">{job.englishLevel}</span>
                    </div>
                    <div className="flex flex-col w-full sm:w-1/2 md:w-1/3 lg:w-1/4 mb-4">
                        <span className="text-gray-700 font-medium">Location</span>
                        <span className="inline-block px-3 py-1 border border-blue-400 rounded-full text-blue-500 text-sm">
                            {job.locationAvailability}
                        </span>
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default JobCard;

