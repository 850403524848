import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCandidateById } from '../../redux/actions/Recruiter/getCandidateById';
import Loading from '../../components/ProfileCandidates/Loading';
import ProfileHeader from '../../components/ProfileCandidates/ProfileCard';
import CandidateDetails from '../../components/ProfileCandidates/ProfileDetail';
import EditButton from '../../components/ProfileCandidates/EditButton';

const ProfileCandidates = () => {
  
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const candidate = useSelector(state => state.candidate);

  useEffect(() => {
    if (user && user.id) {
      dispatch(getCandidateById(user.id));
    }
  }, [user.id, dispatch]);

  if (!candidate) {
    return <Loading />;
  }

  return (
    <div className="bg-gray-50 min-h-screen flex items-center justify-center">
      <div className="container mx-auto p-4">
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-3xl mx-auto">
          <div className="text-center mb-6">
            <h1 className="text-2xl font-bold text-gray-900">My Profile</h1>
          </div>
          <ProfileHeader candidate={candidate} />
          <CandidateDetails candidate={candidate} />
          <EditButton candidateId={candidate.id} />
        </div>
      </div>
    </div>
  );
};
export default ProfileCandidates;
