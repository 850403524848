import React from 'react';

const CheatingDetection = ({ cheatingFlag, cheatingDetails }) => {
  return (
    <section className="mb-12">
      <h2 className="text-3xl font-semibold text-gray-700 mb-4 text-center">Cheating Detection</h2>
      <div className="p-6 bg-gray-50 rounded-lg shadow-inner text-center">
        <h3 className="text-xl font-medium text-gray-600 mb-4">Likelihood: {cheatingFlag}</h3>
        <h3 className="text-xl font-medium text-gray-600 mb-4">Details:</h3>
        <ul className="list-disc list-inside bg-gray-200 rounded p-4 text-gray-800 inline-block">
          <li>Leaving tab {cheatingDetails.tab_leaving} times</li>
          <li>Copy & paste: {cheatingDetails.pasted_code}</li>
          <li>Plagiarism: {cheatingDetails.plagiarism}</li>
          <li>Suspicious activity:{cheatingDetails.suspicious_activity ?  <> Detected</> :<> Not detected</>}</li>
        </ul>
      </div>
    </section>
  );
};

export default CheatingDetection;
