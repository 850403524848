import ACTION_TYPES from '../../actionTypes'
import axios from 'axios'


const updateUserInformationSuccess = (userInfo) => {
    return {
        type: ACTION_TYPES.UPDATE_USER_INFORMATION_SUCCESS,
        payload: userInfo
    }
}

const updateUserInformationFailure = (error) => {
    return {
        type: ACTION_TYPES.UPDATE_USER_INFORMATION_FAILURE,
        payload: error
    }
}

export const updateUserInformation = (userId) => {
    return async (dispatch) => {
        try {
            const response =  await axios.post('update-token', {userId})
            const userInfo = response.data
            dispatch(updateUserInformationSuccess(userInfo))
        } catch (error) {
            dispatch(updateUserInformationFailure(error.message))
        }
    }
}

