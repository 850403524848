import React from 'react';
import Select from 'react-select';

const Languages = ({ errors, form, mainTech, handleSelectChange, placeholder, error }) => {
    return (
<div className="col-span-1">
              <label className="block text-gray-700">
                Languages 
              </label>
              <Select
              name="languages"
              isMulti
              value={form.languages.map(language => ({ value: language, label: language }))}
              onChange={handleSelectChange}
              options={[
                { value: 'English', label: 'English' },
                { value: 'Spanish', label: 'Spanish' },
                { value: 'Mandarin', label: 'Mandarin' },
                { value: 'Hindi', label: 'Hindi' },
                { value: 'French', label: 'French' },
                { value: 'Arabic', label: 'Arabic' },
                { value: 'Bengali', label: 'Bengali' },
                { value: 'Portuguese', label: 'Portuguese' },
                { value: 'Russian', label: 'Russian' },
                { value: 'Japanese', label: 'Japanese' },
                { value: 'German', label: 'German' },
                { value: 'Korean', label: 'Korean' },
                { value: 'Italian', label: 'Italian' },
                { value: 'Turkish', label: 'Turkish' },
                { value: 'Vietnamese', label: 'Vietnamese' },
                { value: 'Polish', label: 'Polish' },
                { value: 'Ukrainian', label: 'Ukrainian' },
                { value: 'Dutch', label: 'Dutch' },
                { value: 'Greek', label: 'Greek' },
                { value: 'Czech', label: 'Czech' },
                { value: 'Hungarian', label: 'Hungarian' },
                { value: 'Swedish', label: 'Swedish' },
                { value: 'Finnish', label: 'Finnish' },
                { value: 'Norwegian', label: 'Norwegian' },
                { value: 'Danish', label: 'Danish' },
                { value: 'Romanian', label: 'Romanian' },
                { value: 'Thai', label: 'Thai' },
                { value: 'Indonesian', label: 'Indonesian' },
                { value: 'Malay', label: 'Malay' },
                { value: 'Filipino', label: 'Filipino' },
                { value: 'Hebrew', label: 'Hebrew' },
                { value: 'Persian', label: 'Persian' },
                { value: 'Swahili', label: 'Swahili' },
                { value: 'Zulu', label: 'Zulu' },
                { value: 'Afrikaans', label: 'Afrikaans' },
                { value: 'Urdu', label: 'Urdu' },
                { value: 'Tamil', label: 'Tamil' },
                { value: 'Telugu', label: 'Telugu' },
                { value: 'Marathi', label: 'Marathi' },
                { value: 'Gujarati', label: 'Gujarati' },
                { value: 'Punjabi', label: 'Punjabi' },
                { value: 'Kannada', label: 'Kannada' },
                { value: 'Malayalam', label: 'Malayalam' },
                { value: 'Sinhala', label: 'Sinhala' },
                { value: 'Burmese', label: 'Burmese' },
                { value: 'Khmer', label: 'Khmer' },
                { value: 'Lao', label: 'Lao' },
                { value: 'Mongolian', label: 'Mongolian' },
                { value: 'Nepali', label: 'Nepali' },
                { value: 'Pashto', label: 'Pashto' },
                { value: 'Kurdish', label: 'Kurdish' },
                { value: 'Tibetan', label: 'Tibetan' },
                { value: 'Amharic', label: 'Amharic' },
                { value: 'Hausa', label: 'Hausa' },
                { value: 'Igbo', label: 'Igbo' },
                { value: 'Yoruba', label: 'Yoruba' },
                { value: 'Shona', label: 'Shona' },
                { value: 'Xhosa', label: 'Xhosa' },
              ]}
              className="block w-full"
            />
            </div>
    );
}

export default Languages;
