import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import countryList from 'react-select-country-list';
import { updateUserInformation } from '../../redux/actions/User/updateUserInfo';
import axios from 'axios'
import CompleteCompanyForm from '../../components/CompleteCompany/CompleteCompanyFrom';
import  validation from '../../components/CompleteCompany/validation';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const INITIAL_FORM = {
  companyName: '',
  logo:null,
  country: '',
  location: '',
  companyRepresentative: '',
  nameOfCompanyRepresentative: '',
  industry: '',
  stage: '',
  companyDescription: '',
  aboutYourWorkCulture:'',
  coreValue: [],
  perksAndBenefits:[],
  companyLink: ''
}

const CompleteCompanyProfile = () => {

    const [form, setForm] = useState(INITIAL_FORM)
    const [coreValueSelect, setCoreValueSelect] = useState('')
    const [perksSelect, setPerksSelect] = useState('')
    const [errors, setErrors] = useState({})

    const user = useSelector((state) => state?.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const latamCountries = [
      'Argentina', 'Bolivia', 'Brazil', 'Chile', 'Colombia', 'Costa Rica', 'Cuba', 
      'Dominican Republic', 'United States', 'Ecuador', 'El Salvador', 'Guatemala', 'Honduras', 
      'Mexico', 'Nicaragua', 'Panama', 'Paraguay', 'Peru', 'Puerto Rico', 
      'Uruguay', 'Venezuela'
    ];
    // const options = countryList().getData();
    const options = useMemo(() => {
      const allCountries = countryList().getData();
      return allCountries.filter(country => latamCountries.includes(country.label));
    }, []);
  

    useEffect(() => {
        if (user && user.isCompleteInfo) {
            navigate('/')
        }
    }, [user, navigate])

    const handleChange = (event) => {
      const { name, value } = event.target
      setForm({...form, [name]: value})
    }

    const handleFileChange = (event) => {
      const file = event.target.files[0];
      const allowedExtensions = ['image/jpeg', 'image/png'];
    
      if (file && allowedExtensions.includes(file.type)) {
        setForm({
          ...form,
          logo: file
        });
      } else {
        alert('Please upload a valid image file (JPEG, PNG)');
        event.target.value = null; // Reiniciar el input de archivo
      }
    }
    

    const handleCountryChange = (selectedOption) => {
      setForm({ ...form, country: selectedOption.label });
    };

    const handleChangeSelects = (event) => {
        if(!form[event.target.name].includes(event.target.value)){
          if (event.target.name === 'coreValue') {
            setCoreValueSelect(event.target.value)
          }else if (event.target.name === 'perksAndBenefits') {
            setPerksSelect(event.target.value)
          }
          setForm({...form, [event.target.name]:[...form[event.target.name], event.target.value]})
        }
    }

    const onHandleClickTags = ({data, type}) => {
      if (type === 'coreValue') {
        if (form.coreValue.includes(data)) {
          const result = form.coreValue.filter(core => core != data) 
          setForm({...form, coreValue: result})
          setCoreValueSelect('')
        }
      }else if (type === 'perksAndBenefits') {
        if (form.perksAndBenefits.includes(data)) {
          const result = form.perksAndBenefits.filter(perk => perk != data) 
          setForm({...form, perksAndBenefits: result})
          setPerksSelect('')
        }
      }
    }

    const handleForm = async (event)=>{
      event.preventDefault()
      const err = validation(form)
      //Verifica si hay errores, si no hay simplemente manda 
      if(err === null){
            try {
              setErrors({})
            const formData = new FormData();
            formData.append('userId', user.id);
            formData.append('companyName', form.companyName);
            formData.append('logo', form.logo);
            formData.append('country', form.country);
            formData.append('location', form.location);
            formData.append('companyRepresentative', form.companyRepresentative);
            formData.append('nameOfCompanyRepresentative', form.nameOfCompanyRepresentative);
            formData.append('industry', form.industry);
            formData.append('stage', form.stage);
            formData.append('companyDescription', form.companyDescription);
            formData.append('aboutYourWorkCulture', form.aboutYourWorkCulture);
            form.coreValue.map((core)=> {
              return formData.append('coreValue', core);
            })
            form.perksAndBenefits.map((perks)=> {
              return formData.append('perksAndBenefits', perks);
            })
            formData.append('companyLink', form.companyLink);

            const response = await axios.post('/complete-company-information', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });

            if (response.status === 200) {
              toast.success('If you have any problems after completing the information, please log in again.')
              dispatch(updateUserInformation(user.id))
            }
            setForm(INITIAL_FORM)
            setCoreValueSelect('')
            setPerksSelect('')
            navigate('/')
              
            } catch (error) {
              if (error.response) {
                toast.error(error.response.data.error);
                } else {
                  toast.error("An error occurred in the request. Please try again.");
                }
            }
          
      }else{
          setErrors(err)
          toast.error("First fix the mistakes")
      }
  }





return (
  <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div className="max-w-4xl w-full space-y-8 bg-white p-10 rounded-xl shadow-lg">
      <div className="text-center">
        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
          Complete your company profile
        </h2>
      </div>
      {/* Aqui va el Form */}
      <CompleteCompanyForm
      form={form}
      onHandleClickTags= {onHandleClickTags}
      handleForm={handleForm}
      setForm={setForm}
      errors={errors}
      options={options}
      handleCountryChange={handleCountryChange}
      handleChangeSelects={handleChangeSelects}
      handleFileChange={handleFileChange}
      handleChange= {handleChange}
      coreValueSelect= {coreValueSelect}
      perksSelect= {perksSelect}
      />
    </div>
    <ToastContainer />
  </div>
);
};

export default CompleteCompanyProfile;