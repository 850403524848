import React from "react";

const Pagination = ({ jobsPerPage, totalJobs, paginate, page }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalJobs / jobsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="m-5">
        {page !== 1 ? (
            <button
            className="m-1 border-none w-24 h-6 rounded-lg bg-blue-600 text-white hover:bg-blue-500"
            onClick={() => paginate(page - 1)}
            >
            Previous
            </button>
        ) : null}

        {pageNumbers.map((number) => {
            if (number === page) {
            return (
                <button
                className="m-1 border-none w-12 h-6 rounded bg-blue-400 text-white "
                key={number}
                disabled
                >
                {number}
                </button>
            );
            }
            return (
            <button
                className="m-1 border-none w-12 h-6 rounded bg-blue-600 text-white hover:bg-blue-500"
                key={number}
                onClick={() => paginate(number)}
            >
                {number}
            </button>
            );
        })}
        {page !== pageNumbers.length ? (
            <button
            className="m-1 border-none w-24 h-6 rounded-lg bg-blue-600 text-white hover:bg-blue-500"
            onClick={() => paginate(page + 1)}
            >
            Next
            </button>
        ) : null}
        </div>
    );
};

export default Pagination;
