import axios from 'axios'
import ACTION_TYPES from '../../actionTypes'


const getJobsForInviteCandidateSuccess = (jobs) => {
    return {
        type: ACTION_TYPES.GET_JOBS_FOR_INVITE_CANDIDATE_SUCCESS,
        payload: jobs
    }
}

const getJobsForInviteCandidateFailure = (error) => {
    return {
        type: ACTION_TYPES.GET_JOBS_FOR_INVITE_CANDIDATE_FAILURE,
        payload: error
    }
}

export const getJobsForInviteCandidate = (candidateId, userId) => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/jobs-for-invite-candidate/?candidateId=${candidateId}&userId=${userId}`)
            const jobs = response.data
            dispatch(getJobsForInviteCandidateSuccess(jobs))
        } catch (error) {
            dispatch(getJobsForInviteCandidateFailure(error.message))
        }
    }
}
