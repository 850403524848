import React from 'react';

const CandidateDetails = ({ candidate }) => (
  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
    <div>
      <h3 className="text-gray-500">Role</h3>
      <p className="text-blue-500">{candidate.role}</p>
    </div>
    <div>
      <h3 className="text-gray-500">Seniority</h3>
      <p className="text-gray-900">{candidate.technicalLevel}</p>
    </div>
    <div>
      <h3 className="text-gray-500">Years of experience</h3>
      <p className="text-gray-900">{candidate.yearsOfExperience}</p>
    </div>
    <div>
      <h3 className="text-gray-500">Main Technology</h3>
      <p className="text-blue-500">{candidate.mainTech?.join(', ')}</p>
    </div>
    <div>
      <h3 className="text-gray-500">Secondary Technology</h3>
      <p className="text-blue-500">{candidate.secondaryTech?.join(', ')}</p>
    </div>
    <div>
      <h3 className="text-gray-500">Education</h3>
      <p className="text-gray-900">{candidate.educationalLevel}</p>
    </div>
    <div>
      <h3 className="text-gray-500">Certifications</h3>
      <p className="text-gray-900">{candidate.certifications}</p>
    </div>
    <div>
      <h3 className="text-gray-500">English level</h3>
      <p className="text-gray-900">{candidate.englishLevel}</p>
    </div>
    <div>
      <h3 className="text-gray-500">Other languages</h3>
      <p className="text-gray-900">{candidate.languages?.join(', ')}</p>
    </div>
    <div>
      <h3 className="text-gray-500">Availability for projects</h3>
      <p className="text-blue-500">{candidate.contractType}</p>
    </div>
    <div>
      <h3 className="text-gray-500">Working scheme</h3>
      <p className="text-blue-500">{candidate.workScheme}</p>
    </div>
    <div>
      <h3 className="text-gray-500">Contract terms</h3>
      <p className="text-blue-500">{candidate.contractScheme}</p>
    </div>
    <div>
      <h3 className="text-gray-500">Salary expectations</h3>
      <p className="text-gray-900">{`$${candidate.salaryExpectations}`}</p>
    </div>
  </div>
);

export default CandidateDetails;
