import Select from 'react-select';
import { Link } from 'react-router-dom';

const PendingJobsTable = ({ pendingJobs, assessmentOptions, selectedAssessments, handleSelectChange, handleOpenJob }) => {
    return (
        <table className="min-w-full bg-white">
            <thead>
                <tr>
                    <th className="py-2 text-center border-b">Company</th>
                    <th className="py-2 text-center border-b">Vacancy Name</th>
                    <th className="py-2 text-center border-b">Status</th>
                    <th className="py-2 text-center border-b">Assessments</th>
                    <th className="py-2 text-center border-b">Actions</th>
                </tr>
            </thead>
            <tbody>
                {pendingJobs?.map((job) => (
                    <tr key={job.id} className="text-center">
                        <td className="py-2 px-4 border-b">{job.tbl_company?.companyName}</td>
                        <td className="py-2 px-4 border-b">{job.jobName}</td>
                        <td className="py-2 px-4 border-b text-orange-500">{job.positionStatus}</td>
                        <td className="py-2 px-4 border-b">
                            <Select
                                name={`assessment-${job.id}`}
                                value={assessmentOptions.find((option) => option.value === selectedAssessments[job.id])}
                                options={assessmentOptions}
                                onChange={(selectedOption) => handleSelectChange(job.id, selectedOption)}
                                className="block w-full"
                            />
                        </td>
                        <td className="py-2 px-4 border-b flex justify-center items-center space-x-2">
                            <button
                                className="bg-green-600 px-4 py-1 rounded-md text-white"
                                onClick={() => handleOpenJob(job.id)}
                            >
                                Open
                            </button>
                            <Link to={`/job-description-fa/${job.id}`}>
                                <button className="bg-blue-600 px-4 py-1 rounded-md text-white">Details</button>
                            </Link>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default PendingJobsTable;