import React from 'react';
import Filters from '../../components/JobListComponents/Filters';
import JobList from '../../components/JobListComponents/JobList';

const JobPositionsLayout = ({ currentJobs, filterJobs, resetJobsFilter, setCurrentPage }) => {
    return (
        <div className="flex flex-col lg:flex-row lg:space-x-4">
            <div className="lg:w-1/4 w-full mb-4 lg:mb-0">
                <Filters
                    filterJobs={filterJobs}
                    resetJobsFilter={resetJobsFilter}
                    setCurrentPage={setCurrentPage}
                />
            </div>
            <div className="lg:w-3/4 w-full">
                <JobList jobs={currentJobs} />
            </div>
        </div>
    );
};

export default JobPositionsLayout;