import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

function PrivateRoute({ children, requiredRole, isLoading }) {
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const userRole = useSelector(state => state.user.userType);

  if (isLoading) {
    return <div>Loading...</div>; // O un componente de loading
  }

  if (!isAuthenticated) {
    return <Navigate to="/signin" />;
  }

  if (requiredRole && userRole !== requiredRole) {
    return <Navigate to="/signin" />;
  }

  return children;
}

export default PrivateRoute;
