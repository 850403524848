import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import validation from '../../components/CreateCandidate/validation';
import countryList from 'react-select-country-list';
import { updateUserInformation } from '../../redux/actions/User/updateUserInfo';
import { getCandidateById } from '../../redux/actions/Recruiter/getCandidateById';
import EditCandidateForm from '../../components/CreateCandidate/EditCandidateForm';
import { ToastContainer, toast } from 'react-toastify';
import { Country, State } from 'country-state-city'

const INITIAL_FORM = {
  firstName: '',
  lastName: '',
  email: '',
  location: '',
  experienceDescription: '',
  role: '',
  technicalLevel: '',
  yearsOfExperience: '',
  softSkills: [],
  tools: [],
  mainTech: [],
  secondaryTech: [],
  educationalLevel: '',
  certifications: '',
  englishLevel: '',
  languages: [],
  contractType: '',
  workScheme: '',
  contractScheme: '',
  relocation: '',
  travelAvailability: '',
  salaryExpectations: '',
  currentBenefits: '',
  timeZones: '',
  referencesLink: '',
  country:'',
  resume: null
}

const EditCandidateProfile = () => {

  const { id } = useParams()
  const [form, setForm] = useState(INITIAL_FORM);
  const [errors, setErrors] = useState({});
  
  
  const user = useSelector((state) => state?.user);
  const candidate = useSelector((state) => state?.candidate);
  const error = useSelector((state) => state?.error);
  const [stateOptions, setStateOptions] = useState({})
  const [isStateDisabled, setIsStateDisable] = useState(true)
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const latamCountries = [
    'Argentina', 'Bolivia', 'Brazil', 'Chile', 'Colombia', 'Costa Rica', 'Cuba', 
    'Dominican Republic', 'United States', 'Ecuador', 'El Salvador', 'Guatemala', 'Honduras', 
    'Mexico', 'Nicaragua', 'Panama', 'Paraguay', 'Peru', 'Puerto Rico', 
    'Uruguay', 'Venezuela'
  ];

  //Use memo se utiliza para los calculos innecesarios, guarda respuestas
  const options = useMemo(() => {
    const allCountries = Country.getAllCountries().map(c => {
      const country = {value: c.isoCode,label:c.name}
      return country
    });
    
    
    return allCountries.filter(country => latamCountries.includes(country.label));
  }, []);


  const handleCountryChange = (selectedOption) => {
    setForm({ ...form, country: selectedOption.label, location: '' });
    
    const states = State.getStatesOfCountry(selectedOption.value).map(s => {
      return { value: s.isoCode, label: s.name } 
    }); 

    setStateOptions(states)
    setIsStateDisable(false)
  };

  const handleStateChange = (selectedOption) => {
    setForm({ ...form, location: selectedOption.label });
  };

  

  useEffect(() => {
    if (user && user.id) {
        dispatch(getCandidateById(id));
    }
}, [dispatch, user.id]);

useEffect(() => {
    if (candidate) {
        setForm({
            firstName: candidate.firstName,
            lastName: candidate.lastName,
            email: candidate.email,
            location: candidate.location,
            experienceDescription: candidate.experienceDescription,
            role: candidate.role,
            technicalLevel: candidate.technicalLevel,
            yearsOfExperience: candidate.yearsOfExperience,
            softSkills: candidate.softSkills,
            tools: candidate.tools,
            mainTech: candidate.mainTech,
            secondaryTech: candidate.secondaryTech,
            educationalLevel: candidate.educationalLevel,
            certifications: candidate.certifications,
            englishLevel: candidate.englishLevel,
            languages: candidate.languages,
            contractType: candidate.contractType,
            workScheme: candidate.workScheme,
            contractScheme: candidate.contractScheme,
            relocation: candidate.relocation,
            travelAvailability: candidate.travelAvailability,
            salaryExpectations: candidate.salaryExpectations,
            currentBenefits: candidate.currentBenefits,
            timeZones: candidate.timeZones,
            referencesLink: candidate.referencesLink,
            country: candidate.country,
        });

        if (candidate.country) {
          
          const country = Country.getAllCountries().find(s => s.name === candidate.country)
          

            const states = State.getStatesOfCountry(country.isoCode).map(s => {
                return { value: s.isoCode, label: s.name }
            });

            
            
            setStateOptions(states)
            setIsStateDisable(false)
        }
        
    }
}, [candidate]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "experienceDescription" && value.length > 1200) {
      return;
    }
    setForm({ ...form, [name]: value });
  };
  
  const limitExperienceDescription = (event) => {
    const maxLength = 1200;
    if (event.target.value.length > maxLength) {
      event.target.value = event.target.value.slice(0, maxLength);
    }
  };

  const handleSelectChange = (selectedOptions, { name }) => {
    setForm({ ...form, [name]: selectedOptions.map(option => option.value) });
  }


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedExtensions = ['application/pdf'];
  
    if (file && allowedExtensions.includes(file.type)) {
      setForm({
        ...form,
        resume: file
      });
    } else {
      alert('Please upload a valid file (PDF)');
      event.target.value = null; // Reiniciar el input de archivo
    }
  }



  const handleForm = async (event) => {
    event.preventDefault();
    const err = validation(form, "edit");
    if (err === null) {

      try {
        setErrors({})

      
      const formData = {
        ...form,
        userId: user.id
      }
      
      const response = await axios.put(`/update-candidate-information/${id}`, formData);
      if (response.status === 200) {
        toast.success(`Update successfully`, {
          position: "top-center",
          autoClose: 3000,
          onClose: () => {
              dispatch(updateUserInformation(user.id))
              navigate('/profile-candidates');
          }
        });

      }

        
      } catch (error) {
        if (error.response) {
              toast.error(error.response.data.error, {
                position: "top-center",
                autoClose: 3000,
              });
              
          } else {
            toast.error("An error occurred in the request. Please try again.", {
              position: "top-center",
              autoClose: 3000,
            })
          }
      }
    } else {
      setErrors(err);
      alert("First fix the mistakes");
    }
  }

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl w-full space-y-8 bg-white p-10 rounded-xl shadow-lg">
        <div className="text-center">
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
            Update Candidate Profile
          </h2>
        </div>
        {/* Aqui va el Form */}
        <EditCandidateForm
        options= {options}
        form= {form}
        errors= {errors}
        handleChange= {handleChange}
        limitExperienceDescription= {limitExperienceDescription}
        handleSelectChange= {handleSelectChange}
        handleCountryChange= {handleCountryChange}
        handleFileChange= {handleFileChange}
        handleForm= {handleForm}
        handleStateChange= {handleStateChange}
        isStateDisabled= {isStateDisabled}
        stateOptions= {stateOptions}
        />
      </div>
      <ToastContainer />
    </div>
  );
}

export default EditCandidateProfile;
