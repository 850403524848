import axios from 'axios'
import ACTION_TYPES from '../../actionTypes'


const getJobByIdSuccess = (job) => {
    return {
        type: ACTION_TYPES.GET_JOB_BY_ID_SUCCESS,
        payload: job
    }
}

const getJobByIdFailure = (error) => {
    return {
        type: ACTION_TYPES.GET_JOB_BY_ID_FAILURE,
        payload: error
    }
}

export const getJobById = (id) => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/jobs/${id}`)
            const job = response.data
            dispatch(getJobByIdSuccess(job))
        } catch (error) {
            dispatch(getJobByIdFailure(error.message))
        }
    }
}


