import { useState } from "react";
import DefaultP from '../../assets/default_p.jpg'
import { Link } from "react-router-dom";

const CandidateRecommendationCard = ({ candidate }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleDescription = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="flex flex-col sm:flex-row w-full sm:w-4/5 max-w-5xl bg-white shadow-md rounded-lg overflow-hidden transition-transform transform hover:scale-105">
        {/* Imagen del Candidato */}
        <img src={DefaultP} alt={`${candidate.name}`} className="w-32 h-32 sm:w-36 sm:h-36 object-cover mx-auto sm:m-5 rounded-full" />
        
        {/* Detalles del Candidato */}
        <div className="flex flex-col p-5 w-full">
            <div className="flex flex-col sm:flex-row sm:items-center sm:gap-3">
            {/* Nombre y Nivel */}
            <h2 className="text-lg sm:text-xl font-bold text-center sm:text-left">{candidate.firstName} {candidate.lastName}</h2>
            <span className="bg-red-600 text-white px-3 py-1 rounded-full text-xs sm:text-sm text-center sm:text-left mt-2 sm:mt-0">
                {candidate.technicalLevel}
            </span>
            </div>
            <h3 className="text-gray-500 text-center sm:text-left">{candidate.role}</h3>

            {/* Descripción con ver más/ver menos */}
            <p className="mt-2 text-sm sm:text-base text-center sm:text-left">
            {isExpanded ? candidate.experienceDescription : `${candidate.experienceDescription.substring(0, 100)}...`} 
            <span 
                onClick={toggleDescription} 
                className="text-blue-500 cursor-pointer ml-1"
            >
                {isExpanded ? "See Less" : "See More"}
            </span>
            </p>
            
            {/* Botones */}
            <div className="mt-5 flex flex-col sm:flex-row justify-center sm:justify-end gap-3">
                <Link target='_blank' to={`/candidate-detail/${candidate.id}`}>
                    <button className="text-white bg-blue-600 p-2 rounded-xl">View profile to invite</button>
                </Link>
            </div>
        </div>
        </div>
    );
};

export default CandidateRecommendationCard;