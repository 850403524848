import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const SignInForm = ({ form, onHandleChange, onHandleSubmit }) => {
    
    const [showPassword, setShowPassword] = useState(false); 

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <form className="mt-8 space-y-6" onSubmit={onHandleSubmit}>
            <div className="rounded-md shadow-sm space-y-4">
                <div>
                    <label htmlFor="email" className="sr-only">Email</label>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="relative block w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-blue-400 sm:text-sm"
                        placeholder="Your Email"
                        value={form.email}
                        onChange={onHandleChange}
                    />
                </div>
                <div>
                    <label htmlFor="password" className="sr-only">Password</label>
                    <div className='relative'>
                        <input
                            id="password"
                            name="password"
                            type={showPassword ? "text" : "password"}
                            autoComplete="current-password"
                            required
                            className="relative block w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-blue-400 sm:text-sm"
                            placeholder="Password"
                            value={form.password}
                            onChange={onHandleChange} 
                        />
                        <button
                            type="button"
                            onClick={togglePasswordVisibility}
                            className="absolute inset-y-0 right-0 px-3 text-gray-500 z-20 focus:outline-none"
                            tabIndex="-1"
                        >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <button
                    type="submit"
                    className="relative flex justify-center w-full px-6 py-3 text-sm font-medium text-white bg-blue-500 border border-transparent rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
                >
                    Sign In
                </button>
            </div>
        </form>
    );
};

export default SignInForm;
