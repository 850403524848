import imagenes from '../../../assets/LANDING/ILLUSTRATION/hr-software-flat-style-illustration-design-free-vector.jpg';
import imagenes1 from '../../../assets/LANDING/ILLUSTRATION/flat-illustration-of-a-working-woman-operating-a-computer-at-her-desk-perfect-for-design-elements-from-remote-work-work-from-home-and-online-learning-free-vector.jpg';


const ServiceBorderlessCashflow = () => {


    return(
        <div className="min-h-screen bg-white">
      <header className="flex flex-col items-center justify-center py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="md:w-1/2">
              <h1 className="text-4xl font-bold text-gray-900">
                Streamline Your Contractor Payments with Borderless Cashflow
              </h1>
              <p className="mt-4 text-lg text-gray-600">
                Simplify your administrative workload and focus on what really matters—your business growth. In today's global market, hiring top talent from regions like Latin America involves navigating varied labor laws and managing multiple payment platforms. That's where "Borderless Cashflow" by Rysconnect excels, providing a seamless, comprehensive payment and administrative management solution.
              </p>
              <button className="mt-6 px-4 py-2 bg-blue-600 text-white rounded-md">
                Talk with an expert!
              </button>
            </div>
            <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center">
              <img src={imagenes} alt="Illustration of business management" className="w-full h-auto"/>
            </div>
          </div>
        </div>
      </header>

      <main className="bg-gray-100 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <section>
            <h2 className="text-3xl font-bold text-gray-900 text-center">What Makes Borderless Cashflow Unique?</h2>
            <p className="mt-4 text-lg text-gray-600 text-center">
              Borderless Cashflow isn't just about payments; it's about creating a hassle-free administrative environment where your business can thrive.
            </p>
          </section>

          <header className="flex flex-col items-center justify-center py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="md:w-1/2">
                <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center m-3">
                    <i className="fas fa-file-alt text-4xl text-yellow-500"></i>
                    <h3 className="mt-4 text-xl font-bold">Streamlined Paperwork Management</h3>
                    <p className="mt-2 text-gray-600">
                        We handle all the administrative tasks—from contracts to labor agreements—so you don't have to. Enjoy simplified operations with every contractor through a single, unified service.
                    </p>
                </div>
                <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center m-3">
                    <i className="fas fa-handshake text-4xl text-yellow-500"></i>
                    <h3 className="mt-4 text-xl font-bold">One-Stop Payment Solution</h3>
                    <p className="mt-2 text-gray-600">
                        Pay all your contractors through one platform, regardless of the number they number. One invoice, multiple talents, zero headaches.
                    </p>
                </div>
                <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center m-3">
                    <i className="fas fa-globe text-4xl text-yellow-500"></i>
                    <h3 className="mt-4 text-xl font-bold">Cultural and Legal Expertise</h3>
                    <p className="mt-2 text-gray-600">
                        Armed with a deep understanding of Latin American employment laws and culture, we ensure compliance and smooth operations across borders.
                    </p>
                </div>
            </div>
            <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center ml-3">
              <img src={imagenes1} alt="Illustration of business management" className="w-full h-auto"/>
            </div>
          </div>
        </div>
      </header>

        </div>
      </main>

      <footer className="bg-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-gray-900">Ready to Simplify Your Contractor Management?</h2>
          <p className="mt-4 text-lg text-gray-600">
            Don't get bogged down by the complexities of international hiring and payments. Let Borderless Cashflow take care of the paperwork while you focus on driving your business forward.
          </p>
          <button className="mt-6 px-4 py-2 bg-blue-600 text-white rounded-md">
            Schedule a call
          </button>
        </div>
      </footer>
    </div>
    )
}

export default ServiceBorderlessCashflow