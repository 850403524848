import React, { useState, useEffect } from 'react';
import { FaRegCheckCircle } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const Question = ({ nameOfTest, question, onSubmit, totalQuestions, currentQuestionIndex, setTestCompleted, testCompleted }) => {
  const [answer, setAnswer] = useState({});
  const [timeLeft, setTimeLeft] = useState(1200);
  const navigate = useNavigate();


  useEffect(() => {
    if (timeLeft <= 0) {
      onComplete();
    }

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0)); 
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const onComplete = () => {
    setTestCompleted(true);
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const handleSubmit = () => {
    onSubmit(answer);
    setAnswer({});
  };

  const onHandleRadio = (question, answer) => {
    setAnswer({ question, answer });
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-6">
      <motion.div
        className="bg-white rounded-lg shadow-lg p-8 w-full max-w-screen-lg mx-auto space-y-6"
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 100 }}
        transition={{ duration: 0.5 }}
      >
        {/* Tiempo y Progreso */}
        <div className="flex justify-between items-center mb-4">
          <span className="text-gray-600 text-xl font-medium">
            Tiempo restante: <strong>{formatTime(timeLeft)}</strong>
          </span>
          <span>{nameOfTest === 'psychometricTest' ? 'Psychometric Test' : 'Technical Test' }</span>
        </div>

        {/* Texto de la Pregunta */}
        <h3 className="text-3xl md:text-4xl font-semibold text-center text-gray-800">{question.question}</h3>

        {/* Elección Múltiple u Opción Abierta */}
        {question.type === 'multipleChoice' ? (
          <motion.div
            className="grid grid-cols-1 sm:grid-cols-2 gap-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {question.options.map((option, index) => (
              <label
                key={index}
                className={`bg-gray-50 border border-gray-300 rounded-lg p-4 text-center cursor-pointer 
                w-full h-32 flex items-center justify-center transition duration-200 
                ${answer.answer === option ? 'text-blue-800' : 'hover:bg-blue-200'}`}
                onClick={() => onHandleRadio(question.question, option)}
              >
                <input
                  type="radio"
                  name="multipleChoice"
                  value={option}
                  checked={answer === option}
                  // onChange={() => setAnswer(option)}
                  onChange={() => onHandleRadio(question.question, option)}
                  className="hidden"
                />
                <span className="flex items-center justify-center text-lg font-medium">
                  {option} {answer.answer === option ? <FaRegCheckCircle className='ml-2'/> : null}
                </span>
              </label>
            ))}
          </motion.div>
        ) : (
          <motion.textarea
            className="w-full h-48 p-4 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-600 transition duration-200 text-lg"
            value={answer?.answer || ""}
            // onChange={(e) => setAnswer(e.target.value)}
            onChange={(e) => onHandleRadio(question.question, e.target.value)}
            placeholder="Escribe tu respuesta aquí..."
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          />
        )}

        {/* Botón Enviar */}
        {!testCompleted && (
          <motion.button
          onClick={handleSubmit}
          disabled={!answer.answer}
          className={`w-full bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-6 rounded-lg transition duration-200 text-xl shadow-md 
          ${!answer.answer ? 'opacity-50 cursor-not-allowed' : ''}`}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          {currentQuestionIndex === totalQuestions - 1 && nameOfTest === 'psychometricTest' ? 'Finalizar' : 'Siguiente'}
          
        </motion.button>
        )}
        
      </motion.div>
    </div>
  );
};

export default Question;
