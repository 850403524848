import React from 'react';

const InfoAlert = () => {
  return (
    <div className="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3 mb-10 mt-7" role="alert">
      <p className="font-bold">Informational message</p>
      <p className="text-sm">
        When you enter to answer the test, please enter the same email address with which you registered in Rysconnect.io
      </p>
    </div>
  );
};

export default InfoAlert;
