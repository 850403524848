import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getJobById } from '../../redux/actions/Candidate/getJobById';
import axios from 'axios'
import DefaultImage from '../../assets/default.jpg'
import JobHeader from '../../components/JobDetails/JobHeader';
import JobDetailsGrid from '../../components/JobDetails/JobDetailsGrid';
import JobDescriptionSection from '../../components/JobDetails/JobDescriptionSection';


const JobDescriptionFA = () => {
    const { id } = useParams();
    const job = useSelector(state => state.job)
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

useEffect(() => {
    dispatch(getJobById(id))
}, [id, dispatch]);  


if (!job) {
    return <p>Loading job details...</p>;
}


const handleSubmit = async (e) => {
try {
const obj = {
    jobId: id,
    userId: user.id
}
    const response = await axios.post(`/add-applications`, obj)
    if (response.status === 200) {
        alert('aplication success')
    }
} catch (error) {
if (error.response) {
console.log(error.response.data.error)
alert(error.response.data.error);
} else {
    alert("An error occurred in the request. Please try again.");
}
}

}




return (
  <div className="bg-white pt-8">
    <div className="max-w-7xl mx-auto mb-5">
      <div className="border rounded-lg p-6">
        <JobHeader job={job} />
        <JobDetailsGrid job={job} />
        <JobDescriptionSection job={job} />
      </div>
    </div>
  </div>
);
};


export default JobDescriptionFA