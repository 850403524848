const validation = (data)=>{
    let isError = false
    let incorrect = {}
    let companyName = data.companyName.trim()
    let country = data.country.trim()
    let location = data.location.trim()
    let industry = data.industry.trim() //select normal
    let stage = data.stage.trim() //select normal
    let coreValue = data.coreValue
    let companyLink = data.companyLink.trim()

    const regexLetters = RegExp(/^[ a-zA-ZñÑáéíóúÁÉÍÓÚ,.]+$/)
    const regexURL =  RegExp(/^(ftp|http|https):\/\/[^ "]+$/)
    
    
        if (!companyName){
            incorrect.companyName = "Company name cannot be empty."
            isError = true
        }else if(!regexLetters.test(companyName)){
            incorrect.companyName = "Only letters"
            isError = true
        }
    
        if (!country){
        incorrect.country = "Country cannot be empty."
        isError = true
        }

        if (!location) {
        incorrect.location = "Location cannot be empty."
        isError = true
        }else if(!regexLetters.test(location)){
        incorrect.location = "Only letters"
        isError = true
        }

        if (!industry){
        incorrect.industry = "Industry cannot be empty."
        isError = true
        }

        if (!stage){
        incorrect.stage = "Stage cannot be empty."
        isError = true
        }

        if (coreValue.length <= 0) {
        incorrect.coreValue = "Core value cannot be empty."
        isError = true
        }

        if (companyLink) {
        if(!regexURL.test(companyLink)){
            incorrect.companyLink = "URL format."
            isError = true
        }
        }
        

    return isError ? incorrect : null
}

export default validation