import axios from 'axios'
import ACTION_TYPES from '../../actionTypes'


const getTestsSuccess = (tests) => {
    return {
        type: ACTION_TYPES.GET_TESTS_SUCCESS,
        payload: tests
    }
}


const getTestsFailure = (error) => {
    return {
        type: ACTION_TYPES.GET_TESTS_FAILURE,
        payload: error
    }
}



export const getTests = (jobId) => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/test/?jobId=${jobId}`)
            const tests = response.data
            dispatch(getTestsSuccess(tests))
        } catch (error) {
            dispatch(getTestsFailure(error.message))
        }
    }
}