import axios from 'axios'
import ACTION_TYPES from '../../actionTypes'


const getReportSuccess = (report) => {
    return {
        type: ACTION_TYPES.GET_REPORT_SUCCESS,
        payload: report
    }
}

const getReportFailure = (error) => {
    return {
        type: ACTION_TYPES.GET_REPORT_FAILURE,
        payload: error
    }
}

export const getReport = (candidateId, jobId) => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/reporting-test/${candidateId}/${jobId}`)
            const report = response.data
            dispatch(getReportSuccess(report))
        } catch (error) {
            dispatch(getReportFailure(error.message))
        }
    }
}