import React from 'react';

const SignInIllustration = ({ src, alt }) => {
    return (
        <div className="flex items-center justify-center w-full lg:w-1/2 h-72 lg:h-full bg-white p-4 lg:p-0 shadow-inner">
            <img src={src} alt={alt} className="max-w-full max-h-full rounded-lg shadow-lg" />
        </div>
    );
};

export default SignInIllustration;

