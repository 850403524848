import { createStore, applyMiddleware, compose } from 'redux'
import { thunk } from 'redux-thunk'
import rootReducer from './reducer'

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose


const store = createStore(rootReducer,
    composeEnhancer(
        //Permite hacer peticiones asincronas
        applyMiddleware(thunk))
)

export default store

