import React from 'react';
import Select from 'react-select';

const SoftSkills = ({ errors, form, handleChange, handleSelectChange, placeholder, error }) => {
    return (
<div className="col-span-1">
              <label className="block text-gray-700">
                Soft Skills <span className="text-red-500">*</span>
              </label>
              {errors.softSkills && (
              <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                <span>{errors.softSkills}</span>
              </div>
            )}
              <Select
              name="softSkills"
              isMulti
              value={form.softSkills?.map(skill => ({ value: skill, label: skill }))}
              onChange={handleSelectChange}
              options={[
                { value: 'Communication', label: 'Communication' },
                { value: 'Teamwork', label: 'Teamwork' },
                { value: 'Problem-Solving', label: 'Problem-Solving' },
                { value: 'Time Management', label: 'Time Management' },
                { value: 'Adaptability', label: 'Adaptability' },
                { value: 'Critical Thinking', label: 'Critical Thinking' },
                { value: 'Creativity', label: 'Creativity' },
                { value: 'Leadership', label: 'Leadership' },
                { value: 'Work Ethic', label: 'Work Ethic' },
                { value: 'Interpersonal Skills', label: 'Interpersonal Skills' },
                { value: 'Conflict Resolution', label: 'Conflict Resolution' },
                { value: 'Emotional Intelligence', label: 'Emotional Intelligence' },
                { value: 'Negotiation', label: 'Negotiation' },
                { value: 'Decision Making', label: 'Decision Making' },
                { value: 'Attention to Detail', label: 'Attention to Detail' },
                { value: 'Organizational Skills', label: 'Organizational Skills' },
                { value: 'Stress Management', label: 'Stress Management' },
                { value: 'Patience', label: 'Patience' },
                { value: 'Active Listening', label: 'Active Listening' },
                { value: 'Public Speaking', label: 'Public Speaking' },
                { value: 'Customer Service', label: 'Customer Service' },
                { value: 'Collaboration', label: 'Collaboration' },
                { value: 'Self-Motivation', label: 'Self-Motivation' },
                { value: 'Flexibility', label: 'Flexibility' },
                { value: 'Multitasking', label: 'Multitasking' },
                { value: 'Empathy', label: 'Empathy' },
                { value: 'Networking', label: 'Networking' },
                { value: 'Mentoring', label: 'Mentoring' },
                { value: 'Persuasion', label: 'Persuasion' },
                { value: 'Delegation', label: 'Delegation' },
                { value: 'Team Building', label: 'Team Building' },
                { value: 'Innovation', label: 'Innovation' },
                { value: 'Responsibility', label: 'Responsibility' },
                { value: 'Professionalism', label: 'Professionalism' },
                { value: 'Assertiveness', label: 'Assertiveness' },
                { value: 'Cultural Awareness', label: 'Cultural Awareness' },
                { value: 'Resilience', label: 'Resilience' },
                { value: 'Positive Attitude', label: 'Positive Attitude' },
                { value: 'Open-Mindedness', label: 'Open-Mindedness' },
                { value: 'Tolerance', label: 'Tolerance' },
                { value: 'Motivating Others', label: 'Motivating Others' },
                { value: 'Giving and Receiving Feedback', label: 'Giving and Receiving Feedback' },
                { value: 'Conflict Management', label: 'Conflict Management' },
                { value: 'Work-Life Balance', label: 'Work-Life Balance' },
                { value: 'Goal Setting', label: 'Goal Setting' },
                { value: 'Initiative', label: 'Initiative' },
                { value: 'Self-Discipline', label: 'Self-Discipline' },
                { value: 'Independence', label: 'Independence' },
                { value: 'Learning Agility', label: 'Learning Agility' },
                { value: 'Coaching', label: 'Coaching' },
              ]}
              className="block w-full"
            />
            </div>
    );
}

export default SoftSkills;
