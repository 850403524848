import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { logout } from "../../redux/actions/Logout/Logout";
import imagen from "../../assets/Logo/simbolo-SF-04.png";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const onHandleClick = () => {
    dispatch(logout());
    navigate('/');
  };

  const handleMenuClick = () => {
    setIsOpen(false);
  };

  return (
    <header className="bg-gradient-to-r from-gray-900 via-black to-gray-900 text-white shadow-lg">
      <div className="max-w-7xl mx-auto px-6 py-4 flex justify-between items-center">
        <div className="flex items-center space-x-3">
          <img src={imagen} alt="RysConnect logo" className="w-10 h-10" />
          <Link to="/" onClick={handleMenuClick}>
            <span className="text-2xl font-semibold tracking-wide">Rysconnect</span>
          </Link>
        </div>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="lg:hidden text-white focus:outline-none"
        >
          <svg
            className="h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            {isOpen ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            ) : (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            )}
          </svg>
        </button>
        <nav
          className={`${
            isOpen ? "block" : "hidden"
          } lg:flex lg:items-center lg:space-x-8 absolute lg:relative top-16 left-0 right-0 lg:top-auto bg-gray-900 lg:bg-transparent z-20 lg:z-auto transition duration-300`}
        >
          {user?.isAuthenticated ? (
            <>
              {user.userType === "Admin-FLAS-1721" && (
                <>
                  <Link to="/admin-companies" className="block lg:inline-block px-4 py-2 hover:text-orange-400 transition">
                    Companies
                  </Link>
                  <Link to="/admin-jobs" className="block lg:inline-block px-4 py-2 hover:text-orange-400 transition">
                    Admin
                  </Link>
                  <span className="block lg:inline-block text-orange-400 px-4 py-2 cursor-pointer">{user.name}</span>
                </>
              )}

              {user.userType === "Candidate" && user.isCompleteInfo && (
                <>
                  <Link to="/job-positions-list" className="block lg:inline-block px-4 py-2 hover:text-gray-400 transition">
                    Positions
                  </Link>
                  <Link to="/my-application" className="block lg:inline-block px-4 py-2 hover:text-gray-400 transition">
                    My positions
                  </Link>
                  <Link to="/profile-candidates" className="block lg:inline-block text-orange-400 hover:text-orange-600 px-4 py-2 transition">
                    {user.name}
                  </Link>
                </>
              )}

              {user.userType === "Company" && user.isCompleteInfo && (
                <>
                  <Link to="/hire-talent" className="block lg:inline-block px-4 py-2 hover:text-gray-400 transition">
                    Hire Talent
                  </Link>
                  <Link to="/register-job" className="block lg:inline-block px-4 py-2 hover:text-gray-400 transition">
                    Register Job
                  </Link>
                  <Link to={`/your-job/${user.id}`} className="block lg:inline-block px-4 py-2 hover:text-gray-400 transition">
                    My Jobs
                  </Link>
                  <Link to="/company-profile" className="block lg:inline-block text-orange-400 hover:text-orange-600 px-4 py-2 transition">
                    {user.name}
                  </Link>
                </>
              )}

              <button
                className="block lg:inline-block text-blue-400 hover:text-blue-600 px-4 py-2 transition cursor-pointer"
                onClick={() => {
                  handleMenuClick();
                  onHandleClick();
                }}
              >
                LogOut
              </button>
            </>
          ) : (
            <>
            <Link to="/pricing" className="block lg:inline-block px-4 py-2 hover:text-gray-400 transition">
            Pricing</Link>
              <Link to="/job-positions-list" className="block lg:inline-block px-4 py-2 hover:text-gray-400 transition">
                Positions
              </Link>
              <Link to="/signin" className="block lg:inline-block text-white border border-white hover:bg-white hover:text-black px-4 py-2 rounded transition">
                Log In
              </Link>
              <Link to="/signup" className="block lg:inline-block bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded transition">
                Register Now
              </Link>
              <Link target="_blank" to="https://calendly.com/sergio_razo_ryscode/30min" className="block lg:inline-block bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded transition">
                Contact Us
              </Link>
            </>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
