import React from 'react';
import DOMPurify from 'dompurify'
import './style.css'

const JobDescriptionSection = ({ job }) => {

  const sanitizedJobDescription = DOMPurify.sanitize(job?.jobDescription);
  const sanitizedResponsibilities = DOMPurify.sanitize(job?.responsibilities)
  const sanitizedQualificationsAndSkills = DOMPurify.sanitize(job?.qualificationsAndSkills)

  return (
    <div className="border-t border-gray-200 mt-6 pt-6">
      <div className="space-y-4 text-lg">
        <div>
          <p className="font-semibold">Description</p> <hr />
          
          <div className="no-tailwind" dangerouslySetInnerHTML={{ __html:  sanitizedJobDescription}} />
        </div>
        <div>
          <p className="font-semibold">Responsibilities</p> <hr />
          <div className="no-tailwind" dangerouslySetInnerHTML={{ __html:  sanitizedResponsibilities}} />
        </div>
        <div>
          <p className="font-semibold">Qualifications and skills</p> <hr />
          <div className="no-tailwind" dangerouslySetInnerHTML={{ __html:  sanitizedQualificationsAndSkills}} />
        </div>
        <div>
          <p className="font-semibold">Additional information</p> <hr />
          <p>{job.additionalInformation}</p>
        </div>
        <div>
          <p className="font-semibold">About the company</p> <hr />
          <p>{job.tbl_company.companyDescription}</p>
        </div>
        <div>
          <p className="font-semibold">Interview stages</p> <hr />
          <p>{job.interviewStages}</p>
        </div>
      </div>
    </div>
  );
};

export default JobDescriptionSection;