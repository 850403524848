import React from 'react';

const PublishedJobsTable = ({ openJobs }) => {
  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Published</h2>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 text-center border-b">Company</th>
            <th className="py-2 text-center border-b">Vacancy Name</th>
            <th className="py-2 text-center border-b">Status</th>
          </tr>
        </thead>
        <tbody>
          {openJobs.map((job) => (
            <tr key={job.id} className="text-center">
              <td className="py-2 px-4 border-b">{job.tbl_company?.companyName}</td>
              <td className="py-2 px-4 border-b">{job.jobName}</td>
              <td className="py-2 px-4 border-b">{job.positionStatus}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PublishedJobsTable;