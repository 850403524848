import React from 'react';

const ReportScores = ({ finalScore, codeScore, mcScore, oeDetails }) => {
  return (
    <section className="mb-12">
      <h2 className="text-3xl font-semibold text-gray-700 mb-4 text-center">Scores</h2>
      <div className="grid grid-cols-2 gap-8">
        <div className="p-6 bg-gray-50 rounded-lg shadow-inner text-center">
          <h3 className="text-xl font-medium text-gray-600 mb-4">Final Score</h3>
          <p className="text-gray-800 text-2xl">{finalScore}%</p>
        </div>
        <div className="p-6 bg-gray-50 rounded-lg shadow-inner text-center">
          <h3 className="text-xl font-medium text-gray-600 mb-4">Challenges</h3>
          <p className="text-gray-800 text-2xl">{codeScore}%</p>
        </div>
        <div className="p-6 bg-gray-50 rounded-lg shadow-inner text-center">
          <h3 className="text-xl font-medium text-gray-600 mb-4">Multiple Choice</h3>
          <p className="text-gray-800 text-2xl">{mcScore}%</p>
        </div>
        <div className="p-6 bg-gray-50 rounded-lg shadow-inner text-center">
          <h3 className="text-xl font-medium text-gray-600 mb-4">Open-ended Responses</h3>
          <p className="text-gray-800 text-2xl"> {oeDetails?.length <= 0 ? <>N/A</>: `${oeDetails.length} responses`}</p>
        </div>
      </div>
    </section>
  );
};

export default ReportScores;
