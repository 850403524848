import imagenes from '../../../assets/LANDING/ILLUSTRATION/outsourcing-illustration-exclusive-design-inspiration-vector.jpg';
import { FaClipboardCheck, FaRocket, FaClock, FaHeadset, FaArrowRight } from 'react-icons/fa';

const ServerSmartMatching = () => {
    return (
        <div>
            <main className="max-w-7xl mx-auto px-4 py-16 flex flex-col md:flex-row items-center">
                {/* Left Content */}
                <div className="w-full md:w-1/2 md:pr-8">
                    <h1 className="text-4xl font-bold text-black leading-tight">
                        Simplify Tech <br />
                        Talent Search with <br />
                        Smart Matching
                    </h1>
                    <p className="mt-4 text-gray-700">
                        Finding the perfect candidate shouldn't feel like searching for a needle in a haystack.
                    </p>
                    <button className="mt-8 px-8 py-3 bg-blue-600 text-white font-bold rounded-none shadow-lg hover:bg-yellow-600">
                        Try it for free
                    </button>
                </div>

                {/* Right Content */}
                <div className="w-full md:w-1/2 mt-8 md:mt-0 flex justify-center">
                    {/* URL of youtube */}
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/R1Z5VEPlPIg?si=7g8j792aQniHODI1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </main>

            <section className="max-w-7xl mx-auto px-4 py-16 flex flex-col md:flex-row items-center">
                <img src={imagenes} alt="Illustration of tech recruitment process" className="w-full md:w-1/2 mb-8 md:mb-0 md:mr-8"/>
                <div className="w-full md:w-1/2">
                    <h2 className="text-3xl font-bold text-gray-900 mb-4">Why Choose Smart Matching for Your Tech Recruitment Needs?</h2>
                    <p className="text-lg text-gray-600 mb-8">
                        At RysConnect, we specialize in connecting companies with exceptional tech talent across the U.S., Canada, Europe, and Latin America. Our revolutionary platform, Smart Matching, simplifies the recruitment process by focusing on both technical skills and soft skills, ensuring you find candidates who are the perfect fit for your team.
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <div className="items-start">
                            <FaClipboardCheck className="text-yellow-500 text-2xl mr-4" size={40} />
                            <div>
                                <h3 className="font-semibold text-xl text-gray-900 mb-2">Comprehensive Candidate Evaluation</h3>
                                <p className="text-gray-600">Smart Matching's algorithm goes beyond technical skills by equally weighing soft skills. This holistic approach ensures that you find tech professionals who excel in their roles and align with your company culture.</p>
                            </div>
                        </div>
                        <div className="items-start">
                            <FaRocket className="text-yellow-500 text-2xl mr-4" size={40}/>
                            <div>
                                <h3 className="font-semibold text-xl text-gray-900 mb-2">Quick and Easy Vacancy Setup</h3>
                                <p className="text-gray-600">Start your recruitment journey by simply filling out a form with Smart Matching. Answer a few targeted questions about your hiring needs, and let our platform do the rest.</p>
                            </div>
                        </div>
                        <div className="items-start">
                            <FaClock className="text-yellow-500 text-2xl mr-4" size={40}/>
                            <div>
                                <h3 className="font-semibold text-xl text-gray-900 mb-2">Save Time and Resources</h3>
                                <p className="text-gray-600">Reduce the hours and costs traditionally associated with finding the right tech talent. With Smart Matching, efficient and effective hiring is at your fingertips.</p>
                            </div>
                        </div>
                        <div className="items-start">
                            <FaHeadset className="text-yellow-500 text-2xl mr-4" size={40}/>
                            <div>
                                <h3 className="font-semibold text-xl text-gray-900 mb-2">Human-Centered Support</h3>
                                <p className="text-gray-600">Experience personalized support from our Senior IT Recruiters. At Smart Matching, we prioritize human interaction over automated responses, providing you with real-time, expert assistance.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <div className="text-center mt-8 flex justify-center items-center">
                    
                    <button className="text-yellow-500 font-semibold" >Try it for free </button>
                    <FaArrowRight color='#eab308'/>
                
            </div>

            <section className="max-w-7xl mx-auto px-4 py-16 flex flex-col md:flex-row items-center mt-10">
                {/* Left Content */}
                <div className="w-full md:w-1/2 md:pr-8">
                    <h1 className="text-4xl font-bold text-black leading-tight">
                        Transform Your Hiring Process <br />
                        with Smart Matching
                    </h1>
                    <p className="mt-4 text-gray-700">
                    Whether you’re located in North America, Europe, or Latin America, Smart Matching is your solution for fast, efficient, and effective tech hiring.

                        Are you ready to streamline your recruitment process and connect with top tech talent effortlessly?
                    </p>
                    
                </div>

                {/* Right Content */}
                <div className="w-full md:w-1/2 mt-8 md:mt-0 flex justify-center">
                    <button className="mt-8 px-8 py-3 bg-blue-600 text-white font-bold rounded-none shadow-lg hover:bg-yellow-600">
                        Contact us
                    </button>
                </div>
            </section>
        </div>
    );
}

export default ServerSmartMatching;
