import React from 'react';
import Select from 'react-select';

const SelectTools = ({ errors, form, handleChange, handleSelectChange, placeholder, error }) => {
    return (
<div className="col-span-1">
            <label className="block text-gray-700">
              Tools <span className="text-red-500">*</span>
            </label>
            {errors.tools && (
              <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                <span>{errors.tools}</span>
              </div>
            )}
            <Select
              name="tools"
              isMulti
              value={form.tools?.map(tool => ({ value: tool, label: tool }))}
              onChange={handleSelectChange}
              options={[
                { value: 'Git', label: 'Git' },
                { value: 'Docker', label: 'Docker' },
                { value: 'Jenkins', label: 'Jenkins' },
                { value: 'Kubernetes', label: 'Kubernetes' },
                { value: 'Ansible', label: 'Ansible' },
                { value: 'Terraform', label: 'Terraform' },
                { value: 'JIRA', label: 'JIRA' },
                { value: 'Confluence', label: 'Confluence' },
                { value: 'Slack', label: 'Slack' },
                { value: 'Trello', label: 'Trello' },
                { value: 'VS Code', label: 'VS Code' },
                { value: 'IntelliJ IDEA', label: 'IntelliJ IDEA' },
                { value: 'Eclipse', label: 'Eclipse' },
                { value: 'PyCharm', label: 'PyCharm' },
                { value: 'Postman', label: 'Postman' },
                { value: 'Selenium', label: 'Selenium' },
                { value: 'Apache Maven', label: 'Apache Maven' },
                { value: 'Gradle', label: 'Gradle' },
                { value: 'Webpack', label: 'Webpack' },
                { value: 'Gulp', label: 'Gulp' },
                { value: 'NPM', label: 'NPM' },
                { value: 'Yarn', label: 'Yarn' },
                { value: 'Babel', label: 'Babel' },
                { value: 'ESLint', label: 'ESLint' },
                { value: 'Prettier', label: 'Prettier' },
                { value: 'Redux DevTools', label: 'Redux DevTools' },
                { value: 'Figma', label: 'Figma' },
                { value: 'Sketch', label: 'Sketch' },
                { value: 'Adobe XD', label: 'Adobe XD' },
                { value: 'InVision', label: 'InVision' },
                { value: 'Zeplin', label: 'Zeplin' },
                { value: 'Google Analytics', label: 'Google Analytics' },
                { value: 'Hotjar', label: 'Hotjar' },
                { value: 'Mixpanel', label: 'Mixpanel' },
                { value: 'Segment', label: 'Segment' },
                { value: 'Grafana', label: 'Grafana' },
                { value: 'Prometheus', label: 'Prometheus' },
                { value: 'Splunk', label: 'Splunk' },
                { value: 'DataDog', label: 'DataDog' },
                { value: 'ELK Stack', label: 'ELK Stack' },
                { value: 'New Relic', label: 'New Relic' },
                { value: 'AWS', label: 'AWS' },
                { value: 'Azure', label: 'Azure' },
                { value: 'Google Cloud', label: 'Google Cloud' },
                { value: 'Firebase', label: 'Firebase' },
                { value: 'DigitalOcean', label: 'DigitalOcean' },
                { value: 'Heroku', label: 'Heroku' },
                { value: 'Netlify', label: 'Netlify' },
                { value: 'Vercel', label: 'Vercel' },
                { value: 'CircleCI', label: 'CircleCI' },
                { value: 'Travis CI', label: 'Travis CI' },
                { value: 'GitLab CI', label: 'GitLab CI' },
                { value: 'Bitbucket Pipelines', label: 'Bitbucket Pipelines' },
                { value: 'Google Compute Engine (GCE)', label: 'Google Compute Engine (GCE)' },
                { value: 'Google Kubernetes Engine (GKE)', label: 'Google Kubernetes Engine (GKE)' },
                { value: 'Google Cloud Storage (GCS)', label: 'Google Cloud Storage (GCS)' },
                { value: 'Google BigQuery', label: 'Google BigQuery' },
                { value: 'Google Cloud Functions', label: 'Google Cloud Functions' },
                { value: 'Google Cloud Pub/Sub', label: 'Google Cloud Pub/Sub' },
                { value: 'Google Cloud SQL', label: 'Google Cloud SQL' },
                { value: 'Google Cloud CDN', label: 'Google Cloud CDN' },
                { value: 'Google Cloud Load Balancing', label: 'Google Cloud Load Balancing' },
                { value: 'Google Cloud IAM (Identity and Access Management)', label: 'Google Cloud IAM (Identity and Access Management)' },
                { value: 'Google Cloud SDK', label: 'Google Cloud SDK' },
                { value: 'Google Cloud Console', label: 'Google Cloud Console' },
                { value: 'Google Operations Suite', label: 'Google Operations Suite' },
                { value: 'Bugzilla', label: 'Bugzilla' },
                { value: 'Redmine', label: 'Redmine' },
                { value: 'Mantis', label: 'Mantis' },
                { value: 'Microsoft Teams', label: 'Microsoft Teams' },
                { value: 'TestRail', label: 'TestRail' },
                { value: 'QTest', label: 'QTest' },
                { value: 'Zephyr', label: 'Zephyr' },
                { value: 'SessionBox', label: 'SessionBox' },
                { value: 'Rapid Reporter', label: 'Rapid Reporter' },
                { value: 'Selenium WebDriver', label: 'Selenium WebDriver' },
                { value: 'Cypress', label: 'Cypress' },
                { value: 'Appium', label: 'Appium' },
                { value: 'TestComplete', label: 'TestComplete' },
                { value: 'SVN', label: 'SVN' },
                { value: 'SoapUI', label: 'SoapUI' },
                { value: 'REST Assured', label: 'REST Assured' },
                { value: 'JMeter', label: 'JMeter' },
                { value: 'LoadRunner', label: 'LoadRunner' },
                { value: 'Cucumber', label: 'Cucumber' },
                { value: 'SpecFlow', label: 'SpecFlow' },
                { value: 'Functional Testing', label: 'Functional Testing' },
                { value: 'Non-Functional Testing', label: 'Non-Functional Testing' },
                { value: 'Regression Testing', label: 'Regression Testing' },
                { value: 'Acceptance Testing', label: 'Acceptance Testing' },
                { value: 'Usability Testing', label: 'Usability Testing' },
                { value: 'Compatibility Testing', label: 'Compatibility Testing' },
                { value: 'Performance Testing', label: 'Performance Testing' },
                { value: 'Security Testing', label: 'Security Testing' },
                { value: 'Integration Testing', label: 'Integration Testing' },
                { value: 'System Testing', label: 'System Testing' },
                { value: 'Smoke Testing', label: 'Smoke Testing' },
                { value: 'User Interface (UI) Testing', label: 'User Interface (UI) Testing' },
                { value: 'API Testing', label: 'API Testing' },
                { value: 'Exploratory Testing', label: 'Exploratory Testing' }
              ]}
              className="block w-full"
            />
          </div>
    );
}

export default SelectTools;
