import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ChallengeIntro from './ChallengeIntro';
import Question from './Question';
import { toast, ToastContainer } from 'react-toastify'; // Importa Toasty si lo tienes configurado
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getTests } from '../../redux/actions/Test/getTests';
import axios from 'axios'




    const Challenge = () => {
        const { id } = useParams()
        const user = useSelector(state => state.user)
        const navigate = useNavigate();
        const [started, setStarted] = useState(false);
        const [testCompleted, setTestCompleted] = useState(false)
        const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
        const tests = useSelector(state => state.tests)
        const [actuallyTest, setActuallyTest] = useState({})
        const [answers, setAnswers] = useState({
            technicalTest: {
                questions: []
            },
            psychometricTest: {
                questions: []
            }
        })
        const [questions, setQuestions] = useState([])
        const dispatch = useDispatch()

        useEffect(()=>{
            //Necesito sacarlo de aqui si no hay ningun challenge programado para el
            //dispatch para cargar el test que tiene la vacante de trabajo
            if (id) {
                dispatch(getTests(id))
            }
            
        },[id])

        useEffect(()=>{
            if (tests.hasOwnProperty("technicalTest")) {
                setActuallyTest({
                    name: "technicalTest",
                    questions: tests.technicalTest.questions
                })
            }
        },[tests])  

        useEffect(()=>{
            const sentToBackEnd = async () => {
                // aqui se manda el objeto answers al backend
                // tambien hacer redireccion para llevar a la pestaña de test terminado
                // realizar validacion para saber si ya contesto el test
                try {
                    console.log("se manda al backend");
                    const formData = {
                        name: user.name,
                        email: user.email,
                        technicalTest: answers.technicalTest,
                        psychometricTest: answers.psychometricTest,
                        userId:user.id,
                        jobId: id,
                        testId: tests.id
                    }

                    const response = await axios.post('/add-completed-test', formData)

                    if (response.status === 201) {
                        toast.success("Test completed successfully",{
                            autoClose: 3000,
                            onClose: () => {
                                navigate('/challenge-sent')
                            }
                        });
                    }
                    
                } catch (error) {
                    if (error.response) {
                            toast.error(error.response.data.error);
                        } else {
                            toast.error("An error occurred in the request. Please try again.");
                        }
                }
            }
            
            if(testCompleted){
                sentToBackEnd()
            }

        },[testCompleted, answers])


        
        

        const handleAcceptConditions = () => {
            setStarted(true);
        };
    
        const handleAnswerSubmit = (answer) => {
            //setear las preguntas en el estado
            if (actuallyTest.name === "technicalTest") {
                if (currentQuestionIndex < actuallyTest.questions.length - 1) {
                    setAnswers({...answers, technicalTest: {
                        questions: [
                            ...answers.technicalTest.questions,
                            {
                                question: answer.question,
                                answer: answer.answer
                            }
                        ]
                    }})
                    setCurrentQuestionIndex(currentQuestionIndex + 1);
                } else {
                    setAnswers({...answers, technicalTest: {
                        questions: [
                            ...answers.technicalTest.questions,
                            answer
                        ]
                    }})
                    if (tests.hasOwnProperty("psychometricTest")) {
                        setActuallyTest({
                            name: "psychometricTest",
                            questions: tests.psychometricTest.questions
                        })
                        setCurrentQuestionIndex(0)
                    }
                }
                
            }else if (actuallyTest.name === "psychometricTest") {
                
                if (currentQuestionIndex < actuallyTest.questions.length - 1) {
                    setAnswers({...answers, psychometricTest: {
                        questions: [
                            ...answers.psychometricTest.questions,
                            {
                                question: answer.question,
                                answer: answer.answer
                            }
                        ]
                    }})
                    setCurrentQuestionIndex(currentQuestionIndex + 1);
                } else {
                    setAnswers({...answers, psychometricTest: {
                        questions: [
                            ...answers.psychometricTest.questions,
                            {
                                question: answer.question,
                                answer: answer.answer
                            }
                        ]
                    }})
                    //esto actualiza el useEffect para poder mandar la info al backend ya que tengo un useEffect para cuando termine el test
                    setTestCompleted(true)
                }
            }
        };
    
        return (
        <div className="min-h-screen flex items-center justify-center bg-white">
            {!started ? (
            <ChallengeIntro onAccept={handleAcceptConditions} />
            ) : (
            <div className="w-full h-full"> {/* Ocupar todo el espacio */}
                <Question
                    setTestCompleted={setTestCompleted}
                    nameOfTest={actuallyTest.name}
                    question={actuallyTest.questions[currentQuestionIndex]}
                    onSubmit={handleAnswerSubmit}
                    totalQuestions={tests?.technicalTest?.questions.length}  // Añadido para pasar el número total de preguntas
                    currentQuestionIndex={currentQuestionIndex} // Índice de pregunta actual
                    testCompleted={testCompleted}
                />
            </div>
            )}
            <ToastContainer />
        </div>
        );
    };
    
    export default Challenge;