import React from 'react';
import { Link } from 'react-router-dom'
import DefaultP from '../../assets/default_p.jpg'

const Card = ({candidates}) => {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 w-full">
          {candidates?.map((candidate) => (
            <Link key={candidate.id} to={`/candidate-detail/${candidate.id}`}>
            <div key={candidate.id} className="bg-gray-100 p-6 rounded-lg shadow-lg flex flex-col items-center">
            <img src={DefaultP} alt={`Profile of ${candidate.firstName}`} className="rounded-full h-24 w-24 mb-4" />
              <div className="text-right w-full">
                <span className={`text-sm font-bold p-1 rounded ${candidate.technicalLevel === 'Junior' ? 'bg-blue-200 text-blue-600' : candidate.technicalLevel === 'Senior' ? 'bg-purple-200 text-purple-600' : 'bg-red-200 text-red-600'}`}>
                  {candidate.technicalLevel}
                </span>
              </div>
              <h3 className="text-xl font-semibold mt-2 text-center">{candidate.firstName || candidate.tbl_user.firstName} {candidate.lastName || candidate.tbl_user.secondName}</h3>
              <p className="text-gray-600 text-center">{candidate.role}</p>
              <p className="text-gray-500 mt-2 text-center">{candidate.country}</p>
              <p className="text-gray-500 text-center">${candidate.salaryExpectations}</p>
              <div className="flex flex-wrap justify-center mt-4 space-x-2">
                {candidate.mainTech?.map((tech) => (
                  <span key={tech} className={`text-sm font-bold p-1 rounded ${tech === 'Angular' ? 'bg-green-200 text-green-600' : tech === 'Java' 
                  ? 'bg-yellow-200 text-yellow-600' : 'bg-red-200 text-red-600'}`}>
                    {tech}
                  </span>
                ))}
              </div>
            </div>
            </Link>
          ))}
        </div>
    );
}

export default Card;
