import React from 'react';

const JobTableHeader = () => {
  return (
    <header>
      <h1 className="text-2xl font-bold mb-4">Your Job's</h1>
    </header>
  );
};

export default JobTableHeader;
