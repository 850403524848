import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllJobsForCompany } from '../../redux/actions/Company/getAllJobsForCompany';
import JobTableHeader from '../../components/JobTable//JobTableHeader';
import JobTableInfoMessage from '../../components/JobTable/JobTableInfoMessage';
import JobTableBody from '../../components/JobTable/JobTableBody';
import JobFilter from '../../components/JobTable/JobFilter';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const JobTable = () => {
  const user = useSelector(state => state.user);
  const jobsFC = useSelector(state => state.jobsFC);
  const dispatch = useDispatch();
  const [filteredJobs, setFilteredJobs] = useState(jobsFC);

  useEffect(() => {
    if (user) {
      dispatch(getAllJobsForCompany({ userId: user.id }));
    }
  }, [user, dispatch]);

  useEffect(() => {
    setFilteredJobs(jobsFC); // Actualizar los trabajos filtrados cuando cambie el listado de trabajos
  }, [jobsFC]);

  const handleJobClose = async (jobId) => {
    if (confirm('Are you sure you want to close this vacancy?')) {
      try {
        const result = await axios.put(`/close-job/?jobId=${jobId}`);
        if (result.status === 200 && result.data.message === 'Update successful') {
          toast.success('Job closed successfully, refreshing!', {
            autoClose: 1500,
            onClose: () => dispatch(getAllJobsForCompany({ userId: user.id })),
          });
        } else {
          toast.error('Nothing was updated');
        }
      } catch (error) {
        toast.error(error.response ? error.response.data.error : 'An error occurred. Please try again.');
      }
    }
  };

  const handleFilterChange = (filter) => {
    if (filter === 'All') {
      setFilteredJobs(jobsFC);
    } else {
      setFilteredJobs(jobsFC.filter(job => job.positionStatus === filter));
    }
  };

  return (
    <div className="container mx-auto mt-8 mb-80 px-4 sm:px-6 lg:px-8">
      <JobTableHeader />
      <JobTableInfoMessage />
      <JobFilter onFilterChange={handleFilterChange} />
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Posting Title</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Num. candidates</th>
              <th colSpan={4} className="py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <JobTableBody jobs={filteredJobs} user={user} handleJobClose={handleJobClose} />
        </table>
      </div>
      <ToastContainer />
    </div>
  );
};

export default JobTable;
