import React from 'react';

const JobTableInfoMessage = () => {
  return (
    <div className="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3 mb-10 mt-7" role="alert">
      <p className="font-bold">Informational message</p>
      <p className="text-sm">
        If the status is "Pending", a personalized test will be added based on the profile you are looking for to have the best-rated candidates for you.
        When the status is "Open" it means that the test has already been added to the vacancy.
      </p>
    </div>
  );
};

export default JobTableInfoMessage;
