import axios from 'axios'
import ACTION_TYPES from '../../actionTypes'


const getCompanyByIdSuccess = (company) => {
    return {
        type: ACTION_TYPES.GET_COMPANY_BY_ID_SUCCESS,
        payload: company
    }
}

const getCompanyByIdFailure = (error) => {
    return {
        type: ACTION_TYPES.GET_COMPANY_BY_ID_FAILURE,
        payload: error
    }
}

export const getCompanyById = (id) => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/companies/${id}`)
            const company = response.data
            dispatch(getCompanyByIdSuccess(company))
        } catch (error) {
            dispatch(getCompanyByIdFailure(error.message))
        }
    }
}

