import axios from 'axios'
import ACTION_TYPES from '../../actionTypes'


const getCandidateByIdSuccess = (candidate) => {
    return {
        type: ACTION_TYPES.GET_CANDIDATE_BY_ID_SUCCESS,
        payload: candidate
    }
}

const getCandidateByIdFailure = (error) => {
    return {
        type: ACTION_TYPES.GET_CANDIDATE_BY_ID_FAILURE,
        payload: error
    }
}

export const getCandidateById = (id) => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/candidate/${id}`)
            const candidate = response.data
            dispatch(getCandidateByIdSuccess(candidate))
        } catch (error) {
            dispatch(getCandidateByIdFailure(error.message))
        }
    }
}


