import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getJobs } from '../../redux/actions/Candidate/getJobs';
import { getJobsByFilters } from '../../redux/actions/Candidate/getJobsByFilters';
import SearchBar from '../../components/SearchBar/SearchBar';
import Header from '../../components/JobPositions/Header';
import JobPositionsLayout from '../../components/JobPositions/JobPositionsLayout';
import JobPositionsPagination from '../../components/JobPositions/JobPositionsPagination';


const JobPositionsList = () => {
    const jobs = useSelector(state => state.jobs);
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [jobsPerPage] = useState(15);

    useEffect(() => {
        dispatch(getJobs());
    }, [dispatch]);

    if (!jobs) {
        return <p>Loading candidate details...</p>;
    }

    const numberOfLastPosition = currentPage * jobsPerPage;
    const numberOfFirstPosition = numberOfLastPosition - jobsPerPage;
    const currentJobs = jobs.slice(numberOfFirstPosition, numberOfLastPosition);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const searchJobs = (name) => {
        setCurrentPage(1);
        dispatch(getJobs(name));
    };

    const filterJobs = (filters) => {
        dispatch(getJobsByFilters(filters));
    };

    const resetJobsFilter = () => {
        setCurrentPage(1);
        dispatch(getJobs());
    };

    return (
        <div className="container mx-auto p-4">
            <Header />
            <SearchBar searchJobs={searchJobs} />
            <JobPositionsPagination
                jobsPerPage={jobsPerPage}
                totalJobs={jobs.length}
                paginate={paginate}
                page={currentPage}
            />
            <JobPositionsLayout
                currentJobs={currentJobs}
                filterJobs={filterJobs}
                resetJobsFilter={resetJobsFilter}
                setCurrentPage={paginate}
            />
            <JobPositionsPagination
                jobsPerPage={jobsPerPage}
                totalJobs={jobs.length}
                paginate={paginate}
                page={currentPage}
            />
        </div>
    );
};

export default JobPositionsList;
