import React, { useEffect, useState } from 'react';
import imagen from "../../assets/LANDING/ILUSTRACIONES-RYSCONNECT-LATAM.png";
import logo from "../../assets/Logo/Logo-signin.jpeg";
import SignInIllustration from '../../components/SignIn/SignInIllustration';
import SignInHeader from '../../components/SignIn/SignInHeader';
import SignInForm from '../../components/SignIn/SignInForm';
import { useDispatch, useSelector } from 'react-redux';
import { authenticated } from '../../redux/actions/SignIn/postSignIn';
import { useNavigate } from 'react-router-dom';
import { setUserErrorNull } from '../../redux/actions/Error/setUserErrorNull';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { FaArrowLeft } from "react-icons/fa";

const SignIn = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.user);
    const error = useSelector((state) => state.user.error);
    const navigate = useNavigate();
    
    const [form, setForm] = useState({
        email: '',
        password: ''
    });
    const [forgotPassword, setForgotPassword] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    
    useEffect(() => {
        if (user && !user.error && user.isAuthenticated) {
            if (user.userType === 'Company' ) {
                if (!user.isCompleteInfo) {
                    navigate('/complete-company-profile');
                } else {
                    navigate(`/your-job/${user.id}`);
                }
            } else if (user.userType === 'Candidate') {
                if (!user.isCompleteInfo) {
                    navigate('/create-candidate');
                } else {
                    navigate('/my-application');
                }
            } else {
                navigate('/');
            }
        }
        if (error) {
            alert('Email or password is wrong or your not authorized!');
            dispatch(setUserErrorNull());
        }
    }, [user, error, navigate, dispatch]);
    
    useEffect(() => {
        if (showConfirmation) {
            const timer = setTimeout(() => {
                navigate('/'); // Redirigir al home
            }, 10000); // 10 segundos

            return () => clearTimeout(timer); // Limpiar el temporizador al desmontar
        }
    }, [showConfirmation, navigate]);

    const onHandleChange = (event) => {
        setForm({ ...form, [event.target.name]: event.target.value });
    };
    
    const onHandleSubmit = (event) => {
        event.preventDefault();
        dispatch(authenticated(form));
    };
    
    const handleForgotPassword = () => {
        setForgotPassword(true);
    };

    const validateEmail = (email) => {
        const regexEmail = RegExp(/\S+@\S+\.\S+/);
        return regexEmail.test(email);
    };

    const handleResetPasswordSubmit = async (event) => {
        event.preventDefault();

        if (validateEmail(form.email)) {
            try {
                console.log("entre");
                
                    const email = form.email
                    const response = await axios.post('/send-mail-to-reset-password', {email});
        
                    if (response.status === 201) {
                        setShowConfirmation(true); // Mostrar mensaje de confirmación
                    }
                } catch (error) {
                    if (error.response) {
                        toast.error(error.response.data.error);
                    } else {
                        toast.error("An error occurred in the request. Please try again.");
                    }
                }
        }else{
            toast.error('Please enter a valid email address.');
            return;
        }
    };

    return (
        <div className="flex flex-col-reverse lg:flex-row justify-between items-center min-h-screen bg-white">
            <SignInIllustration src={imagen} alt="Illustration" />
            <div className="flex items-center justify-center w-full lg:w-1/2 h-full p-4 lg:p-0">
                <div className="w-full max-w-md p-8 space-y-8 bg-white shadow-lg rounded-lg">
                    {forgotPassword ? <button onClick={()=>setForgotPassword(false)}><FaArrowLeft size={30}/></button> : null}
                    <SignInHeader logoSrc={logo} title="Welcome Back to RysConnect" />
                    {showConfirmation ? (
                        <div className="text-center text-green-600">
                            <p>Perfect, check your email to reset your password.</p>
                            <p>If you can't find the message, check your spam folder.</p>
                        </div>
                    ) : forgotPassword ? (
                        <form onSubmit={handleResetPasswordSubmit} className="space-y-4">
                            <input
                                type="email"
                                name="email"
                                placeholder="Enter your email"
                                onChange={onHandleChange}
                                value={form.email}
                                className="w-full p-2 border rounded"
                                required
                            />
                            <button type="submit" className="w-full p-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                                Send Reset Link
                            </button>
                        </form>
                    ) : (
                        <>
                            <SignInForm form={form} onHandleChange={onHandleChange} onHandleSubmit={onHandleSubmit} />
                            <button 
                                onClick={handleForgotPassword} 
                                className="text-blue-500 text-sm hover:underline mt-2"
                            >
                                Forgot your password?
                            </button>
                        </>
                    )}
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default SignIn;
