import React from 'react';
import { Link } from 'react-router-dom';

const CandidateDetailsGrid = ({ candidate }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
      <DetailCard title="Role" content={candidate.role} color="blue" />
      <DetailCard title="Seniority" content={candidate.technicalLevel} />
      <DetailCard title="Years of experience" content={candidate.yearsOfExperience} />
      <DetailCard title="Main Technology" content={candidate.mainTech?.join(', ')} color="blue" />
      <DetailCard title="Secondary Technology" content={candidate.secondaryTech?.join(', ')} color="blue" />
      <DetailCard title="Education" content={candidate.educationalLevel} />
      <DetailCard title="Certifications" content={candidate.certifications} />
      <DetailCard title="English level" content={candidate.englishLevel} />
      <DetailCard title="Other languages" content={candidate.languages?.join(', ')} />
      <DetailCard title="Work scheme" content={candidate.workScheme} color="blue" />
      <DetailCard title="Contract schemes" content={candidate.contractScheme} color="blue" />
      <DetailCard title="Salary Expectation (USD)" content={`$${candidate.salaryExpectations}`} />
      <DetailCard title="CV" content={
        <Link to={candidate.resume} target="_blank">
          <button className='p-2 w-auto bg-blue-600 text-white rounded-lg'>View resume</button>
        </Link>
      } />
    </div>
  );
};

const DetailCard = ({ title, content, color = "gray" }) => (
  <div className="p-4 bg-gray-50 rounded-lg shadow-md">
    <h3 className={`text-${color}-600 font-semibold`}>{title}</h3>
    <p className={`text-${color}-900 text-lg`}>{content}</p>
  </div>
);

export default CandidateDetailsGrid;