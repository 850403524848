export const technologyOptions = [
    { value: 'JavaScript', label: 'JavaScript' },
    { value: 'Python', label: 'Python' },
    { value: 'Java', label: 'Java' },
    { value: 'Ruby', label: 'Ruby' },
    { value: 'Docker', label: 'Docker' },
    { value: 'Prometheus', label: 'Prometheus' },
    { value: 'Kubernetes', label: 'Kubernetes' },
    { value: 'Rust', label: 'Rust' },
    { value: 'Azure', label: 'Azure' },
    { value: 'Service Now', label: 'Service Now' },
    { value: 'Ansible', label: 'Ansible' },
    { value: 'DataDog', label: 'DataDog' },
    { value: 'C++', label: 'C++' },
    { value: 'PHP', label: 'PHP' },
    { value: 'Angular', label: 'Angular' },
    { value: 'Bootstrap', label: 'Bootstrap' },
    { value: 'JIRA', label: 'JIRA' },
    { value: 'Bugzilla', label: 'Bugzilla' },
    { value: 'Redmine', label: 'Redmine' },
    { value: 'Mantis', label: 'Mantis' },
    { value: 'Confluence', label: 'Confluence' },
    { value: 'Microsoft Teams', label: 'Microsoft Teams' },
    { value: 'FastAPI', label: 'FastAPI' },
    { value: 'Flask', label: 'Flask' },
    { value: 'Apache Spark', label: 'Apache Spark' },
    { value: 'Slack', label: 'Slack' },
    { value: 'TestRail', label: 'TestRail' },
    { value: 'QTest', label: 'QTest' },
    { value: 'Zephyr', label: 'Zephyr' },
    { value: 'SessionBox', label: 'SessionBox' },
    { value: 'Rapid Reporter', label: 'Rapid Reporter' },
    { value: 'Selenium WebDriver', label: 'Selenium WebDriver' },
    { value: 'Cypress', label: 'Cypress' },
    { value: 'Appium', label: 'Appium' },
    { value: 'TestComplete', label: 'TestComplete' },
    { value: 'Jenkins', label: 'Jenkins' },
    { value: 'GitLab CI', label: 'GitLab CI' },
    { value: 'CircleCI', label: 'CircleCI' },
    { value: 'Git', label: 'Git' },
    { value: 'SVN', label: 'SVN' },
    { value: 'Postman', label: 'Postman' },
    { value: 'SoapUI', label: 'SoapUI' },
    { value: 'REST Assured', label: 'REST Assured' },
    { value: 'JMeter', label: 'JMeter' },
    { value: 'LoadRunner', label: 'LoadRunner' },
    { value: 'Cucumber', label: 'Cucumber' },
    { value: 'SpecFlow', label: 'SpecFlow' },
    { value: 'Functional Testing', label: 'Functional Testing' },
    { value: 'Non-Functional Testing', label: 'Non-Functional Testing' },
    { value: 'Regression Testing', label: 'Regression Testing' },
    { value: 'Acceptance Testing', label: 'Acceptance Testing' },
    { value: 'Usability Testing', label: 'Usability Testing' },
    { value: 'Compatibility Testing', label: 'Compatibility Testing' },
    { value: 'Performance Testing', label: 'Performance Testing' },
    { value: 'Security Testing', label: 'Security Testing' },
    { value: 'Integration Testing', label: 'Integration Testing' },
    { value: 'System Testing', label: 'System Testing' },
    { value: 'Smoke Testing', label: 'Smoke Testing' },
    { value: 'User Interface (UI) Testing', label: 'User Interface (UI) Testing' },
    { value: 'API Testing', label: 'API Testing' },
    { value: 'Exploratory Testing', label: 'Exploratory Testing' },
    { value: 'Agile', label: 'Agile' },
    { value: 'Scrum', label: 'Scrum' },
    { value: 'LEAN', label: 'LEAN' },
    { value: 'CSS', label: 'CSS' },
    { value: 'C', label: 'C' },
    { value: 'C#', label: 'C#' },
    { value: 'Cobol', label: 'Cobol' },
    { value: 'Django', label: 'Django' },
    { value: 'ETL', label: 'ETL' },
    { value: 'Express.js', label: 'Express.js' },
    { value: 'Entity Framework', label: 'Entity Framework' },
    { value: 'Spring', label: 'Spring' },
    { value: 'Flutter', label: 'Flutter' },
    { value: 'Kotlin', label: 'Kotlin' },
    { value: 'Golang', label: 'Golang' },
    { value: 'HTML', label: 'HTML' },
    { value: 'Hibernate', label: 'Hibernate' },
    { value: 'Ionic', label: 'Ionic' },
    { value: 'jQuery', label: 'jQuery' },
    { value: 'JSON', label: 'JSON' },
    { value: 'Java Server Pages', label: 'Java Server Pages' },
    { value: 'Java Server Faces', label: 'Java Server Faces' },
    { value: 'Laravel', label: 'Laravel' },
    { value: 'Snowflake', label: 'Snowflake' },
    { value: 'Databricks', label: 'Databricks' },
    { value: 'random forest', label: 'Random Forest' },
    { value: 'SVM', label: 'SVM' },
    { value: 'xgboost', label: 'XGBoost' },
    { value: 'PCA', label: 'PCA' },
    { value: 'TSNE', label: 'TSNE' },
    { value: 'Umap', label: 'UMAP' },
    { value: 'Pandas', label: 'Pandas' },
    { value: 'Matplotlib', label: 'Matplotlib' },
    { value: 'RNN', label: 'RNN' },
    { value: 'CNN', label: 'CNN' },
    { value: 'Transformers', label: 'Transformers' },
    { value: 'Pytorch', label: 'PyTorch' },
    { value: 'Tensorflow', label: 'TensorFlow' },
    { value: '.NET', label: '.NET' },
    { value: 'Node.js', label: 'Node.js' },
    { value: 'MongoDB', label: 'MongoDB' },
    { value: 'SQL', label: 'SQL' },
    { value: 'MySQL', label: 'MySQL' },
    { value: 'PostgreSQL', label: 'PostgreSQL' },
    { value: 'SQL Server', label: 'SQL Server' },
    { value: 'React', label: 'React' },
    { value: 'React Native', label: 'React Native' },
    { value: 'Ruby on Rails', label: 'Ruby on Rails' },
    { value: 'Redux', label: 'Redux' },
    { value: 'TypeScript', label: 'TypeScript' },
    { value: 'Swift', label: 'Swift' },
    { value: 'Objective-C', label: 'Objective-C' },
    { value: 'Vue.js', label: 'Vue.js' },
    { value: 'VisualBasic', label: 'VisualBasic' },
    { value: 'Xamarin', label: 'Xamarin' },
    { value: 'LAMP', label: 'LAMP' },
    { value: 'Clojure', label: 'Clojure' },
    { value: 'Dart', label: 'Dart' },
    { value: 'Elixir', label: 'Elixir' },
    { value: 'Go', label: 'Go' },
    { value: 'Perl', label: 'Perl' },
    { value: 'R', label: 'R' },
    { value: 'Scala', label: 'Scala' },
    { value: 'Event Hubs', label: 'Event Hubs' },
    { value: 'EventGrids', label: 'EventGrids' },
    { value: 'Azure Data Explore', label: 'Azure Data Explore' },
    { value: 'Azure Storage Accounts', label: 'Azure Storage Accounts' },
    { value: 'Azure Media Streaming', label: 'Azure Media Streaming' },
    { value: 'Zones', label: 'Zones' },
    { value: 'Conduits models', label: 'Conduits models' },
    { value: 'cybersecurity', label: 'cybersecurity' },
    { value: 'Virtualization', label: 'Virtualization' },
    { value: 'Kubernetes', label: 'Kubernetes' },
    { value: 'MQTT', label: 'MQTT' },
    { value: 'Azure ARC', label: 'Azure ARC' },
    { value: 'Azure IoT Service', label: 'Azure IoT Service' },
    { value: 'ITSM processes', label: 'ITSM processes' },
    { value: 'CSDM model', label: 'CSDM model' },
    { value: 'CMDB', label: 'CMDB' },
    { value: 'azure devops', label: 'azure devops' },
    { value: 'IoT Hub', label: 'IoT Hub' },
    { value: 'Purdue', label: 'Purdue' },
    { value: 'Swift', label: 'Swift' },
    { value: 'TypeScript', label: 'TypeScript' },
    { value: 'Unity', label: 'Unity' },
    { value: 'Tableau', label: 'Tableau' },
    { value: 'Tableau Site Consolidation/Migration', label: 'Tableau Site Consolidation/Migration' },
    { value: 'Tableau server to online migration', label: 'Tableau server to online migration' },
    { value: 'Tableau Renewal', label: 'Tableau Renewal' },
    { value: 'Tableau Pulse and Copilot', label: 'Tableau Pulse and Copilot' },
    { value: 'Tableau Permission Management', label: 'Tableau Permission Management' },
    { value: 'Tableau Security', label: 'Tableau Security' },
    { value: 'AWS', label: 'AWS' },
    { value: 'AWS Lambda', label: 'AWS Lambda' },
    { value: 'AWS S3', label: 'AWS S3' },
    { value: 'AWS EC2', label: 'AWS EC2' },
    { value: 'AWS DynamoDB', label: 'AWS DynamoDB' },
    { value: 'AWS RDS', label: 'AWS RDS' },
    { value: 'AWS CloudFormation', label: 'AWS CloudFormation' },
    { value: 'AWS CloudFront', label: 'AWS CloudFront' },
    { value: 'AWS VPC', label: 'AWS VPC' },
    { value: 'AWS CloudWatch', label: 'AWS CloudWatch' },
    { value: 'AWS Elastic Beanstalk', label: 'AWS Elastic Beanstalk' },
    { value: 'AWS IAM', label: 'AWS IAM' },
    { value: 'AWS Glue', label: 'AWS Glue' },
    { value: 'AWS CodePipeline', label: 'AWS CodePipeline' },
    { value: 'AWS AppSync', label: 'AWS AppSync' },
    { value: 'AWS Athena', label: 'AWS Athena' },
    { value: 'AWS SageMaker', label: 'AWS SageMaker' },
    { value: 'AWS Redshift', label: 'AWS Redshift' },
    { value: 'AWS Kinesis', label: 'AWS Kinesis' },
    { value: 'AWS Lake Formation', label: 'AWS Lake Formation' },
    { value: 'AWS Step Functions', label: 'AWS Step Functions' },
    { value: 'AWS Batch', label: 'AWS Batch' },
    { value: 'AWS ECS', label: 'AWS ECS' },
    { value: 'AWS EKS', label: 'AWS EKS' },
    { value: 'AWS Fargate', label: 'AWS Fargate' },
    { value: 'AWS CodeBuild', label: 'AWS CodeBuild' },
    { value: 'AWS CodeDeploy', label: 'AWS CodeDeploy' },
    { value: 'AWS CloudTrail', label: 'AWS CloudTrail' },
    { value: 'AWS Config', label: 'AWS Config' },
    { value: 'AWS Systems Manager', label: 'AWS Systems Manager' },
    { value: 'AWS X-Ray', label: 'AWS X-Ray' },
    { value: 'AWS Elastic MapReduce', label: 'AWS Elastic MapReduce' },
    { value: 'AWS DMS', label: 'AWS DMS' },
    { value: 'AWS DataSync', label: 'AWS DataSync' },
    { value: 'AWS Glue DataBrew', label: 'AWS Glue DataBrew' },
    { value: 'AWS Data Pipeline', label: 'AWS Data Pipeline' },
    { value: 'AWS Glue Catalog', label: 'AWS Glue Catalog' },
    { value: 'AWS Managed Services', label: 'AWS Managed Services' },
    { value: 'AWS Migration Hub', label: 'AWS Migration Hub' },
    { value: 'AWS OpsWorks', label: 'AWS OpsWorks' },
    { value: 'AWS Proton', label: 'AWS Proton' },
    { value: 'AWS RoboMaker', label: 'AWS RoboMaker' },
    { value: 'AWS CodeStar', label: 'AWS CodeStar' },
    { value: 'AWS Ground Station', label: 'AWS Ground Station' },
    { value: 'AWS IoT Core', label: 'AWS IoT Core' },
    { value: 'AWS IoT Greengrass', label: 'AWS IoT Greengrass' },
    { value: 'AWS IoT Analytics', label: 'AWS IoT Analytics' },
    { value: 'AWS IoT Device Defender', label: 'AWS IoT Device Defender' },
    { value: 'AWS IoT Events', label: 'AWS IoT Events' },
    { value: 'AWS IoT Things Graph', label: 'AWS IoT Things Graph' },
    { value: 'AWS IoT SiteWise', label: 'AWS IoT SiteWise' },
    { value: 'AWS IoT TwinMaker', label: 'AWS IoT TwinMaker' },
    { value: 'AWS IoT ExpressLink', label: 'AWS IoT ExpressLink' },
    { value: 'AWS IoT Device Management', label: 'AWS IoT Device Management' },
    { value: 'AWS IoT 1-Click', label: 'AWS IoT 1-Click' },
    { value: 'AWS IoT Core for LoRaWAN', label: 'AWS IoT Core for LoRaWAN' },
    { value: 'AWS Partner Network', label: 'AWS Partner Network' },
    { value: 'Amazon EC2 Spot Instances', label: 'Amazon EC2 Spot Instances' },
    { value: 'Amazon Elastic Graphics', label: 'Amazon Elastic Graphics' },
    { value: 'Amazon AppStream 2.0', label: 'Amazon AppStream 2.0' },
    { value: 'Amazon WorkSpaces', label: 'Amazon WorkSpaces' },
    { value: 'Amazon WorkDocs', label: 'Amazon WorkDocs' },
    { value: 'Amazon WorkLink', label: 'Amazon WorkLink' },
    { value: 'Amazon WorkMail', label: 'Amazon WorkMail' },
    { value: 'AWS End User Computing', label: 'AWS End User Computing' },
    { value: 'Amazon Honeycode', label: 'Amazon Honeycode' },
    { value: 'Amazon Managed Workflows for Apache Airflow', label: 'Amazon Managed Workflows for Apache Airflow' },
    { value: 'AWS CloudFormation Registry', label: 'AWS CloudFormation Registry' },
    { value: 'AWS Solutions Constructs', label: 'AWS Solutions Constructs' },
    { value: 'AWS Cloud Development Kit', label: 'AWS Cloud Development Kit' },
    { value: 'AWS Service Catalog', label: 'AWS Service Catalog' },
    { value: 'AWS Control Tower', label: 'AWS Control Tower' },
    { value: 'AWS Control Tower Account Factory', label: 'AWS Control Tower Account Factory' },
    { value: 'AWS Landing Zone', label: 'AWS Landing Zone' },
    { value: 'AWS Managed Services - AMS Advanced', label: 'AWS Managed Services - AMS Advanced' },
    { value: 'AWS Service Management Connector', label: 'AWS Service Management Connector' },
    { value: 'AWS Systems Manager Incident Manager', label: 'AWS Systems Manager Incident Manager' },
    { value: 'AWS Service Management Solutions', label: 'AWS Service Management Solutions' },
    { value: 'AWS Compute Optimizer', label: 'AWS Compute Optimizer' },
    { value: 'AWS Cost Explorer', label: 'AWS Cost Explorer' },
    { value: 'AWS Budgets', label: 'AWS Budgets' },
    { value: 'AWS Application Cost Profiler', label: 'AWS Application Cost Profiler' },
    { value: 'AWS Cost Anomaly Detection', label: 'AWS Cost Anomaly Detection' },
    { value: 'AWS Cost and Usage Report', label: 'AWS Cost and Usage Report' },
    { value: 'AWS Cost Categories', label: 'AWS Cost Categories' },
    { value: 'AWS Cost Explorer Resource Groups', label: 'AWS Cost Explorer Resource Groups' },
    { value: 'AWS Cost Optimization', label: 'AWS Cost Optimization' },
    { value: 'AWS Cost Allocation Tags', label: 'AWS Cost Allocation Tags' },
    { value: 'AWS Saving Plans', label: 'AWS Saving Plans' },
    { value: 'AWS Price List API', label: 'AWS Price List API' },
    { value: 'AWS Free Tier', label: 'AWS Free Tier' },
    { value: 'AWS Activate', label: 'AWS Activate' },
    { value: 'AWS Marketplace', label: 'AWS Marketplace' },
    { value: 'AWS Data Exchange', label: 'AWS Data Exchange' },
    { value: 'AWS Partner Network Programs', label: 'AWS Partner Network Programs' },
    { value: 'AWS SaaS Factory', label: 'AWS SaaS Factory' },
    { value: 'AWS Activate Console', label: 'AWS Activate Console' },
    { value: 'AWS Activate Portfolio', label: 'AWS Activate Portfolio' },
    { value: 'AWS Activate Founders', label: 'AWS Activate Founders' },
    { value: 'AWS Activate Portfolio Plus', label: 'AWS Activate Portfolio Plus' },
    { value: 'AWS Activate Portfolio Community', label: 'AWS Activate Portfolio Community' },
    { value: 'AWS IoT EduKit', label: 'AWS IoT EduKit' },
    { value: 'AWS IoT ExpressLink Certified', label: 'AWS IoT ExpressLink Certified' },
    { value: 'AWS IoT Events Certified', label: 'AWS IoT Events Certified' },
    { value: 'AWS IoT Greengrass Certified', label: 'AWS IoT Greengrass Certified' },
    { value: 'AWS IoT Things Graph Certified', label: 'AWS IoT Things Graph Certified' },
    { value: 'AWS IoT SiteWise Certified', label: 'AWS IoT SiteWise Certified' },
    { value: 'AWS IoT TwinMaker Certified', label: 'AWS IoT TwinMaker Certified' },
    { value: 'AWS RoboMaker Certified', label: 'AWS RoboMaker Certified' },
    { value: 'AWS Proton Certified', label: 'AWS Proton Certified' },
    { value: 'AWS CodeStar Certified', label: 'AWS CodeStar Certified' },
    { value: 'AWS Control Tower Certified', label: 'AWS Control Tower Certified' },
    { value: 'AWS Control Tower Account Factory Certified', label: 'AWS Control Tower Account Factory Certified' },
    { value: 'AWS Landing Zone Certified', label: 'AWS Landing Zone Certified' },
    { value: 'AWS Managed Services - AMS Advanced Certified', label: 'AWS Managed Services - AMS Advanced Certified' },
    { value: 'AWS Service Management Connector Certified', label: 'AWS Service Management Connector Certified' },
    { value: 'AWS Service Management Solutions Certified', label: 'AWS Service Management Solutions Certified' },
    { value: 'AWS Solutions Constructs Certified', label: 'AWS Solutions Constructs Certified' },
    { value: 'AWS CloudFormation Registry Certified', label: 'AWS CloudFormation Registry Certified' },
    { value: 'AWS Cloud Development Kit Certified', label: 'AWS Cloud Development Kit Certified' },
    { value: 'AWS Marketplace Certified', label: 'AWS Marketplace Certified' },
    { value: 'AWS Data Exchange Certified', label: 'AWS Data Exchange Certified' },
    { value: 'AWS Activate Console Certified', label: 'AWS Activate Console Certified' },
    { value: 'AWS Activate Portfolio Certified', label: 'AWS Activate Portfolio Certified' },
    { value: 'AWS Activate Founders Certified', label: 'AWS Activate Founders Certified' },
    { value: 'AWS Activate Portfolio Plus Certified', label: 'AWS Activate Portfolio Plus Certified' },
    { value: 'AWS Activate Portfolio Community Certified', label: 'AWS Activate Portfolio Community Certified' },
    { value: 'AWS Cost Explorer Certified', label: 'AWS Cost Explorer Certified' },
    { value: 'AWS Budgets Certified', label: 'AWS Budgets Certified' },
    { value: 'AWS Application Cost Profiler Certified', label: 'AWS Application Cost Profiler Certified' },
    { value: 'AWS Cost Anomaly Detection Certified', label: 'AWS Cost Anomaly Detection Certified' },
    { value: 'AWS Cost and Usage Report Certified', label: 'AWS Cost and Usage Report Certified' },
    { value: 'AWS Cost Categories Certified', label: 'AWS Cost Categories Certified' },
    { value: 'AWS Cost Explorer Resource Groups Certified', label: 'AWS Cost Explorer Resource Groups Certified' },
    { value: 'AWS Cost Optimization Certified', label: 'AWS Cost Optimization Certified' },
    { value: 'AWS Cost Allocation Tags Certified', label: 'AWS Cost Allocation Tags Certified' },
    { value: 'AWS Saving Plans Certified', label: 'AWS Saving Plans Certified' },
    { value: 'AWS Price List API Certified', label: 'AWS Price List API Certified' },
    { value: 'AWS Free Tier Certified', label: 'AWS Free Tier Certified' },
    { value: 'AWS Cost Explorer Certified - Advanced', label: 'AWS Cost Explorer Certified - Advanced' },
    { value: 'AWS Budgets Certified - Advanced', label: 'AWS Budgets Certified - Advanced' },
    { value: 'AWS Application Cost Profiler Certified - Advanced', label: 'AWS Application Cost Profiler Certified - Advanced' },
    { value: 'AWS Cost Anomaly Detection Certified - Advanced', label: 'AWS Cost Anomaly Detection Certified - Advanced' },
    { value: 'AWS Cost and Usage Report Certified - Advanced', label: 'AWS Cost and Usage Report Certified - Advanced' },
    { value: 'AWS Cost Categories Certified - Advanced', label: 'AWS Cost Categories Certified - Advanced' },
    { value: 'AWS Cost Explorer Resource Groups Certified - Advanced', label: 'AWS Cost Explorer Resource Groups Certified - Advanced' },
    { value: 'AWS Cost Optimization Certified - Advanced', label: 'AWS Cost Optimization Certified - Advanced' },
    { value: 'AWS Cost Allocation Tags Certified - Advanced', label: 'AWS Cost Allocation Tags Certified - Advanced' },
    { value: 'AWS Saving Plans Certified - Advanced', label: 'AWS Saving Plans Certified - Advanced' },
    { value: 'AWS Price List API Certified - Advanced', label: 'AWS Price List API Certified - Advanced' },
    { value: 'AWS Free Tier Certified - Advanced', label: 'AWS Free Tier Certified - Advanced' },
    { value: 'AWS Activate Certified', label: 'AWS Activate Certified' },
    { value: 'AWS Activate Portfolio Certified - Advanced', label: 'AWS Activate Portfolio Certified - Advanced' },
    { value: 'AWS Activate Founders Certified - Advanced', label: 'AWS Activate Founders Certified - Advanced' },
    { value: 'AWS Activate Portfolio Plus Certified - Advanced', label: 'AWS Activate Portfolio Plus Certified - Advanced' },
    { value: 'AWS Activate Portfolio Community Certified - Advanced', label: 'AWS Activate Portfolio Community Certified - Advanced' },
    { value: 'AWS Marketplace Certified - Advanced', label: 'AWS Marketplace Certified - Advanced' },
    { value: 'AWS Data Exchange Certified - Advanced', label: 'AWS Data Exchange Certified - Advanced' },
    { value: 'AWS Activate Console Certified - Advanced', label: 'AWS Activate Console Certified - Advanced' },
    { value: 'AWS Activate Portfolio Certified - Advanced', label: 'AWS Activate Portfolio Certified - Advanced' },
    { value: 'AWS Activate Founders Certified - Advanced', label: 'AWS Activate Founders Certified - Advanced' },
    { value: 'AWS Activate Portfolio Plus Certified - Advanced', label: 'AWS Activate Portfolio Plus Certified - Advanced' },
    { value: 'AWS Activate Portfolio Community Certified - Advanced', label: 'AWS Activate Portfolio Community Certified - Advanced' },
    { value: 'AWS IoT EduKit Certified - Advanced', label: 'AWS IoT EduKit Certified - Advanced' },
    { value: 'AWS IoT ExpressLink Certified - Advanced', label: 'AWS IoT ExpressLink Certified - Advanced' },
    { value: 'AWS IoT Events Certified - Advanced', label: 'AWS IoT Events Certified - Advanced' },
    { value: 'AWS IoT Greengrass Certified - Advanced', label: 'AWS IoT Greengrass Certified - Advanced' },
    { value: 'AWS IoT Things Graph Certified - Advanced', label: 'AWS IoT Things Graph Certified - Advanced' },
    { value: 'AWS IoT SiteWise Certified - Advanced', label: 'AWS IoT SiteWise Certified - Advanced' },
    { value: 'AWS IoT TwinMaker Certified - Advanced', label: 'AWS IoT TwinMaker Certified - Advanced' },
    { value: 'AWS RoboMaker Certified - Advanced', label: 'AWS RoboMaker Certified - Advanced' },
    { value: 'AWS Proton Certified - Advanced', label: 'AWS Proton Certified - Advanced' },
    { value: 'AWS CodeStar Certified - Advanced', label: 'AWS CodeStar Certified - Advanced' },
    { value: 'AWS Control Tower Certified - Advanced', label: 'AWS Control Tower Certified - Advanced' },
    { value: 'AWS Control Tower Account Factory Certified - Advanced', label: 'AWS Control Tower Account Factory Certified - Advanced' },
    { value: 'AWS Landing Zone Certified - Advanced', label: 'AWS Landing Zone Certified - Advanced' },
    { value: 'AWS Managed Services - AMS Advanced Certified - Advanced', label: 'AWS Managed Services - AMS Advanced Certified - Advanced' },
    { value: 'AWS Service Management Connector Certified - Advanced', label: 'AWS Service Management Connector Certified - Advanced' },
    { value: 'AWS Service Management Solutions Certified - Advanced', label: 'AWS Service Management Solutions Certified - Advanced' },  
    { value: 'AWS CloudFormation Designer', label: 'AWS CloudFormation Designer' },
    { value: 'AWS Systems Manager', label: 'AWS Systems Manager' },
    { value: 'AWS AppConfig', label: 'AWS AppConfig' },
    { value: 'AWS Health', label: 'AWS Health' },
    { value: 'AWS Security Hub', label: 'AWS Security Hub' },
    { value: 'AWS Artifact', label: 'AWS Artifact' },
    { value: 'AWS Certificate Manager', label: 'AWS Certificate Manager' },
    { value: 'AWS Firewall Manager', label: 'AWS Firewall Manager' },
    { value: 'AWS Key Management Service', label: 'AWS Key Management Service' },
    { value: 'AWS Resource Access Manager', label: 'AWS Resource Access Manager' },
    { value: 'AWS Security Token Service', label: 'AWS Security Token Service' },
    { value: 'AWS Shield', label: 'AWS Shield' },
    { value: 'AWS WAF', label: 'AWS WAF' },
    { value: 'AWS Web Application Firewall', label: 'AWS Web Application Firewall' },
    { value: 'AWS WAF Classic', label: 'AWS WAF Classic' },
    { value: 'AWS App Mesh', label: 'AWS App Mesh' },
    { value: 'AWS Ground Station', label: 'AWS Ground Station' },
    { value: 'AWS Cloud9', label: 'AWS Cloud9' },
    { value: 'AWS CodeCommit', label: 'AWS CodeCommit' },
    { value: 'AWS CodeArtifact', label: 'AWS CodeArtifact' },
    { value: 'AWS CodePipeline', label: 'AWS CodePipeline' },
    { value: 'AWS CodeBuild', label: 'AWS CodeBuild' },
    { value: 'AWS CodeDeploy', label: 'AWS CodeDeploy' },
    { value: 'AWS Device Farm', label: 'AWS Device Farm' },
    { value: 'AWS IoT Events', label: 'AWS IoT Events' },
    { value: 'AWS IoT SiteWise', label: 'AWS IoT SiteWise' },
    { value: 'AWS IoT Things Graph', label: 'AWS IoT Things Graph' },
    { value: 'AWS IoT Device Defender', label: 'AWS IoT Device Defender' },
    { value: 'AWS IoT Device Management', label: 'AWS IoT Device Management' },
    { value: 'AWS IoT Events', label: 'AWS IoT Events' },
    { value: 'AWS IoT Device Defender', label: 'AWS IoT Device Defender' },
    { value: 'AWS Ground Station', label: 'AWS Ground Station' },
    { value: 'AWS Outposts', label: 'AWS Outposts' },
    { value: 'AWS Backup', label: 'AWS Backup' },
    { value: 'AWS Elastic Inference', label: 'AWS Elastic Inference' },
    { value: 'Amazon EC2 Auto Scaling', label: 'Amazon EC2 Auto Scaling' },
    { value: 'AWS App Runner', label: 'AWS App Runner' },
    { value: 'Amazon Elastic Container Registry', label: 'Amazon Elastic Container Registry' },
    { value: 'Amazon Elastic Kubernetes Service', label: 'Amazon Elastic Kubernetes Service' },
    { value: 'Amazon Lightsail', label: 'Amazon Lightsail' },
    { value: 'AWS Amplify Hosting', label: 'AWS Amplify Hosting' },
    { value: 'AWS Proton', label: 'AWS Proton' },
    { value: 'AWS Resilience Hub', label: 'AWS Resilience Hub' },
    { value: 'Google Compute Engine (GCE)', label: 'Google Compute Engine (GCE)' },
    { value: 'Google Kubernetes Engine (GKE)', label: 'Google Kubernetes Engine (GKE)' },
    { value: 'Google Cloud Storage (GCS)', label: 'Google Cloud Storage (GCS)' },
    { value: 'Google BigQuery', label: 'Google BigQuery' },
    { value: 'Google Cloud Functions', label: 'Google Cloud Functions' },
    { value: 'Google Cloud Pub/Sub', label: 'Google Cloud Pub/Sub' },
    { value: 'Google Cloud SQL', label: 'Google Cloud SQL' },
    { value: 'Google Cloud CDN', label: 'Google Cloud CDN' },
    { value: 'Google Cloud Load Balancing', label: 'Google Cloud Load Balancing' },
    { value: 'Google Cloud IAM (Identity and Access Management)', label: 'Google Cloud IAM (Identity and Access Management)' },
    { value: 'Google Cloud SDK', label: 'Google Cloud SDK' },
    { value: 'Google Cloud Console', label: 'Google Cloud Console' },
    { value: 'Google Operations Suite', label: 'Google Operations Suite' },
];