import axios from 'axios'
import ACTION_TYPES from '../../actionTypes'


const getPendingForRecruiterSuccess = (pendingJobs) => {
    return {
        type: ACTION_TYPES.GET_PENDING_JOBS_FOR_RECRUITER_SUCCESS,
        payload: pendingJobs
    }
}

const getPendingForRecruiterFailure = (error) => {
    return {
        type: ACTION_TYPES.GET_PENDING_JOBS_FOR_RECRUITER_FAILURE,
        payload: error
    }
}
// const { companyId , userId } = data;
export const getPendingJobsForRecruiter = ({userId, companyId}) => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/pending-jobs/?userId=${userId}&companyId=${companyId}`)
            const pendingJobs = response.data
            dispatch(getPendingForRecruiterSuccess(pendingJobs))
        } catch (error) {
            dispatch(getPendingForRecruiterFailure(error.message))
        }
    }
}
