import React from 'react';
import ErrorAlert from './ErrorAlert';

const SelectInput = ({ id, value, onChange, options, error }) => (
    <div className='mt-3'>
        <label htmlFor={id} className="sr-only">User Type</label>
        {error && (
            <ErrorAlert message={error} />
        )}
        <select
            id={id}
            name={id}
            value={value}
            onChange={onChange}
            required
            className="relative block w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
        >
            {options.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    </div>
);

export default SelectInput;
