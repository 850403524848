import React from 'react';
import DefaultP from '../../assets/default_p.jpg';

const CandidateProfilePicture = ({ candidate }) => {
  return (
    <div className="flex-shrink-0 mr-6">
      <img
        className="h-32 w-32 rounded-full object-cover"
        src={DefaultP}
        alt={`${candidate.firstName || candidate.tbl_user.firstName} ${candidate.lastName || candidate.tbl_user.secondName}`}
      />
    </div>
  );
};

export default CandidateProfilePicture;