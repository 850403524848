import React from "react";
import Slider from "react-slick";
import { FaTruck, FaChartLine, FaLightbulb } from 'react-icons/fa';
import imagenes from "../../../assets/LANDING/ILLUSTRATION/2722-Portada-software-para-el-recorrido-del-cliente.jpg";
import photo1 from "../../../assets/LANDING/ILLUSTRATION/illus1.jpg";
import photo2 from "../../../assets/LANDING/ILLUSTRATION/illus2.jpg";
import photo3 from "../../../assets/LANDING/ILLUSTRATION/illus3.jpg";

const ServiceHardwareSolution = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="bg-gray-50">
      <div className="max-w-6xl mx-auto px-4 py-16 text-center">
        <h1 className="text-3xl font-bold text-blue-800">Streamline Your Hardware Management Across LATAM with Hardware Solution</h1>
        <p className="mt-4 text-lg text-gray-600">
          Effortlessly handle hardware logistics for your expanding tech team. In the fast-paced tech industry, managing hardware for a growing team is critical yet challenging. "Hardware Solution" by Rysconnect simplifies the hardware lifecycle for companies expanding in Latin America, covering onboarding, offboarding, and storage.
        </p>
        <button className="mt-4 px-6 py-2 bg-blue-600 text-white rounded-full">Request a demo</button>

        <div className="mt-12">
          <img src={imagenes} width={600} height={400} alt="Illustration of hardware management process" className="mx-auto" />
        </div>
      </div>

      <div className="max-w-6xl mx-auto px-4 py-16 text-center">
        <h2 className="text-2xl font-bold text-blue-800">Why Choose Hardware Solution?</h2>
        <p className="mt-4 text-lg text-gray-600">
          "Hardware Solution" by Rysconnect simplifies the hardware lifecycle for companies expanding in Latin America, covering onboarding, offboarding, and storage.
        </p>
        {/* Agregar carrusel */}
        
        <div className="mt-12 grid grid-cols-1 md:grid-cols-1 gap-8 text-center">
          <Slider {...settings}>
            <div className="flex flex-col items-center">
              <i className="fas fa-cogs text-5xl text-yellow-500"></i>
              <h3 className="mt-4 text-xl font-bold">Complete Lifecycle Management</h3>
              <p className="mt-2 text-gray-600 mb-5">
                We manage onboarding, offboarding, storage, and reassignment of hardware, allowing you to focus on scaling your business.
              </p>
              <img src={photo1} alt="Illustration of key benefits" className="mx-auto " width={400} height={150}/>
            </div>
            <div className="flex flex-col items-center">
              <i className="fas fa-globe text-5xl text-yellow-500"></i>
              <h3 className="mt-4 text-xl font-bold">LATAM-wide Coverage</h3>
              <p className="mt-2 text-gray-600 mb-5">
                Our service covers multiple Latin American countries, including Brazil, Mexico, Argentina, and more.
              </p>
              <img src={photo2} alt="Illustration of key benefits" className="mx-auto "width={400} height={150}/>
            </div>
            <div className="flex flex-col items-center">
              <i className="fas fa-handshake text-5xl text-yellow-500"></i>
              <h3 className="mt-4 text-xl font-bold">Simplicity and Convenience</h3>
              <p className="mt-2 text-gray-600 mb-5">
                Our streamlined process eliminates the complexities of hardware logistics, making tech equipment management straightforward and stress-free.
              </p>
              <img src={photo3} alt="Illustration of key benefits" className="mx-auto " width={400} height={150}/>
            </div>
          </Slider>
        </div>
      </div>

      <div className="max-w-6xl mx-auto px-4 py-16 text-center">
        <h2 className="text-2xl font-bold text-blue-800">Key Benefits of Using Hardware Solution</h2>
        <p className="mt-4 text-lg text-gray-600">
          Don't let hardware logistics hold you back. With Hardware Solution, managing your tech equipment across Latin America is easier than ever. Schedule a call today to simplify your hardware management and focus on growing your tech team.
        </p>

        <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8 text-left">
          <div className="flex flex-col items-center">
            <FaTruck className="text-5xl text-yellow-500" />
            <h3 className="mt-4 text-xl font-bold">Logistical Ease</h3>
            <p className="mt-2 text-gray-600">We handle all tech hardware logistics, so you don't have to.</p>
          </div>
          <div className="flex flex-col items-center">
            <FaChartLine className="text-5xl text-yellow-500" />
            <h3 className="mt-4 text-xl font-bold">Scalability</h3>
            <p className="mt-2 text-gray-600">Our service grows with your company, ensuring smooth transitions and continuous support.</p>
          </div>
          <div className="flex flex-col items-center">
            <FaLightbulb className="text-5xl text-yellow-500" />
            <h3 className="mt-4 text-xl font-bold">Focus on Innovation</h3>
            <p className="mt-2 text-gray-600">Free up your team to focus on innovation and core business strategies.</p>
          </div>
        </div>
      </div>

      <div className="py-12">
        <div className="max-w-6xl mx-auto px-4 text-center ">
          <h2 className="text-2xl font-bold">Ready to Enhance Your Hardware Management?</h2>
          <button className="mt-4 px-6 py-2 bg-blue-600 text-white rounded-full">Try it now</button>
        </div>
      </div>
    </div>
  );
};

export default ServiceHardwareSolution;
