import axios from 'axios'
import ACTION_TYPES from '../../actionTypes'


const getJobsForAdminSuccess = (jobs) => {
    return {
        type: ACTION_TYPES.GET_JOBS_FOR_ADMIN_SUCCESS,
        payload: jobs
    }
}

const getJobsForAdminFailure = (error) => {
    return {
        type: ACTION_TYPES.GET_JOBS_FOR_ADMIN_FAILURE,
        payload: error
    }
}

export const getJobsForAdmin = (idCompany) => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/jobs-for-admin/?idCompany=${idCompany}`)
            const jobs = response.data
            dispatch(getJobsForAdminSuccess(jobs))
        } catch (error) {
            dispatch(getJobsForAdminFailure(error.message))
        }
    }
}

