export const validation2 = (data) => {
    let isError = false
    let incorrect = {}

    let jobName = data.jobName.trim()
    let locationAvailability = data.locationAvailability //Array
    let salary = data.salary.trim()
    let mustHaveTechnologies = data.mustHaveTechnologies //Array
    let yearsOfExperience = data.yearsOfExperience.trim()
    let seniority = data.seniority.trim()
    let workingScheme = data.workingScheme.trim()
    let jobType = data.jobType.trim()
    let contractTerm = data.contractTerm.trim()
    let englishLevel = data.englishLevel.trim()


    const regexLetters = RegExp(/^[ a-zA-ZñÑáéíóúÁÉÍÓÚ,.]+$/)
    
    
        if (!jobName){
            incorrect.jobName = "Job name cannot be empty."
            isError = true
        }else if(!regexLetters.test(jobName)){
            incorrect.jobName = "Only letters"
            isError = true
        }

        if (locationAvailability.length <= 0) {
        incorrect.locationAvailability = "Location availability cannot be empty"
        isError = true
        }

        if (!salary) {
        incorrect.salary = 'Salary cannot be empty'
        isError = true
        }
        else if (isNaN(salary)) {
        incorrect.salary = 'Salary must be a number'
        isError = true
        }
        
        // else if (salary <= 0) {
        // incorrect.salary = 'Salary must be greater than 0'
        // isError = true
        // }

        if (mustHaveTechnologies.length <= 0) {
            incorrect.mustHaveTechnologies = 'Must have technologies cannot be empty'
            isError = true
        }

        if (!yearsOfExperience) {
        incorrect.yearsOfExperience = 'Years of experience cannot be empty'
        isError = true
        }

        if (!seniority) {
        incorrect.seniority = "Seniority cannot be empty";
        isError = true;
    }

    if (!workingScheme) {
        incorrect.workingScheme = "Working scheme cannot be empty";
        isError = true;
    }

    if (!jobType) {
        incorrect.jobType = "Job type cannot be empty";
        isError = true;
    }

    if (!contractTerm) {
        incorrect.contractTerm = "Contract term cannot be empty";
        isError = true;
    }

    if (!englishLevel) {
        incorrect.englishLevel = "English level cannot be empty";
        isError = true;
    }

    
    return isError ? incorrect : null
}
