import React from 'react';
import Select from 'react-select';
import { technologyOptions } from '../../view/FormJob/technologyOptions';

const SelectMainTech = ({ errors, form, mainTech, handleSelectChange, placeholder, error }) => {
    return (
<div className="col-span-1">
              <label className="block text-gray-700">
                Main Tech <span className="text-red-500">*</span>
              </label>{errors.mainTech && (
                <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                  <span>{errors.mainTech}</span>
                </div>
              )}
              <Select
              name="mainTech"
              isMulti
              value={form.mainTech?.map(tech => ({ value: tech, label: tech }))}
              onChange={handleSelectChange}
              options={technologyOptions}
              className="block w-full"
            />
            </div>
    );
}

export default SelectMainTech;
