import React from 'react';
import { Link } from 'react-router-dom';

function PricingTable() {
  const plans = [
    {
      title: 'Connect Pro',
      price: '$499 USD/month',
      features: [
        'Access to RysConnect',
        'Company Profile',
        'Unlimited Job Posting',
        'Unlimited Users per account',
        'AI-based Candidates Evaluations (Hardskills and Softskills) - Unlimited',
        'English Evaluations - Unlimited',
        'AI-powered candidate recommendations',
        'Boosted job',
        'Personalized assistance from our Sr. Tech Recruiters',
        'Access to our Tech Talent Marketplace',
        'Automated Job posting and active talent search across RysConnect channels',
      ],
      buttonLabel: 'Get Started',
      color: 'from-purple-500 to-purple-600',
    },
    {
      title: 'Connect Hunter',
      price: 'Pay Per Hire',
      features: [
        'Access our entire network of tech headhunters across LATAM',
        'Share your hiring needs and start receiving candidates',
        'Pricing based on expertise level (Jr, Mid, Sr)',
        
      ],
      buttonLabel: 'Contact Us',
      color: 'from-green-500 to-green-600',
    },
  ];

  const extraServices = [
    {
      title: 'Connect Admin',
      description: 'We take care of your contractors',
      services: ['Paperwork', 'Payments', 'HR Follow-up'],
      note: 'No extra fees, we fit your budget.',
    },
    {
      title: 'Connect Hardware',
      description: 'We provide the hardware your talent needs',
      services: [
        'Equipment provisioning',
        'Onboarding & offboarding',
        'Storage & reassignment',
      ],
    },
  ];

  return (
    <div className="container mx-auto py-16 px-4 space-y-16">
      {/* Pricing Section */}
      <div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {plans.map((plan, index) => (
            <div
              key={index}
              className="border rounded-lg p-8 shadow-lg bg-white hover:shadow-xl transition duration-300"
            >
              <h3
                className={`text-3xl font-semibold text-center mb-4 text-gradient bg-gradient-to-r ${plan.color}`}
              >
                {plan.title}
              </h3>
              <p className="text-2xl text-center font-medium mb-6">
                {plan.price}
              </p>
              <ul className="space-y-2 mb-8 text-gray-700">
                {plan.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center space-x-2">
                    <span className="text-green-500">✔</span>
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
              {plan.buttonLabel === 'Get Started' ? (
                <Link target="_blank" to="/signup">
                  <button
                    className={`w-full py-3 rounded-lg bg-gradient-to-r ${plan.color} text-white font-semibold text-lg`}
                  >
                    {plan.buttonLabel}
                  </button>
                </Link>
              ) : (
                <a href="https://calendly.com/sergio_razo_ryscode/30min" target="_blank" rel="noopener noreferrer">
                  <button
                    className={`w-full py-3 rounded-lg bg-gradient-to-r ${plan.color} text-white font-semibold text-lg`}
                  >
                    {plan.buttonLabel}
                  </button>
                </a>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Closing Message */}
      <div className="bg-gradient-to-r from-blue-500 to-purple-600 text-white p-8 rounded-lg shadow-lg">
        <p className="text-lg font-medium text-center">
          Cut your talent acquisition costs and time by up to 70%. Leave behind job boards
          that don't connect you with the right talent, high fees from staffing agencies, and
          exorbitant prices of traditional headhunting. Transform and innovate your tech recruitment
          with <span className="font-bold">RysConnect.io</span>.
        </p>
      </div>

        {/* Extra Services Section */}
        <div className="py-16 bg-gradient-to-b from-gray-50 to-gray-100">
          <h2 className="text-4xl font-bold text-center mb-12 text-gray-800">Extra Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-5xl mx-auto">
            {extraServices.map((service, index) => (
              <div
                key={index}
                className="rounded-xl p-8 shadow-lg bg-gradient-to-br from-white to-gray-50 border border-gray-200 hover:shadow-2xl transition duration-300 transform hover:-translate-y-1"
              >
                <h3 className="text-2xl font-bold text-gray-800 mb-4">{service.title}</h3>
                <p className="text-gray-600 mb-4">{service.description}</p>
                <ul className="space-y-2 text-gray-700">
                  {service.services.map((item, idx) => (
                    <li key={idx} className="flex items-center space-x-2">
                      <span className="text-blue-500">•</span>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
                {service.note && (
                  <p className="mt-6 text-sm italic text-gray-500">{service.note}</p>
                )}
              </div>
            ))}
          </div>
        </div>
    </div>
  );
}

export default PricingTable;
