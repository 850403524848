import React,{useState} from 'react';

const INITIAL_STATE_FILTERS = { 
    seniority:'',
    workingScheme:'',
    englishLevel:'',
    location:'',
    mustHaveTechnologies:[]
  }

function Filters({filterJobs,resetJobsFilter,  setCurrentPage}) {

    const [selectState, setSelectState] = useState('')
    const [filters, setFilters] = useState(INITIAL_STATE_FILTERS)

    const onHandleChangeFilters = (event) => {
        if (event.target.name === 'mustHaveTechnologies') {
          if(!filters[event.target.name].includes(event.target.value)){
            setSelectState(event.target.value)
            setFilters({...filters, [event.target.name]:[...filters[event.target.name], event.target.value]})
          }
        }else{
          setFilters({...filters, [event.target.name]:event.target.value})
        }
      }

    const onHandleClickTags = (tech) => {
        if (filters.mustHaveTechnologies.includes(tech)) {
            const result = filters.mustHaveTechnologies.filter(technology => technology != tech) 
            setFilters({...filters, mustHaveTechnologies: result})
            setSelectState('')
        }
    }

    const onHandleFilterJobs = () => {
        if (hasFilters) {
          setCurrentPage(1)
            filterJobs(filters)
        }
      }
    
      const onHandleResetFilters = () =>{
        setSelectState('')
        setFilters(INITIAL_STATE_FILTERS)
        resetJobsFilter()
      }
    
      const hasFilters = 
      filters.seniority || 
      filters.workingScheme || 
      filters.location || 
      filters.englishLevel ||
      filters.mustHaveTechnologies.length > 0;

    return (
        <aside className="w-full flex flex-wrap flex-col sm:w-4/4 p-4">
            <div key='technologies' className="mb-4">
                    <label className="block text-gray-700 font-semibold mb-2">Technologies</label>
                    <div className='flex flex-column justify-center items-center flex-wrap'>
                    {filters?.mustHaveTechnologies.length > 0 && filters.mustHaveTechnologies.map((tech) => {
                    return(
                        <div key={tech} className='bg-blue-600 text-white rounded-xl p-1 m-1 cursor-pointer' onClick={()=>onHandleClickTags(tech)} >
                        <span>{tech}</span>
                        </div>
                    )})
                    }
                </div>


                    <select className="w-full p-2 border border-gray-300 rounded bg-white shadow-sm focus:outline-none focus:border-blue-400"
                    onChange={onHandleChangeFilters}
                    name='mustHaveTechnologies'
                    value={selectState}>
                        <option value="" disabled>Select one...</option>
                        <option value='Angular'>Angular</option>
                        <option value='ASP.NET'>ASP.NET</option>
                        <option value='Backbone.js'>Backbone.js</option>
                        <option value='C#'>C#</option>
                        <option value='C++'>C++</option>
                        <option value='Cordova'>Cordova</option>
                        <option value='Dart'>Dart</option>
                        <option value='Django'>Django</option>
                        <option value='Elixir'>Elixir</option>
                        <option value='Ember.js'>Ember.js</option>
                        <option value='Express'>Express</option>
                        <option value='Flask'>Flask</option>
                        <option value='Flutter'>Flutter</option>
                        <option value='Go'>Go</option>
                        <option value='Haskell'>Haskell</option>
                        <option value='Ionic'>Ionic</option>
                        <option value='Java'>Java</option>
                        <option value='JavaScript'>JavaScript</option>
                        <option value='Kotlin'>Kotlin</option>
                        <option value='Laravel'>Laravel</option>
                        <option value='Meteor'>Meteor</option>
                        <option value='Next.js'>Next.js</option>
                        <option value='Node.js'>Node.js</option>
                        <option value='Nuxt.js'>Nuxt.js</option>
                        <option value='Objective-C'>Objective-C</option>
                        <option value='Perl'>Perl</option>
                        <option value='PHP'>PHP</option>
                        <option value='Python'>Python</option>
                        <option value='React'>React</option>
                        <option value='React Native'>React Native</option>
                        <option value='Ruby'>Ruby</option>
                        <option value='Ruby on Rails'>Ruby on Rails</option>
                        <option value='Rust'>Rust</option>
                        <option value='Scala'>Scala</option>
                        <option value='Svelte'>Svelte</option>
                        <option value='Swift'>Swift</option>
                        <option value='Symfony'>Symfony</option>
                        <option value='TypeScript'>TypeScript</option>
                        <option value='Unity'>Unity</option>
                        <option value='Unreal Engine'>Unreal Engine</option>
                        <option value='Vue.js'>Vue.js</option>
                        <option value='Xamarin'>Xamarin</option>
                        <option value='Zend Framework'>Zend Framework</option>
                    </select>
            </div>
            <div key='seniority' className="mb-4">
                    <label className="block text-gray-700 font-semibold mb-2">Seniority</label>
                    <select className="w-full p-2 border border-gray-300 rounded bg-white shadow-sm focus:outline-none focus:border-blue-400"
                    name='seniority'
                    onChange={onHandleChangeFilters} value={filters.seniority}>
                        <option value='' disabled>Select one...</option>
                        <option value="Trainee">Trainee</option>
                        <option value="Junior">Junior</option>
                        <option value="Mid">Mid</option>
                        <option value="Senior">Senior</option>
                        <option value="Tech Lead">Tech Lead</option>
                        <option value="Architect">Architect</option>
                    </select>
            </div>
            <div key='workingscheme' className="mb-4">
                    <label className="block text-gray-700 font-semibold mb-2">Working Scheme</label>
                    <select className="w-full p-2 border border-gray-300 rounded bg-white shadow-sm focus:outline-none focus:border-blue-400"
                    name='workingScheme'
                    onChange={onHandleChangeFilters} value={filters.workingScheme}>
                        <option value="" disabled>Select one...</option>
                        <option value="Remote">Remote</option>
                        <option value="On-site">On-site</option>
                        <option value="Hybrid">Hybrid</option> 
                    </select>
            </div>
            <div key='englishlevel' className="mb-4">
                    <label className="block text-gray-700 font-semibold mb-2">English Level</label>
                    <select className="w-full p-2 border border-gray-300 rounded bg-white shadow-sm focus:outline-none focus:border-blue-400"
                    name='englishLevel'
                    onChange={onHandleChangeFilters} value={filters.englishLevel}
                    >
                        <option value="" disabled>Select one...</option>
                        <option value="A1">A1</option>
                        <option value="A2">A2</option>
                        <option value="B1">B1</option>
                        <option value="B2">B2</option>
                        <option value="C1">C1</option>
                        <option value="C2">C2</option>
                    </select>
            </div>
            <div key='location' className="mb-4">
                    <label className="block text-gray-700 font-semibold mb-2">Location</label>
                    <select className="w-full p-2 border border-gray-300 rounded bg-white shadow-sm focus:outline-none focus:border-blue-400"
                    name='location'
                    onChange={onHandleChangeFilters} value={filters.location}
                    >
                        <option value="" disabled>Select one...</option>
                        <option value="LATAM">LATAM</option>
                        <option value="USA">USA</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Belize">Belize</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Brazil">Brazil</option>
                        <option value="Chile">Chile</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Dominican Republic">Dominican Republic</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Honduras">Honduras</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Panama">Panama</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Venezuela">Venezuela</option>
                    </select>
            </div>
            <button className='mt-6 px-4 py-2 bg-blue-600 text-white rounded-md' onClick={onHandleFilterJobs} disabled={!hasFilters}
          >Apply filters</button>
          <button className='mt-6 px-4 py-2 bg-blue-600 text-white rounded-md' onClick={onHandleResetFilters} >Reset filters</button>
        </aside>
    );
}

export default Filters;

