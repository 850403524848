import React from 'react';

const ReportHeader = ({ displayName }) => {
  return (
    <header className="mb-12 text-center">
      <h1 className="text-5xl font-bold text-gray-800">{displayName}</h1>
    </header>
  );
};

export default ReportHeader;
