import React from "react";
import Select from 'react-select';

const SelectRole = ({ errors, form, handleChange, options, placeholder, error }) => {
  return (
<div className="col-span-1">
  <label className="block text-gray-700">
    Role <span className="text-red-500">*</span>
  </label>
  {errors.role && (
    <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
      <span>{errors.role}</span>
    </div>
  )}
  <Select
    name="role"
    value={{ value: form.role, label: form.role }}
    onChange={(selectedOption) => handleChange({ target: { name: 'role', value: selectedOption.value } })}
    options={[
      { value: 'Front-end', label: 'Front-end' },
      { value: 'Back-end', label: 'Back-end' },
      { value: 'Full-Stack', label: 'Full-Stack' },
      { value: 'DevOps Engineer', label: 'DevOps Engineer' },
      { value: 'Data Scientist', label: 'Data Scientist' },
      { value: 'QA Engineer', label: 'QA Engineer' },
      { value: 'Project Manager', label: 'Project Manager' },
      { value: 'UI/UX Designer', label: 'UI/UX Designer' },
      { value: 'System Administrator', label: 'System Administrator' },
      { value: 'Product Manager', label: 'Product Manager' },
      { value: 'Scrum Master', label: 'Scrum Master' },
      { value: 'Business Analyst', label: 'Business Analyst' },
      { value: 'Database Administrator', label: 'Database Administrator' },
      { value: 'Security Specialist', label: 'Security Specialist' },
      { value: 'Network Engineer', label: 'Network Engineer' },
      { value: 'Technical Support', label: 'Technical Support' },
      { value: 'Cloud Architect', label: 'Cloud Architect' },
      { value: 'Machine Learning Engineer', label: 'Machine Learning Engineer' },
      { value: 'AI Specialist', label: 'AI Specialist' },
      { value: 'Mobile Developer', label: 'Mobile Developer' },
      { value: 'Game Developer', label: 'Game Developer' },
      { value: 'Blockchain Developer', label: 'Blockchain Developer' },
      { value: 'IT Consultant', label: 'IT Consultant' },
      { value: 'Software Architect', label: 'Software Architect' },
      { value: 'Embedded Systems Engineer', label: 'Embedded Systems Engineer' },
      { value: 'Site Reliability Engineer (SRE)', label: 'Site Reliability Engineer (SRE)' },
      { value: 'Chief Technology Officer (CTO)', label: 'Chief Technology Officer (CTO)' },
      { value: 'Chief Information Officer (CIO)', label: 'Chief Information Officer (CIO)' },
      { value: 'Penetration Tester', label: 'Penetration Tester' },
      { value: 'Digital Marketing Specialist', label: 'Digital Marketing Specialist' },
      { value: 'SEO Specialist', label: 'SEO Specialist' },
      { value: 'Cybersecurity Analyst', label: 'Cybersecurity Analyst' },
      { value: 'Data Engineer', label: 'Data Engineer' },
      { value: 'Data Analyst', label: 'Data Analyst' },
      { value: 'Technical Writer', label: 'Technical Writer' },
      { value: 'ERP Consultant', label: 'ERP Consultant' },
      { value: 'CRM Consultant', label: 'CRM Consultant' },
      { value: 'IT Auditor', label: 'IT Auditor' },
      { value: 'Business Intelligence (BI) Developer', label: 'Business Intelligence (BI) Developer' },
      { value: 'Robotics Engineer', label: 'Robotics Engineer' },
      { value: 'DevSecOps Engineer', label: 'DevSecOps Engineer' },
      { value: 'Cloud Engineer', label: 'Cloud Engineer' },
      { value: 'Big Data Engineer', label: 'Big Data Engineer' },
      { value: 'AI Researcher', label: 'AI Researcher' },
      { value: 'Computer Vision Engineer', label: 'Computer Vision Engineer' },
      { value: 'Augmented Reality Developer', label: 'Augmented Reality Developer' },
      { value: 'Virtual Reality Developer', label: 'Virtual Reality Developer' },
      { value: 'IoT Engineer', label: 'IoT Engineer' },
      { value: 'Quantum Computing Researcher', label: 'Quantum Computing Researcher' },
      { value: 'Bioinformatics Specialist', label: 'Bioinformatics Specialist' },
      { value: 'Ethical Hacker', label: 'Ethical Hacker' },
      { value: 'Information Security Analyst', label: 'Information Security Analyst' },
      { value: 'Incident Response Specialist', label: 'Incident Response Specialist' },
      { value: 'Cryptographer', label: 'Cryptographer' },
      { value: 'Systems Analyst', label: 'Systems Analyst' },
      { value: 'Release Manager', label: 'Release Manager' },
      { value: 'Game Designer', label: 'Game Designer' },
      { value: 'Technical Artist', label: 'Technical Artist' },
      { value: 'Sound Engineer', label: 'Sound Engineer' },
      { value: 'IT Operations Manager', label: 'IT Operations Manager' },
      { value: 'IT Support Specialist', label: 'IT Support Specialist' },
      { value: 'Technical Account Manager', label: 'Technical Account Manager' },
      { value: 'Customer Success Manager', label: 'Customer Success Manager' },
      { value: 'Data Privacy Officer', label: 'Data Privacy Officer' },
      { value: 'Chief Data Officer (CDO)', label: 'Chief Data Officer (CDO)' },
      { value: 'Chief Information Security Officer (CISO)', label: 'Chief Information Security Officer (CISO)' },
      { value: 'Chief Data Scientist', label: 'Chief Data Scientist' },
      { value: 'UX Researcher', label: 'UX Researcher' },
      { value: 'Visual Designer', label: 'Visual Designer' },
      { value: 'Technical Program Manager', label: 'Technical Program Manager' },
      { value: 'Innovation Manager', label: 'Innovation Manager' },
      { value: 'Data Governance Specialist', label: 'Data Governance Specialist' },
      { value: 'Telecommunications Engineer', label: 'Telecommunications Engineer' },
      { value: 'Mainframe Engineer', label: 'Mainframe Engineer' },
      { value: 'Hardware Engineer', label: 'Hardware Engineer' },
      { value: 'Firmware Developer', label: 'Firmware Developer' },
      { value: 'Compliance Officer', label: 'Compliance Officer' },
      { value: 'IT Trainer', label: 'IT Trainer' },
      { value: 'Application Support Analyst', label: 'Application Support Analyst' },
      { value: 'Systems Architect', label: 'Systems Architect' },
      { value: 'Algorithm Engineer', label: 'Algorithm Engineer' },
      { value: 'Performance Engineer', label: 'Performance Engineer' },
      { value: 'Security Operations Center (SOC) Analyst', label: 'Security Operations Center (SOC) Analyst' },
      { value: 'Storage Engineer', label: 'Storage Engineer' },
      { value: 'Linux Administrator', label: 'Linux Administrator' },
      { value: 'Windows Administrator', label: 'Windows Administrator' },
      { value: 'Network Security Engineer', label: 'Network Security Engineer' },
      { value: 'Middleware Engineer', label: 'Middleware Engineer' },
      { value: 'Business Systems Analyst', label: 'Business Systems Analyst' },
      { value: 'Embedded Software Engineer', label: 'Embedded Software Engineer' },
      { value: 'Release Engineer', label: 'Release Engineer' },
      { value: 'SaaS Operations Manager', label: 'SaaS Operations Manager' },
      { value: 'Platform Engineer', label: 'Platform Engineer' },

      // Add more roles as needed
    ]}
    className="block w-full"
  />
</div>
  );
};
export default SelectRole;