import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getReport } from '../../redux/actions/Report/getReport';
import { FaArrowRightLong } from "react-icons/fa6";

import ReportHeader from '../../components/EvReporting/ReportHeader';
import ReportSummary from '../../components/EvReporting/ReportSummary';
import ReportGraphics from '../../components/EvReporting/ReportGraphics';
import ReportScores from '../../components/EvReporting/ReportScores';
import CheatingDetection from '../../components/EvReporting/CheatingDetection';
import ChallengeSolutions from '../../components/EvReporting/ChallengeSolutions';
import ReportQuestions from '../../components/EvReporting/ReportQuestions';

const ReportPage = () => {
  const { candidateId, jobId } = useParams();
  const report = useSelector(state => state.report);
  const dispatch = useDispatch();

  useEffect(() => {
    if (candidateId || jobId) {
      dispatch(getReport(candidateId, jobId));
    }
  }, [candidateId, jobId]);

  if (!report) {
    return <>Loading</>;
  }

  const stripHtml = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  const questions = report.mc_details.map((mc) => ({
    question: stripHtml(mc.question),
    answered: mc.correct,
  }));

  const questionsOE = report.oe_details.map((oe) => ({
    question: stripHtml(oe.question),
    answer: stripHtml(oe.answer),
  }));

  const detailedScoresData = {
    labels: ['Challenges', 'Multiple Choice'],
    datasets: [
      {
        label: 'Scores',
        data: [report.code_score, report.mc_score],
        backgroundColor: ['#ff9800', '#3f51b5', '#9c27b0'],
      },
    ],
  };

  const finalScoreData = {
    labels: ['Final Score'],
    datasets: [
      {
        label: 'Score',
        data: [report.final_score],
        backgroundColor: ['#4caf50'],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
      },
    },
  };

  const challengeSolutions = report.challenge_details.map((challenge) => ({
    title: challenge.title,
    duration: challenge.time_taken,
    difficulty: challenge.meta.difficulty,
    tags: challenge.meta.tags.map(tag => ` ${tag}`).join(''),
    score: challenge.score,
    language: challenge.language,
  }));

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="max-w-6xl mx-auto bg-white shadow-lg rounded-lg p-6">
        <ReportHeader displayName={report.display_name} />
        <ReportSummary dateJoined={report.date_joined} name={report.name} email={report.email} />
        <div className='flex justify-center items-center m-5'>
          <Link target='_blank' to={`/candidate-detail/${candidateId}`}>
            <button className='bg-blue-600 p-2 rounded-lg text-white flex justify-center items-center hover:bg-blue-500'>
              Visit profile<FaArrowRightLong style={{ marginLeft: '10px' }} />
            </button>
          </Link>
        </div>
        <ReportGraphics finalScoreData={finalScoreData} detailedScoresData={detailedScoresData} options={options} />
        <ReportScores finalScore={report.final_score} codeScore={report.code_score} mcScore={report.mc_score} oeDetails={report.oe_details} />
        <CheatingDetection cheatingFlag={report.cheating_flag} cheatingDetails={report.cheating_details} />
        <ChallengeSolutions challengeSolutions={challengeSolutions} />
        <ReportQuestions questions={questions} questionsOE={questionsOE} />
      </div>
    </div>
  );
};

export default ReportPage;

