import { useEffect, useState } from 'react';
import React from "react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { FaArrowRightLong } from 'react-icons/fa6';

const ReportChallenge = () => {


  const {candidateId, jobId } = useParams()
  const [report, setReport] = useState(null);

  useEffect(() => {
    if (candidateId || jobId) {
      // dispatch(getReport(candidateId, jobId));
    //   axios.get(`applications/?jobId=${id}&userId=${user.id}`).then(response => {
    //     const fetchedCandidates = response.data;
    //     setCandidates(fetchedCandidates);
    //     fetchedCandidates.forEach(candidate => {
    //         setCandidateStatuses(prevState => ({
    //             ...prevState,
    //             [candidate.candidate.id]: candidate.application.status || null,
    //         }))
    //     })
    // })

    axios.get(`/completed-test/${candidateId}/${jobId}`).then(response => {
        const fetchedReport = response.data;
        setReport(fetchedReport);
    })

    }
  }, [candidateId, jobId]);

  if (!report) {
    return <>Loading</>;
  }

  const COLORS = ["#22C55E", "#EF4444"];
  const pieData = [
    { name: "Correct", value: report.technicalScore },
    { name: "Incorrect", value: 10 - report.technicalScore },
  ];

  return (
    <div className="p-8 bg-gray-100 min-h-screen flex flex-col items-center">
      <div className="bg-white p-8 rounded-xl shadow-lg w-full max-w-full">
        {/* User Information */}
        <div className="flex justify-between items-center mb-8">
          <div>
            <h2 className="text-2xl font-semibold text-gray-800">{report.name}</h2>
            <p className="text-md text-gray-500">{report.email}</p>
          </div>
          
          <div>
            <p className="text-lg font-medium text-gray-700">
              Score: <span className="text-primary">{report.technicalScore} / 10 </span>
            </p>
          </div>
        </div>
        <div>
          <Link target='_blank' to={`/candidate-detail/${candidateId}`}>
            <button className='bg-blue-600 p-2 rounded-lg text-white flex justify-center items-center hover:bg-blue-500'>
              Visit candidate profile<FaArrowRightLong style={{ marginLeft: '10px' }} />
            </button>
          </Link>
        </div>

        {/* Chart */}
        <div className="flex justify-center mb-10">
          <div className="w-48 h-48">
            <ResponsiveContainer>
              <PieChart>
                <Pie data={pieData} innerRadius={60} outerRadius={80} paddingAngle={5} dataKey="value">
                  {pieData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Technical Test */}
        <div className="mb-10">
          <h3 className="text-lg font-semibold text-gray-800 mb-4">Technical Test</h3>
          <div className="space-y-4">
            {report.technicalTest.map((answer, index) => (
              <div
                key={index}
                className={`flex items-center justify-between p-4 rounded-lg border ${
                  answer.correct ? "bg-green-50 border-green-200" : "bg-red-50 border-red-200"
                }`}
              >
                <div className="flex items-center">
                  {answer.correct ? (
                    <CheckCircleIcon className="h-6 w-6 text-green-500" />
                  ) : (
                    <XCircleIcon className="h-6 w-6 text-red-500" />
                  )}
                  <p className="ml-4 text-gray-700">{answer.question}</p>
                </div>
                <p className="text-gray-500 italic">Selected: {answer.answer}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Open-Ended Questions */}
        <div>
          <h3 className="text-lg font-semibold text-gray-800 mb-4">Psychometric Test</h3>
          <div className="space-y-6">
            {report.psychometricTest.questions.map((item, index) => (
              <div key={index} className="bg-gray-50 p-6 rounded-lg shadow-sm border border-gray-200">
                <p className="text-gray-600 font-semibold mb-2">Question:</p>
                <p className="text-gray-800 font-medium mb-3">{item.question}</p>
                <p className="text-gray-600 font-semibold mb-2">Answer:</p>
                <p className="text-gray-800">{item.answer}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportChallenge;
