


const Modal =({isOpen, onClose, children})=>{

    return (
        //backdrop
        <div onClick={onClose} className={`fixed inset-0 flex justify-center items-center transition-colors ${ isOpen ? "visible bg-black/20":"invisible"}`}>
        

        {/* Modal */}
        <div onClick={(e)=> e.stopPropagation()} className={`bg-white rounded-xl shadow p-6 transition-all ${isOpen ? "scale-100 opacity-100":"scale-125 opacity-0"}`}>
        <button onClick={onClose} className="p-1.5 w-8 absolute top-2 right-2 rounded-lg text-white bg-red-600 hover:bg-white hover:text-gray-600">
            X
        </button>
            {children}
        </div>
        </div>
    )
}

export default Modal