import React from 'react';
import Select from 'react-select';
import SelectRole from './SelectRole';
import SoftSkills from './SoftSkills';
import SelectTools from './SelectTools';
import SelectMainTech from './SelectMainTech';
import SecondaryTech from './SecondaryTech';
import Languages from './Languages';


const EditCandidateForm = ({form, options, errors, handleChange, limitExperienceDescription, handleSelectChange, handleCountryChange, handleFileChange, handleForm, stateOptions, isStateDisabled, handleStateChange} ) => {
    return (
<form className="mt-8 space-y-6" onSubmit={handleForm}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* First Name */}
            <div className="col-span-1">
            <label className="block text-gray-700">
                First Name <span className="text-red-500">*</span>
            </label>
            {errors.firstName && (
                <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                    <span>{errors.firstName}</span>
                </div>
            )}
            <input
                type="text"
                name="firstName"
                value={form.firstName || ""}
                onChange={handleChange}
                placeholder="First Name"
                className="block w-full p-2 pl-4 pr-12 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            {/* Last Name */}
            <div className="col-span-1">
              <label className="block text-gray-700">Last Name</label>
              <input
                type="text"
                name="lastName"
                value={form.lastName || ""}
                onChange={handleChange}
                placeholder="Last Name"
                className="block w-full p-2 pl-4 pr-12 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            {/* Country */}
            <div className="col-span-1">
              <label className="block text-gray-700">
                Country
              </label>
              {errors.country && (
                <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                  <span>{errors.country}</span>
                </div>
              )}
              <Select
                name="country"
                value={form.country ? options.find(option => option.label === form.country): null}
                onChange={handleCountryChange}
                options={options}
                placeholder="Select a country"
              />
            </div>
            {/* Location */}
            <div className="col-span-1">
              <label className="block text-gray-700">
                State <span className="text-red-500">*</span>
              </label>
              {errors.location && (
                <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                    <span>{errors.location}</span>
                </div>
              )}

              <Select 
                name="location"
                value={ form.location ? !isStateDisabled && stateOptions.find(option => option.label === form.location): null}
                
                onChange={handleStateChange}
                options={stateOptions}
                isDisabled={isStateDisabled}
              />
            </div>
            {/* Experience Description */}
            <div className="col-span-1">
              <label className="block text-gray-700">
                Experience Description <span className="text-red-500">*</span>
              </label>
              {errors.experienceDescription && (
                <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                  <span>{errors.experienceDescription}</span>
                </div>
              )}
              <textarea
                name="experienceDescription"
                value={form.experienceDescription}
                onChange={handleChange}
                placeholder="Enter your experience description (max 1200 characters)"
                onInput={limitExperienceDescription} // Utilizamos onInput en lugar de onChange para capturar el evento de entrada de texto
                className="block w-full p-2 pl-4 pr-12 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          {/* English Level */}
          <div className="col-span-1">
            <label className="block text-gray-700">
              English Level <span className="text-red-500">*</span>
            </label>
            {errors.englishLevel && (
              <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                <span>{errors.englishLevel}</span>
              </div>
            )}
            <Select
              name="englishLevel"
              value={form.englishLevel ? { value: form.englishLevel, label: form.englishLevel }: null}
              onChange={(selectedOption) => handleChange({ target: { name: 'englishLevel', value: selectedOption.value } })}
              options={[
                { value: 'A1', label: 'A1' },
                { value: 'A2', label: 'A2' },
                { value: 'B1', label: 'B1' },
                { value: 'B2', label: 'B2' },
                { value: 'C1', label: 'C1' },
                { value: 'C2', label: 'C2' },
              ]}
              className="block w-full"
            />
          </div>

            {/* Role */}
        <SelectRole
        options= {options}
        form= {form}
        errors= {errors}
        handleChange= {handleChange}/>


          {/* Technical Level */}
          <div className="col-span-1">
            <label className="block text-gray-700">
              Technical Level <span className="text-red-500">*</span>
            </label>
            {errors.technicalLevel && (
              <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                <span>{errors.technicalLevel}</span>
              </div>
            )}
            <Select
              name="technicalLevel"
              value={form.technicalLevel ? { value: form.technicalLevel, label: form.technicalLevel }:null}
              onChange={(selectedOption) => handleChange({ target: { name: 'technicalLevel', value: selectedOption.value } })}
              options={[
                { value: 'Trainee', label: 'Trainee' },
                { value: 'Junior', label: 'Junior' },
                { value: 'Mid', label: 'Mid' },
                { value: 'Senior', label: 'Senior' },
                { value: 'Architect', label: 'Architect' },
              ]}
              className="block w-full"
            />
          </div>

          {/* Years of Experience */}
          <div className="col-span-1">
            <label className="block text-gray-700">
              Years of Experience <span className="text-red-500">*</span>
            </label>
            {errors.yearsOfExperience && (
              <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                <span>{errors.yearsOfExperience}</span>
              </div>
            )}
            <input
              type="text"
              name="yearsOfExperience"
              value={form.yearsOfExperience}
              onChange={handleChange}
              placeholder="Years of Experience"
              className="block w-full p-2 pl-4 pr-12 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>

            {/* SoftSkills */}
            <SoftSkills
            options= {options}
            form= {form}
            errors= {errors}
            handleSelectChange= {handleSelectChange}/>

          {/* Tools */}
        <SelectTools
        options= {options}
        form= {form}
        errors= {errors}
        handleSelectChange= {handleSelectChange}
        />

            {/* MainTech */}
            <SelectMainTech
            options= {options}
            form= {form}
            errors= {errors}
            handleSelectChange= {handleSelectChange}/>

            {/* SecondaryTech */}
            <SecondaryTech
            options= {options}
            form= {form}
            errors= {errors}
            handleSelectChange= {handleSelectChange}/>

            {/* Educational Level */}
            <div className="col-span-1">
              <label className="block text-gray-700">
                Educational Level
              </label>
              {errors.educationalLevel && (
                <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                  <span>{errors.educationalLevel}</span>
                </div>
              )}
              <Select
                name="educationalLevel"
                value={form.educationalLevel ? { value: form.educationalLevel, label: form.educationalLevel }:null}
                onChange={(selectedOption) => handleChange({ target: { name: 'educationalLevel', value: selectedOption.value } })}
                options={[
                  { value: 'High School', label: 'High School' },
                  { value: 'Associate Degree', label: 'Associate Degree' },
                  { value: 'Bachelor\'s Degree', label: 'Bachelor\'s Degree' },
                  { value: 'Master\'s Degree', label: 'Master\'s Degree' },
                  { value: 'Doctorate', label: 'Doctorate' },
                ]}
                className="block w-full"
              />
            </div>
            {/* Certifications */}
            <div className="col-span-1">
              <label className="block text-gray-700">
                Certifications 
              </label>
              {errors.certifications && (
                <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                  <span>{errors.certifications}</span>
                </div>
              )}
              <input
                type="text"
                name="certifications"
                value={form.certifications}
                onChange={handleChange}
                placeholder="Certifications"
                className="block w-full p-2 pl-4 pr-12 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            {/* Languages */}
            <Languages
            options= {options}
            form= {form}
            errors= {errors}
            handleSelectChange= {handleSelectChange}/>

            {/* Contract Type */}
            <div className="col-span-1">
              <label className="block text-gray-700">
                Contract Type 
              </label>
              {errors.contractType && (
                <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                  <span>{errors.contractType}</span>
                </div>
              )}
              <Select
                name="contractType"
                value={{ value: form.contractType, label: form.contractType }}
                onChange={(selectedOption) => handleChange({ target: { name: 'contractType', value: selectedOption.value } })}
                options={[
                  { value: 'Full-Time', label: 'Full-Time' },
                  { value: 'Part-Time', label: 'Part-Time' },
                  { value: 'Contract', label: 'Contract' },
                  { value: 'Temporary', label: 'Temporary' },
                  { value: 'Internship', label: 'Internship' },
                ]}
                className="block w-full"
              />
            </div>
            {/* Work Scheme */}
            <div className="col-span-1">
              <label className="block text-gray-700">
                Work Scheme 
              </label>
              {errors.workScheme && (
                <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                  <span>{errors.workScheme}</span>
                </div>
              )}
              <Select
                name="workScheme"
                value={{ value: form.workScheme, label: form.workScheme }}
                onChange={(selectedOption) => handleChange({ target: { name: 'workScheme', value: selectedOption.value } })}
                options={[
                  { value: 'On-Site', label: 'On-Site' },
                  { value: 'Remote', label: 'Remote' },
                  { value: 'Hybrid', label: 'Hybrid' },
                ]}
                className="block w-full"
              />
            </div>

            {/* Contract Scheme */}
            <div className="col-span-1">
              <label className="block text-gray-700">
                Contract Scheme 
              </label>
              {errors.contractScheme && (
                <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                  <span>{errors.contractScheme}</span>
                </div>
              )}
              <Select
                name="contractScheme"
                value={{ value: form.contractScheme, label: form.contractScheme }}
                onChange={(selectedOption) => handleChange({ target: { name: 'contractScheme', value: selectedOption.value } })}
                options={[
                  { value: 'Permanent', label: 'Permanent' },
                  { value: 'Fixed-Term', label: 'Fixed-Term' },
                  { value: 'Freelance', label: 'Freelance' },
                ]}
                className="block w-full"
              />
            </div>

            

            {/* Salary */}
            <div className="col-span-1">
              <label className="block text-gray-700">Salary Expectations (USD/Month) <span className="text-red-500">*</span></label>
              {errors.salaryExpectations && (
                <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                    <span>{errors.salaryExpectations}</span>
                </div>
              )}
              <input
                type="number"
                name="salaryExpectations"
                value={form.salaryExpectations}
                onChange={handleChange}
                min={0}
                placeholder="Enter the amount in dollars and monthly"
                className="block w-full p-2 pl-4 pr-12 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="mt-6">
            <button
              type="submit"
              className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Complete
            </button>
          </div>
        </form>
    );
}

export default EditCandidateForm;