import axios from 'axios'
import ACTION_TYPES from '../../actionTypes'


const getCandidateByFiltersSuccess = (candidates) => {
    return {
        type: ACTION_TYPES.GET_CANDIDATE_BY_FILTERS_SUCCESS,
        payload: candidates
    }
}

const getCandidateByFiltersFailure = (error) => {
    return {
        type: ACTION_TYPES.GET_CANDIDATE_BY_FILTERS_FAILURE,
        payload: error
    }
}

export const getCandidateByFilters = (filters) => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/filter-candidates/?seniority=${filters.seniority}&position=${filters.position}&location=${filters.location}${filters.mainTechnology.map((tech)=>`&mainTechnology=${tech}`)}`)
            const candidates = response.data
            dispatch(getCandidateByFiltersSuccess(candidates))
        } catch (error) {
            dispatch(getCandidateByFiltersFailure(error.message))
        }
    }
}


