import React from 'react';

const ReportQuestions = ({ questions, questionsOE }) => {
  return (
    <>
      <section className="mb-12">
        <h2 className="text-3xl font-semibold text-gray-700 mb-4 text-center">Questions</h2>
        <div className="p-6 bg-gray-50 rounded-lg shadow-inner">
          <ul className="list-none space-y-4">
            {questions.map((q, index) => (
              <li key={index} className="flex justify-between items-center text-gray-800 text-xl">
                <span>{q.question}</span>
                <span>{q.answered ? '✔️' : '❌'}</span>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-semibold text-gray-700 mb-4 text-center">Questions Open</h2>
        <div className="p-6 bg-gray-50 rounded-lg shadow-inner">
          <ul className="list-none space-y-4">
            {questionsOE?.map((oe, index) => (
              <li key={index} className="flex flex-col text-gray-800 text-xl">
                <h4 className="text-1xl font-semibold text-gray-700 mb-4">{oe.question}</h4>
                <p className="text-gray-500 mb-1">{oe.answer}</p>
              </li>
            ))}
            {questionsOE?.length <= 0 && (
              <li className="text-gray-800 text-xl">Nothing</li>
            )}
          </ul>
        </div>
      </section>
    </>
  );
};

export default ReportQuestions;
