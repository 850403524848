import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';

const SearchBar = ({searchCandidates = null, searchJobs = null}) => {
    const [search, setSearch] = useState('')


    const onHandleChange = (event) =>{
        setSearch(event.target.value)
    }

    const onHandleSearch = (event) => {
        if (event) {
            event.preventDefault();
        }
        if (searchCandidates) {
            searchCandidates(search);
        } else if (searchJobs) {
            searchJobs(search);
        } else {
            console.error('No search function provided');
        }
    }

    const onHandleKeyUp = (event) => {
        if (event.key === 'Enter') {
            onHandleSearch()
          }
    }

    return (
        <div className="max-w-4xl mx-auto">
                 <div className="flex justify-center mb-6">
                     <div className="relative w-full max-w-3xl">
                         <input 
                             type="text" 
                             placeholder="Type here to search" 
                             className="w-full p-2 rounded-full border border-gray-300 hover:outline-none hover:ring-2 hover:ring-blue-500"
                             onChange={onHandleChange}
                             onKeyUp={onHandleKeyUp}
                             value={search}
                         />
                         <FaSearch onClick={onHandleSearch  } className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 cursor-pointer"/>
                     </div>
                 </div>
          </div>
    );
}

export default SearchBar;
