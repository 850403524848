import React from 'react';
import Filters from './Filters'
import Cards from './Cards';

const HireTalentContent = ({ candidates, filterCandidates, resetCandidatesFilter }) => {
  return (
    <div className="flex flex-col md:flex-row mt-8 md:mt-12 w-full">        
      <Filters filterCandidates={filterCandidates} resetCandidatesFilter={resetCandidatesFilter} />
      <Cards candidates={candidates} /> 
    </div>
  );
};

export default HireTalentContent;