import React from 'react';

const ReportSummary = ({ dateJoined, name, email }) => {
  return (
    <section className="mb-12">
      <div className="grid grid-cols-3 gap-8 text-center">
        <div>
          <h2 className="text-xl font-semibold text-gray-700">Date</h2>
          <p className="text-gray-600">{dateJoined.split('T')[0]}</p>
        </div>
        <div>
          <h2 className="text-xl font-semibold text-gray-700">Name</h2>
          <p className="text-gray-600">{name}</p>
        </div>
        <div>
          <h2 className="text-xl font-semibold text-gray-700">Email</h2>
          <p className="text-gray-600">{email}</p>
        </div>
      </div>
    </section>
  );
};

export default ReportSummary;
