import ACTION_TYPES from "../../actionTypes";
import axios from "axios";

export const authenticatedSuccess = (userData) => {
    return{
        type: ACTION_TYPES.AUTHENTICATED_SUCCESS,
        payload: userData
    }
}

export const authenticatedFailure = (error) => {
    return {
        type: ACTION_TYPES.AUTHENTICATED_FAILURE,
        payload: error
    }
}

export const authenticated = (credentials)=>{
    const {email, password} = credentials
    return async (dispatch) => {
        try {
            const response = await axios.post('/login', {email, password})
            const userData = response.data
            dispatch(authenticatedSuccess(userData));
        } catch (error) {
            dispatch(authenticatedFailure(error.message));
        }
    }
}
