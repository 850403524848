import axios from 'axios'
import ACTION_TYPES from '../../actionTypes'


const getCompaniesSuccess = (companies) => {
    return {
        type: ACTION_TYPES.GET_COMPANIES_SUCCESS,
        payload: companies
    }
}

const getCompaniesFailure = (error) => {
    return {
        type: ACTION_TYPES.GET_COMPANIES_FAILURE,
        payload: error
    }
}

export const getCompanies = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/companies`)
            const companies = response.data
            dispatch(getCompaniesSuccess(companies))
        } catch (error) {
            dispatch(getCompaniesFailure(error.message))
        }
    }
}

