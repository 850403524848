import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const ChallengeSent = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.6, ease: 'easeOut' }}
        className="bg-white rounded-lg shadow-lg p-8 max-w-md text-center"
      >
        <motion.div
          initial={{ opacity: 0, rotate: -180 }}
          animate={{ opacity: 1, rotate: 0 }}
          transition={{ duration: 0.8, ease: 'easeOut' }}
        >
          <CheckCircleIcon className="h-24 w-24 text-green-500 mx-auto" />
        </motion.div>
        <h1 className="text-2xl font-bold text-gray-800 mt-6">
          Your challenge has been successfully submitted!
        </h1>
        <p className="text-gray-600 mt-4">
          Thank you for completing the process. Our team will review your answers and contact you soon.
        </p>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          className="mt-6"
        >
          <button className="bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 transition duration-300">
            <Link to='/my-application'>
              Back to Home
            </Link>
          </button>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default ChallengeSent;
