import axios from 'axios'
import ACTION_TYPES from '../../actionTypes'


const getJobsSuccess = (jobs) => {
    return {
        type: ACTION_TYPES.GET_JOBS_SUCCESS,
        payload: jobs
    }
}

const getJobsFailure = (error) => {
    return {
        type: ACTION_TYPES.GET_JOBS_FAILURE,
        payload: error
    }
}

export const getJobs = (name='') => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/jobs/?name=${name}`)
            const jobs = response.data
            dispatch(getJobsSuccess(jobs))
        } catch (error) {
            dispatch(getJobsFailure(error.message))
        }
    }
}

