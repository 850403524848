import axios from 'axios'
import ACTION_TYPES from '../../actionTypes'


const getJobsByFiltersSuccess = (jobs) => {
    return {
        type: ACTION_TYPES.GET_JOBS_BY_FILTERS_SUCCESS,
        payload: jobs
    }
}

const getJobsByFiltersFailure = (error) => {
    return {
        type: ACTION_TYPES.GET_JOBS_BY_FILTERS_FAILURE,
        payload: error
    }
}

export const getJobsByFilters = (filters) => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/filter-jobs/?seniority=${filters.seniority}&workingScheme=${filters.workingScheme}&location=${filters.location}&englishLevel=${filters.englishLevel}${filters.mustHaveTechnologies.map((tech)=>`&mustHaveTechnologies=${tech}`)}`)
            const jobs = response.data
            dispatch(getJobsByFiltersSuccess(jobs))
        } catch (error) {
            dispatch(getJobsByFiltersFailure(error.message))
        }
    }
}


