import React from 'react';

const JobDetailsGrid = ({ job }) => {
  return (
    <div className="border-t border-gray-200 mt-6 pt-6">
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 text-lg">
        <div>
          <p className="font-semibold">Location</p>
          <p>{job.locationAvailability}</p>
        </div>
        <div>
          <p className="font-semibold">Work Scheme</p>
          <p>{job.workingScheme}</p>
        </div>
        <div>
          <p className="font-semibold">Seniority</p>
          <p>{job.seniority}</p>
        </div>
        <div>
          <p className="font-semibold">Years of Experience</p>
          <p>{job.yearsOfExperience}</p>
        </div>
        <div>
          <p className="font-semibold">Job Type</p>
          <p>{job.jobType}</p>
        </div>
        <div>
          <p className="font-semibold">English Level</p>
          <p>{job.englishLevel}</p>
        </div>
        <div>
          <p className="font-semibold">Contract Term</p>
          <p>{job.contractTerm}</p>
        </div>
        <div>
          <p className="font-semibold">Must-Haves</p>
          <p>{job.mustHaveTechnologies.join(', ')}</p>
        </div>
      </div>
    </div>
  );
};

export default JobDetailsGrid;