import React from 'react';

const ChallengeSolutions = ({ challengeSolutions }) => {
  return (
    <section className="mb-12">
      <h2 className="text-3xl font-semibold text-gray-700 mb-4 text-center">Challenge Solutions</h2>
      <div className="space-y-4">
        {challengeSolutions.map((solution, index) => (
          <div key={index} className="p-6 bg-gray-50 rounded-lg shadow-inner">
            <div className="flex justify-between items-center">
              <div>
                <h3 className="text-xl font-medium text-gray-800">{solution.title}</h3>
                <p className="text-gray-600">{solution.duration} | {solution.difficulty}</p>
                <p className="text-gray-600">{solution.tags}</p>
              </div>
              <div className="text-right">
                <p className="text-gray-800 text-2xl">{solution.score}</p>
                <div className="flex items-center justify-end space-x-2">
                  <span className="text-sm text-gray-600">{solution.language}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ChallengeSolutions;
