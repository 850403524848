import React from 'react';
import Select from 'react-select';
import Industry from './Industry';
import CoreValue from './CoreValue';
import PerksBenefits from './PerksBenefits';

const CompleteCompanyForm = ({handleForm, errors, form, options, handleCountryChange, handleChangeSelects, handleFileChange, onHandleClickTags, handleChange, coreValueSelect, perksSelect}) => {
    return (

    <form className="mt-8 space-y-6" onSubmit={handleForm}>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
    {/* Company Name */}
    <div className="col-span-1">
        <label className="block text-gray-700">
        Company Name <span className="text-red-500">*</span>
        </label>
        {errors.companyName && (
        <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
            <span>{errors.companyName}</span>
        </div>
        
        )}
        <input
        type="text"
        name="companyName"
        value={form.companyName}
        onChange={handleChange}
        placeholder="Company Name"
        className="block w-full p-2 pl-4 pr-12 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
    </div>
    {/* Company Logo */}
    <div className="col-span-1">
        <label className="block text-gray-700">Logo <span className="text-red-500">*</span></label> 
        <input type="file" onChange={handleFileChange} className="block w-full p-2 pl-4 pr-12 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
    </div>
    {/* Country */}
    <div className="col-span-1">
        <label className="block text-gray-700">
        Country<span className="text-red-500">*</span>
        </label>
        {errors.country && (
        <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
            <span>{errors.country}</span>
        </div>
        
        )}
        <Select
            name="country"
            value={options.find(option => option.label === form.country)}
            onChange={handleCountryChange}
            options={options}
            placeholder="Select a country"
            />
    </div>
    {/* Location */}
    <div className="col-span-1">
        <label className="block text-gray-700">
        Location <span className="text-red-500">*</span>
        </label>
        {errors.location && (
        <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
            <span>{errors.location}</span>
        </div>
        
        )}
        <input
        type="text"
        name="location"
        value={form.location}
        onChange={handleChange}
        placeholder="City, state"
        className="block w-full p-2 pl-4 pr-12 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
    </div>
    {/* Industry */}

    <Industry
    form={form}
    errors={errors}
    handleChange={handleChange}
    />

    {/* Stage */}
    <div className="col-span-1">
        <label className="block text-gray-700">Stage <span className="text-red-500">*</span></label>
        {errors.stage && (
        <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
            <span>{errors.stage}</span>
        </div>
        
        )}
        <select name="stage" id="" className="block w-full p-2 pl-4 pr-12 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white" value={form.stage} onChange={handleChange}>
            <option value="" disabled>Select one...</option>
            <option value="Seed/Development">Seed/Development</option>
            <option value="Start-up">Start-up</option>
            <option value="Growth/Establishment">Growth/Establishment</option>
            <option value="Expansion">Expansion</option>
            <option value="Maturity">Maturity</option>
        </select>
    </div>
    {/* Company Description */}
    <div className="col-span-1 md:col-span-2">
        <label className="block text-gray-700">Company Description</label>
        <textarea
        name="companyDescription"
        value={form.companyDescription}
        onChange={handleChange}
        placeholder="Describe the company..."
        className="block w-full p-2 pl-4 pr-12 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm h-32"
        ></textarea>
    </div>
    {/* About Your Work Culture */}
    <div className="col-span-1 md:col-span-2">
        <label className="block text-gray-700">About Your Work Culture</label>
        <textarea
        name="aboutYourWorkCulture"
        value={form.aboutYourWorkCulture}
        onChange={handleChange}
        placeholder="Describe your work culture..."
        className="block w-full p-2 pl-4 pr-12 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm h-32"
        ></textarea>
    </div>

    {/* CoreValue */}
    <CoreValue
    form={form}
    errors={errors}
    handleChange={handleChange}
    coreValueSelect={coreValueSelect}
    handleChangeSelects={handleChangeSelects}
    onHandleClickTags={onHandleClickTags}
    />

    {/* Perks and Benefits */}
        <PerksBenefits
        form={form}
        onHandleClickTags={onHandleClickTags}
        perksSelect={perksSelect}
        handleChangeSelects={handleChangeSelects}

        />
    {/* Company Link */}
    <div className="col-span-1 md:col-span-2">
        <label className="block text-gray-700">
        Company Link 
        </label>
        {errors.companyLink && (
        <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
            <span>{errors.companyLink}</span>
        </div>
        
        )}
        <input
        type="text"
        name="companyLink"
        value={form.companyLink}
        onChange={handleChange}
        placeholder="www.company.com"
        className="block w-full p-2 pl-4 pr-12 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
    </div>
    </div>
    {/* Submit Button */}
    <div className="text-center">
    <button
        type="submit"
        className="bg-blue-600 text-white py-2 px-4 rounded-md shadow-sm w-full hover:bg-blue-700"
    >
        Save Profile
    </button>
    </div>
    </form>

    );
}

export default CompleteCompanyForm;
