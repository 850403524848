import React from 'react';
import { motion } from 'framer-motion';

const ChallengeIntro = ({ onAccept }) => {
  return (
    <motion.div 
      className="flex flex-col items-center justify-center w-full bg-white p-4"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div 
        className="bg-white rounded-lg shadow-lg p-8 w-full max-w-screen-md mx-auto text-center overflow-y-auto"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.6, delay: 0.3 }}
      >
        <motion.h2 
          className="text-3xl sm:text-4xl md:text-5xl font-extrabold mb-6 text-gray-800"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.5 }}
        >
          Get Ready for the Technical Challenge
        </motion.h2>
        <motion.p 
          className="text-gray-700 text-lg sm:text-xl mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 0.7 }}
        >
          You have <span className="font-bold text-blue-600">20 minutes</span> to complete this technical assessment.
          Please review the following guidelines carefully before starting.
        </motion.p>
        <motion.div 
          className="text-left text-gray-700 text-sm sm:text-base mb-6 space-y-3"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.9 }}
        >
          <p className="font-semibold text-md">Challenge Rules:</p>
          <ul className="list-disc list-inside">
            <li>Ensure your response is clean, efficient, and well-detailed.</li>
            <li>No external libraries or resources are allowed.</li>
            <li>Do not attempt to copy answers from other sources or consult external websites.</li>
            <li>Switching to other tabs or leaving this page during the challenge is prohibited.</li>
            <li>Your screen activity will be monitored to ensure compliance and fairness.</li>
            <li>All solutions must be implemented within the assigned time.</li>
            <li>Focus first on solving the problem correctly; optimizations can come later.</li>
            <li>If you encounter any technical issues, document them in the code comments.</li>
            <li>Cheating, including sharing or copying code, will result in disqualification.</li>
            <li>Stay calm, focused, and give it your best. Good luck!</li>
          </ul>
        </motion.div>
        <motion.p 
          className="text-gray-700 text-base sm:text-lg mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 1.1 }}
        >
          Once you're ready, click the button below to start the challenge.
          Remember, stay on this page and follow the rules!
        </motion.p>
        <motion.button
          onClick={onAccept}
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg transition duration-200 text-lg sm:text-xl"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 1.3 }}
        >
          Accept & Start
        </motion.button>
      </motion.div>
    </motion.div>
  );
};

export default ChallengeIntro;
