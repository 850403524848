import React from 'react';
import ErrorAlert from './ErrorAlert';


const FormGroup = ({ id, label, type, value, onChange, placeholder, error }) => (
    <div className='mt-3'>
        <label htmlFor={id} className="sr-only">{label}</label>
        {error && (
            <ErrorAlert message={error} />
        )}
        <input
            id={id}
            name={id}
            type={type}
            value={value}
            onChange={onChange}
            required
            className="relative block w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
            placeholder={placeholder}
        />
    </div>
);

export default FormGroup;
