import React from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const ReportGraphics = ({ finalScoreData, detailedScoresData, options }) => {
  return (
    <section className="mb-12">
      <h2 className="text-3xl font-semibold text-gray-700 mb-4 text-center">Graphics</h2>
      <div className="grid grid-cols-2 gap-8">
        <div className="p-6 bg-gray-50 rounded-lg shadow-inner text-center">
          <h3 className="text-xl font-medium text-gray-600 mb-4">Final Score</h3>
          <div className="h-48">
            <Bar data={finalScoreData} options={options} />
          </div>
        </div>
        <div className="p-6 bg-gray-50 rounded-lg shadow-inner text-center">
          <h3 className="text-xl font-medium text-gray-600 mb-4">Detailed Scores</h3>
          <div className="h-48">
            <Doughnut data={detailedScoresData} options={{ responsive: true, maintainAspectRatio: false }} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReportGraphics;
