import axios from 'axios'
import ACTION_TYPES from '../../actionTypes'


const getCandidatesSuccess = (candidates) => {
    return {
        type: ACTION_TYPES.GET_CANDIDATES_SUCCESS,
        payload: candidates
    }
}

const getCandidatesFailure = (error) => {
    return {
        type: ACTION_TYPES.GET_CANDIDATES_FAILURE,
        payload: error
    }
}

export const getCandidates = (name='') => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/candidates/?name=${name}`)
            const candidates = response.data
            dispatch(getCandidatesSuccess(candidates))
        } catch (error) {
            dispatch(getCandidatesFailure(error.message))
        }
    }
}

