import { useEffect } from 'react';
import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import CandidateRecommendationCard from '../../components/CandidateRecommendationCard/CandidateRecommendationCard';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';




const CandidateRecommendationList = () => {
  const { id } = useParams()
  const user = useSelector(state => state.user)
  const [candidates, setCandidates] = useState([])
  

  useEffect(() => {
    if (id && user) {
        axios.get(`/candidate-recommendation/?jobId=${id}&userId=${user.id}`)
        .then(response => {
            const datos = response.data
            setCandidates(datos)
        }).catch(error => {
            toast.error("Something is wrong")
        })
    }

  }, [user, id])

  return (
    <div className="flex flex-col items-center gap-5 w-full p-5 mb-auto">
      <h1 className='text-3xl md:text-4xl lg:text-5xl font-bold'>Recommended Candidates</h1>
      {candidates.length === 0 ? (<h1 className='text-xl md:text-2xl lg:text-3xl font-bold mt-10'>No candidates found...</h1>):
      candidates.map(candidate => (
        <CandidateRecommendationCard key={candidate.id} candidate={candidate} />
      ))
      }
      <ToastContainer />
    </div>
  );
};

export default CandidateRecommendationList;