import React from 'react';

const Header = () => {
    return (
        <header className="text-center my-8">
            <h1 className="text-3xl md:text-4xl font-bold text-blue-600">Explore Top Tech Opportunities</h1>
            <p className="text-gray-500 mt-2 text-sm md:text-base">
                Where Innovation Meets Talent: Connect with Leading Tech Roles and Professionals
            </p>
        </header>
    );
};

export default Header;