import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOpenJobsForRecruiter } from '../../redux/actions/Recruiter/getOpenJobsForRecruiter';
import { getPendingJobsForRecruiter } from '../../redux/actions/Recruiter/getPendingJobsForRecruiter';
import { getAssessments } from '../../redux/actions/Recruiter/getAssessments';
import PendingJobsTable from '../../components/PendingJobsTable/PendingJobsTable'
import PublishedJobsTable from '../../components/PublishedJobTable/PublishedJobTable'

const AdminJobList = () => {
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const openJobs = useSelector((state) => state.openJobs);
  const pendingJobs = useSelector((state) => state.pendingJobs);
  const assessments = useSelector((state) => state.assessments);
  const dispatch = useDispatch();

  const [selectedAssessments, setSelectedAssessments] = useState({});

  useEffect(() => {
    if (user) {
      dispatch(getOpenJobsForRecruiter({ userId: user.id }));
      dispatch(getPendingJobsForRecruiter({ userId: user.id }));
      dispatch(getAssessments());
    }
  }, []);

  if (!openJobs && !pendingJobs) {
    return <p>Loading candidate details...</p>;
  }
  

  const assessmentOptions = assessments.map((assessment) => ({
    value: assessment.test_id,
    label: assessment.display_name,
  }));

  const handleSelectChange = (jobId, selectedOption) => {
    setSelectedAssessments((prevState) => ({
      ...prevState,
      [jobId]: selectedOption ? selectedOption.value : null,
    }));
  };


  const handleOpenJob = async (id, idC) => {
    const selectedAssessment = selectedAssessments[id];
    if (selectedAssessment) {
      try {
        const form = {
          id: id,
          test_id: selectedAssessment,
        };
        const response = await axios.put('/update-job-with-assessment', form);

        if (response.status === 200) {
          alert('Successful!');
          dispatch(getOpenJobsForRecruiter({ userId: user.id }));
          dispatch(getPendingJobsForRecruiter({ userId: user.id  }));
        }
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.error);
          alert(error.response.data.error);
        } else {
          alert('An error occurred in the request. Please try again.');
        }
      }
    }
  };

  const handleRefreshButton = async () => {
    try {
      
      const response = await axios.post('/add-assessments')

        if (response.status === 200) {
          alert('Successful refresh!');
          dispatch(getOpenJobsForRecruiter({ userId: user.id }));
          dispatch(getPendingJobsForRecruiter({ userId: user.id  }));
          dispatch(getAssessments());
        }
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.error);
          alert(error.response.data.error);
        } else {
          alert('An error occurred in the request. Please try again.');
        }
      }
  }

  // Filtrar trabajos en estado "Pending"
  const filteredPendingJobs = pendingJobs.filter((job) => job.positionStatus === 'Pending');

  return (
    <div className="container mx-auto mt-10 mb-auto">
      <div className="mb-10">
        <h2 className="text-2xl font-bold mb-4">Pending</h2>
        <button className="bg-blue-600 px-4 py-1 rounded-md text-white mb-3" onClick={handleRefreshButton} >Refresh tests</button>

        <PendingJobsTable 
        pendingJobs={filteredPendingJobs}
        handleOpenJob={handleOpenJob}
        assessmentOptions={assessmentOptions}
        selectedAssessments={selectedAssessments}
        handleSelectChange={handleSelectChange}
        />
        
      </div>

      <div>
        <PublishedJobsTable openJobs={openJobs} />
      </div>
    </div>
  );
};

export default AdminJobList;
