import React from 'react';

const ProfileHeader = ({ candidate }) => (
  <div className="text-center mb-4">
    <h2 className="text-xl font-semibold text-gray-900">
      {candidate.firstName} {candidate.lastName}
    </h2>
    <p className="text-blue-500">
      {candidate.location}, {candidate.country}
    </p>
    <p className="text-gray-700">{candidate.experienceDescription}</p>
  </div>
);

export default ProfileHeader;
