import axios from 'axios'
import ACTION_TYPES from '../../actionTypes'


const getAllJobsForCompanySuccess = (jobs) => {
    return {
        type: ACTION_TYPES.GET_ALL_JOBS_FOR_COMPANY_SUCCESS,
        payload: jobs
    }
}

const getAllJobsForCompanyFailure = (error) => {
    return {
        type: ACTION_TYPES.GET_ALL_JOBS_FOR_COMPANY_FAILURE,
        payload: error
    }
}

export const getAllJobsForCompany = ({name='', userId}) => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/jobs-for-company?userId=${userId}&name=${name}`)
            const jobs = response.data
            dispatch(getAllJobsForCompanySuccess(jobs))
        } catch (error) {
            dispatch(getAllJobsForCompanyFailure(error.message))
        }
    }
}

