import React from 'react';
import Select from 'react-select';

const Industry = ({errors, form, handleChange }) => {
    return (
        <div className="col-span-1">
        <label className="block text-gray-700">Industry <span className="text-red-500">*</span></label>
        {errors.industry && (
        <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
            <span>{errors.industry}</span>
        </div>
        
        )}
        <select name="industry" className="block w-full p-2 pl-4 pr-12 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white" value={form.industry} onChange={handleChange}>
        <option value="" disabled>Select one...</option>
        <option value="Agriculture / Enviroment">Agriculture / Enviroment</option>
        <option value="Agro-tech">Agro-tech</option>
        <option value="Artificial Intelligence (AI)">Artificial Intelligence (AI)</option>
        <option value="Automation">Automation</option>
        <option value="Automobile">Automobile</option>
        <option value="Base metal production">Base metal production</option>
        <option value="Big Data / Data Engineering">Big Data / Data Engineering</option>
        <option value="Blockchain / Crypto">Blockchain / Crypto</option>
        <option value="Chemical Industry">Chemical Industry</option>
        <option value="Cloud Engineering">Cloud Engineering</option>
        <option value="Construction">Construction</option>
        <option value="E-commerce">E-commerce</option>
        <option value="Ed-tech">Ed-tech</option>
        <option value="Education">Education</option>
        <option value="Entertainment">Entertainment</option>
        <option value="Fin-tech">Fin-tech</option>
        <option value="Finance">Finance</option>
        <option value="Food">Food</option>
        <option value="Health services">Health services</option>
        <option value="Health-tech">Health-tech</option>
        <option value="Hotel, catering, tourism">Hotel, catering, tourism</option>
        <option value="Internet of Things (IoT)">Internet of Things (IoT)</option>
        <option value="IT Consultancy">IT Consultancy</option>
        <option value="Manufacturing">Manufacturing</option>
        <option value="Marketing">Marketing</option>
        <option value="Mechanical and electrical engineering">Mechanical and electrical engineering</option>
        <option value="Media and communications">Media and communications</option>
        <option value="Mining">Mining</option>
        <option value="Oil and gas production">Oil and gas production</option>
        <option value="Postal services">Postal services</option>
        <option value="Retail">Retail</option>
        <option value="Software as a Service (SaaS)">Software as a Service (SaaS)</option>
        <option value="Software Factory">Software Factory</option>
        <option value="Staffing">Staffing</option>
        <option value="Telecommunications">Telecommunications</option>
        <option value="Textiles: apparel, leather, footwear">Textiles: apparel, leather, footwear</option>
        <option value="Transportation">Transportation</option>
        <option value="Utilities / Government">Utilities / Government</option>
        <option value="Video-games">Video-games</option>
        <option value="Virtual Reality (VR)">Virtual Reality (VR)</option>
        <option value="Other">Other</option>
        </select>

    </div>
    );
}

export default Industry;
