import ACTION_TYPES from "../../actionTypes"
import axios from "axios";


export const logoutSuccess = (userData) => {
    return{
        type: ACTION_TYPES.LOGOUT_SUCCESS,
        payload: false
    }
}

export const logoutFailure = (error) => {
    return {
        type: ACTION_TYPES.LOGOUT_FAILURE,
        payload: error
    }
}



export const logout = () => {
    return async (dispatch) => {
        try {
            // Solicitud al backend para eliminar la cookie
            await axios.post('/logout', {}, { withCredentials: true });

            dispatch(logoutSuccess());
        } catch (error) {
            dispatch(logoutFailure())
        }
    };
};

// dispatch(authenticatedSuccess(userData));
// } catch (error) {
//     dispatch(authenticatedFailure(error.message));


// export const logout = () => {
//     return{
//         type: LOGOUT,
//         payload: false
//     }
// }