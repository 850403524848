import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { updateUserInformation } from '../../redux/actions/User/updateUserInfo';
import CreateCandidateForm from '../../components/CreateCandidate/CreateCandidateForm';
import validation from '../../components/CreateCandidate/validation';
import { toast, ToastContainer } from 'react-toastify';
import { Country, State } from 'country-state-city'


const INITIAL_FORM = {
  firstName: '',
  lastName: '',
  email: '',
  location: '',
  experienceDescription: '',
  role: '',
  technicalLevel: '',
  yearsOfExperience: '',
  softSkills: [],
  tools: [],
  mainTech: [],
  secondaryTech: [],
  educationalLevel: '',
  certifications: '',
  englishLevel: '',
  languages: [],
  contractType: '',
  workScheme: '',
  contractScheme: '',
  relocation: '',
  travelAvailability: '',
  salaryExpectations: '',
  currentBenefits: '',
  timeZones: '',
  referencesLink: '',
  country:'',
  resume: null
}

const CreateCandidate = () => {

  const user = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form, setForm] = useState(INITIAL_FORM);
  const [errors, setErrors] = useState({});
  const [stateOptions, setStateOptions] = useState({})
  const [isStateDisabled, setIsStateDisable] = useState(true)
  

  const latamCountries = [
    'Argentina', 'Bolivia', 'Brazil', 'Chile', 'Colombia', 'Costa Rica', 'Cuba', 
    'Dominican Republic', 'United States', 'Ecuador', 'El Salvador', 'Guatemala', 'Honduras', 
    'Mexico', 'Nicaragua', 'Panama', 'Paraguay', 'Peru', 'Puerto Rico', 
    'Uruguay', 'Venezuela'
  ];
  //Use memo se utiliza para los calculos innecesarios, guarda respuestas
  const options = useMemo(() => {
    const allCountries = Country.getAllCountries().map(c => {
      const country = {value: c.isoCode,label:c.name}
      return country
    });
    
    
    return allCountries.filter(country => latamCountries.includes(country.label));
  }, []);


  const handleCountryChange = (selectedOption) => {
    
    setForm({ ...form, country: selectedOption.label, location: '' });
    
    const states = State.getStatesOfCountry(selectedOption.value).map(s => {
      return { value: s.isoCode, label: s.name } 
    }); 

    setStateOptions(states)
    setIsStateDisable(false)
  };

  const handleStateChange = (selectedOption) => {
    setForm({ ...form, location: selectedOption.label });
  };

  

  useEffect(() => {
      if (user && user.isCompleteInfo) {
          navigate('/');
      }
  }, [user, navigate]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "experienceDescription" && value.length > 1200) {
      return;
    }
    setForm({ ...form, [name]: value });
  };
  
  const limitExperienceDescription = (event) => {
    const maxLength = 1200;
    if (event.target.value.length > maxLength) {
      event.target.value = event.target.value.slice(0, maxLength);
    }
  };

  const handleSelectChange = (selectedOptions, { name }) => {
    setForm({ ...form, [name]: selectedOptions.map(option => option.value) });
  }



  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedExtensions = ['application/pdf'];
  
    if (file && allowedExtensions.includes(file.type)) {
      setForm({
        ...form,
        resume: file
      });
    } else {
      alert('Please upload a valid file (PDF)');
      event.target.value = null; // Reiniciar el input de archivo
    }
  }



  const handleForm = async (event) => {
    event.preventDefault();
    const err = validation(form);
    if (err === null) {

      try {
        setErrors({})

      const formData = new FormData();
      formData.append('firstName', form.firstName);
      formData.append('lastName', form.lastName);
      formData.append('email', form.email);
      formData.append('location', form.location);
      formData.append('experienceDescription', form.experienceDescription);
      formData.append('role', form.role);
      formData.append('technicalLevel', form.technicalLevel);
      formData.append('yearsOfExperience', form.yearsOfExperience);
      formData.append('country', form.country);
      formData.append('educationalLevel', form.educationalLevel);
      formData.append('certifications', form.certifications);
      formData.append('englishLevel', form.englishLevel);
      formData.append('contractType', form.contractType);
      formData.append('workScheme', form.workScheme);
      formData.append('contractScheme', form.contractScheme);
      formData.append('relocation', form.relocation);
      formData.append('travelAvailability', form.travelAvailability);
      formData.append('salaryExpectations', form.salaryExpectations);
      formData.append('currentBenefits', form.currentBenefits);
      formData.append('timeZones', form.timeZones);
      formData.append('referencesLink', form.referencesLink);
      formData.append('userId', user.id);
      formData.append('resume', form.resume); 
      form.softSkills.forEach((skill) => {
        formData.append('softSkills', skill);
      });

      form.tools.forEach((tool) => {
        formData.append('tools', tool);
      });
      
      form.mainTech.forEach((tech) => {
        formData.append('mainTech', tech);
      });
      
      form.secondaryTech.forEach((tech) => {
        formData.append('secondaryTech', tech);
      });
      
      form.languages.forEach((language) => {
        formData.append('languages', language);
      });





      const response = await axios.post('/complete-candidate-information', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.status === 200) {
        toast.success('If you have any problems after completing the information, please log in again.',
          {
            position: "top-right",
            autoClose: 3000,
            onClose: () => {
              dispatch(updateUserInformation(user.id))
              setForm(INITIAL_FORM)
            }
          }
        )
        
      }

        
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.error);
          } else {
            toast.error("An error occurred in the request. Please try again.");
          }
      }
    } else {
      setErrors(err);
      toast.error("First fix the mistakes");
    }
  }


  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl w-full space-y-8 bg-white p-10 rounded-xl shadow-lg">
        <div className="text-center">
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
            Create Candidate Profile
          </h2>
        </div>
        {/* Aqui va el Form */}
        <CreateCandidateForm
        stateOptions= {stateOptions}
        isStateDisabled={isStateDisabled}
        options= {options}
        form= {form}
        errors= {errors}
        handleChange= {handleChange}
        limitExperienceDescription= {limitExperienceDescription}
        handleSelectChange= {handleSelectChange}
        handleCountryChange= {handleCountryChange}
        handleFileChange= {handleFileChange}
        handleForm= {handleForm}
        handleStateChange= {handleStateChange}
        />
      </div>
      <ToastContainer />
    </div>
  );
}

export default CreateCandidate;