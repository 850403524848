import Navbar from './components/Navbar/Navbar'
import Landing from './view/Landing/Landing'
import SignIn from './view/SignIn/SignIn'
import SignUp from './view/SignUp/SignUp'
import { Routes, Route, useLocation } from 'react-router-dom'
import ServiceHardwareSolution from './view/ServicesViews/ServiceHardwareSolution/ServiceHardwareSolution'
import ServiceBorderlessCashflow from './view/ServicesViews/ServiceBorderlessCashflow/ServiceBorderlessCashflow'
import ServerSmartMatching from './view/ServicesViews/ServiceSmartMatching/ServiceSmartMatching'
import Footer from './components/Footer/Footer'
import JobPositionsList from './view/JobPositionsList/JobPositionsList'
import JobDescription from './view/JobDescription/JobDescription'
import HireTalent from './view/HireTalent/HireTalent'
import ProfileCandites from "./view/Profile/ProfileCandidates"
import useAuth from './hooks/useAuth'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import JobRegisterForm from "./view/FormJob/FormJob"
import CompleteCompanyProfile  from "./view/CompleteCompanyProfile/CompleteCompanyProfile"
import backgroundImage from "./assets/LANDING/Fondo-02.png"
import YourJob from "./view/YourJob/YourJob"
import CandidateDetail from './view/CandidateDetail/CandidateDetail'
import MyApplication from './view/MyApplication/MyApplication'
import EditCandidateProfile from './view/EditCandidateProfile/EditCandidateProfile'
import CreateCandidate from './view/CreateCandidate/CreateCandidate'
import Infoalert from './components/Infoalert/Infoalert'
import { useSelector } from 'react-redux'
import CompanyProfile from './view/CompanyProfile/CompanyProfile'
import AdminJobList from './view/AdminJobList/AdminJobList'
import EvaluationReporting from './view/EvaluationReporting/EvaluationReporting'
import VacancyDetailFC from './view/VacancyDetailFC/VacancyDetailFC'
import JobDescriptionFA from './view/JobDescriptionFA/JobDescriptionFA'
import EditCompanyProfile from './view/EditCompanyProfile/EditCompanyProfile'
import CompaniesForAdmin from './view/CompaniesForAdmin/CompaniesForAdmin'
import CompaniesJobsForAdmin from './view/CompaniesForAdmin/CompaniesJobsForAdmin/CompaniesJobsForAdmin'
import CompanyJobDetailForAdmin from './view/CompaniesForAdmin/CompanyJobDetailForAdmin/CompanyJobDetailForAdmin'
import CompanyReportEVForAdmin from './view/CompaniesForAdmin/CompanyReportEVForAdmin/CompanyReportEVForAdmin'
import VacancyView from './view/VacancyView/VacancyView'
import VacancyEdit from './view/VacancyEdit/VacancyEdit'
import CandidateRecommendationList from './view/CandidateCard/CandidateRecommendationList'
import Challenge from './view/Challenge/CandidateChallenge'
import ChallengeSent from './view/ChallegeSent/ChallegeSent'
import PricingTable from './view/Pricing/Pricing'
import ReportChallege from './view/ReportChallege/ReportChallege'
import ResetPassword from './view/ResetPassword/ResetPassword'



// import './App.css'


function App() {
  const user = useSelector(state => state.user)
  const location = useLocation()

  const { isLoading } = useAuth();


  return (
    <>
      <div className="min-h-screen bg-cover bg-center font-sans flex flex-col" style={{ backgroundImage: `url(${backgroundImage})` }}>
      {/* Navbar*/}
      <Navbar />
      {/* Routes */}
      {!user.isCompleteInfo && user.isAuthenticated && location.pathname !== "/complete-company-profile" && location.pathname !== "/create-candidate" && location.pathname !== "/register-recluter"  ? (<Infoalert user={user}/>) : null}
      

      <Routes>
          <Route path='/' element={<Landing />} />
          
          {/* Free Routes */}
          <Route path='/signin' element={<SignIn />} />
          <Route path='/signup' element={<SignUp />} />
          <Route path='/pricing' element={<PricingTable />} />
          <Route path='/service-hardware-solution' element={<ServiceHardwareSolution />} />
          <Route path='/service-borderless-cashflow' element={<ServiceBorderlessCashflow />}/>
          <Route path='/service-smart-matching' element={<ServerSmartMatching />}/>
          <Route path='/job-positions-list' element={<JobPositionsList />}/>
          <Route path='/job-description/:id' element={<JobDescription />}/>
          <Route path='/reset-password/:token' element= {<ResetPassword />}/>
          
          
          {/* Admin routes*/}
          <Route path='/admin-jobs' element={<PrivateRoute requiredRole='Admin-FLAS-1721' isLoading={isLoading}><AdminJobList/></PrivateRoute>} />
          <Route path='/job-description-fa/:id' element={<PrivateRoute requiredRole='Admin-FLAS-1721' isLoading={isLoading}><JobDescriptionFA/></PrivateRoute>} />
          <Route path='/admin-companies' element={<PrivateRoute requiredRole='Admin-FLAS-1721' isLoading={isLoading}><CompaniesForAdmin /></PrivateRoute>} />
          <Route path='/admin-companies-jobs/:idCompany' element={<PrivateRoute requiredRole='Admin-FLAS-1721' isLoading={isLoading}><CompaniesJobsForAdmin /></PrivateRoute>} /> 
          <Route path='/admin-companies-job-detail/:id' element={<PrivateRoute requiredRole='Admin-FLAS-1721' isLoading={isLoading}><CompanyJobDetailForAdmin /></PrivateRoute>} />
          <Route path='/admin-reporting-ev/:candidateId/:jobId' element={<PrivateRoute requiredRole='Admin-FLAS-1721' isLoading={isLoading}><CompanyReportEVForAdmin /></PrivateRoute>} />

          
          {/* Routes for candidates */}
          <Route path='/my-application' element={<PrivateRoute requiredRole='Candidate' isLoading={isLoading}><MyApplication /></PrivateRoute>}/>
          <Route path='/profile-candidates' element={<PrivateRoute requiredRole='Candidate' isLoading={isLoading}><ProfileCandites /></PrivateRoute>}/>
          <Route path='/create-candidate' element={<PrivateRoute requiredRole='Candidate' isLoading={isLoading}><CreateCandidate /></PrivateRoute>} />
          <Route path='/edit-profile/:id' element={<PrivateRoute requiredRole='Candidate' isLoading={isLoading}><EditCandidateProfile /></PrivateRoute>} />
          <Route path='/challenge/:id' element={<PrivateRoute requiredRole='Candidate' isLoading={isLoading}><Challenge /></PrivateRoute>} />
          <Route path='/challenge-sent' element={<PrivateRoute requiredRole='Candidate' isLoading={isLoading}><ChallengeSent /></PrivateRoute>} />

          {/* Routes for companies */}
          <Route path='/vacancy-detail-fc/:id' element={<PrivateRoute requiredRole='Company' isLoading={isLoading}><VacancyDetailFC /></PrivateRoute>} />
          <Route path='/candidate-detail/:id' element={<PrivateRoute requiredRole='Company' isLoading={isLoading}><CandidateDetail /></PrivateRoute>} />
          <Route path='/hire-talent' element={<PrivateRoute requiredRole='Company' isLoading={isLoading}><HireTalent /></PrivateRoute>} />
          <Route path='/register-job' element={<PrivateRoute requiredRole='Company' isLoading={isLoading}><JobRegisterForm /></PrivateRoute>} />
          <Route path='/complete-company-profile' element={<PrivateRoute requiredRole='Company' isLoading={isLoading}><CompleteCompanyProfile /></PrivateRoute>} />
          <Route path='/company-profile' element={<PrivateRoute requiredRole='Company' isLoading={isLoading}><CompanyProfile /></PrivateRoute>} />
          <Route path='/your-job/:id' element={<PrivateRoute requiredRole='Company' isLoading={isLoading}><YourJob /></PrivateRoute>} />
          <Route path='/reporting-ev/:candidateId/:jobId' element={<PrivateRoute requiredRole='Company' isLoading={isLoading}><EvaluationReporting/> </PrivateRoute>} />
          <Route path='/recomendation-list/:id' element={<PrivateRoute requiredRole='Company' isLoading={isLoading}><CandidateRecommendationList /></PrivateRoute>} />
          <Route path='/edit-company-profile/:id' element={<PrivateRoute requiredRole='Company' isLoading={isLoading}><EditCompanyProfile /></PrivateRoute>} />
          <Route path='/vacancy-view/:id' element={<PrivateRoute requiredRole='Company' isLoading={isLoading}> <VacancyView /></PrivateRoute>} />
          <Route path='/vacancy-edit/:id' element={<PrivateRoute requiredRole='Company' isLoading={isLoading}> <VacancyEdit /></PrivateRoute>} />
          <Route path='/report-challenge/:candidateId/:jobId' element={<PrivateRoute requiredRole='Company' isLoading={isLoading}> <ReportChallege /></PrivateRoute>} />
      </Routes>

      <Footer />
    </div>
    </>
  )
}

export default App

