import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCandidateById } from '../../redux/actions/Recruiter/getCandidateById';
import CandidateProfilePicture from '../../components/CandidateProfilePicture/CandidateProfilePicture';
import CandidateDetailsGrid from '../../components/CandidateDetailsGrid/CandidateDetailsGrid';
import CandidateInfo from '../../components/CandidateInfo/CandidateInfo';
import { getJobsForInviteCandidate } from '../../redux/actions/Jobs/getJobsForInviteCandidate';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CandidateDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const candidate = useSelector(state => state.candidate);
  const jobsFIC = useSelector(state => state.jobsFIC || []); // Estos es jobs
  const user = useSelector(state => state.user)

  const [showModal, setShowModal] = useState(false);
  const [selectedVacancy, setSelectedVacancy] = useState('');

  useEffect(() => {
    dispatch(getCandidateById(id));
  
  }, [id, dispatch]);

  useEffect(()=>{
    if (user && candidate) {
      dispatch(getJobsForInviteCandidate(candidate.id, user.id))
    }
  },[user, candidate])

  const toggleModal = () => setShowModal(!showModal);

  const handleInvite = async () => {
    console.log(`Candidate invited to: ${selectedVacancy}`);
    //Selected vacancy es el id del trabajo osea jobId
    try {
      const response = await axios.post('/invite-candidate-to-job', {
        userId: user.id,
        jobId: selectedVacancy,
        candidateId: candidate.id
      });
      if (response.status === 200) {
        toast.success('Candidate invited successfully');
        setShowModal(false); // Cierra el modal después de invitar
      }
    } catch (error) {
      if (error.response) {
        setShowModal(false)
        toast.error(error.response.data.error || 'An error occurred.');
      } else {
        setShowModal(false)
          toast.error("An error occurred in the request. Please try again.");
      }
    }
    // { userId,jobId, candidateId } 
    // setShowModal(false); // Cierra el modal después de invitar
  };

  if (!candidate) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p className="text-xl font-semibold text-gray-700">Loading candidate details...</p>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 min-h-screen flex items-center justify-center p-8">
      <div className="container mx-auto">
        <div className="bg-white p-12 rounded-lg shadow-2xl max-w-6xl mx-auto">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold text-gray-900">Profile</h1>
          </div>
          <div className="flex items-center mb-8 justify-between">
            <div className='flex flex-wrap'>
              <CandidateProfilePicture candidate={candidate} />
              <CandidateInfo candidate={candidate} />
            </div>
            
            <div>
              <button
                className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
                onClick={toggleModal}
              >
                Invite to vacancy
              </button>
            </div>
          </div>

          <CandidateDetailsGrid candidate={candidate} />

          {/* Modal para invitar al candidato */}
          {showModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
                <h3 className="text-xl font-semibold mb-4">Select a Vacancy to Invite</h3>

                {/* Select para elegir la vacante */}
                <select
                  value={selectedVacancy}
                  onChange={(e) => setSelectedVacancy(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-lg mb-4"
                >
                  <option value="" disabled>Select a vacancy</option>
                  {jobsFIC.length > 0 ? (
                    jobsFIC.map((job) => (
                      <option key={job.id} value={job.id}>{job.jobName}</option>
                    ))
                  ) : (
                    <option disabled>No vacancies available</option>
                  )}
                </select>

                <div className="flex justify-end space-x-4">
                  <button
                    className="bg-gray-400 text-white px-4 py-2 rounded-lg hover:bg-gray-500"
                    onClick={toggleModal}
                  >
                    Cancel
                  </button>
                  { selectedVacancy  ? (<button
                    className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700"
                    onClick={handleInvite}
                  >
                    Invite
                  </button>):null}
                  
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CandidateDetail;
