import React from 'react';
import { Link } from 'react-router-dom';
import { FaLinkedin, FaFacebookF, FaInstagram, FaYoutube } from 'react-icons/fa';
import logo from '../../assets/Logo/simbolo-SF-04.png';

const Footer = () => {
  return (
    <div className="bg-blue-700 text-white py-10 px-6 md:px-20">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center space-y-8 md:space-y-0">
        {/* Logo and Title */}
        <div className="flex items-center space-x-4">
          <img src={logo} alt="Rysconnect Logo" className="h-12 w-12" />
          <span className="text-2xl font-bold">Rysconnect</span>
        </div>
        
        {/* Info Section */}
        <div className="text-center md:text-left md:max-w-xs">
          <p className="text-lg font-semibold">Looking for more information?</p>
          <p className="text-sm opacity-80">Join us now and enjoy exclusive loyalty benefits.</p>
        </div>
        
        {/* Social Media Links */}
        <div className="text-center md:text-right">
          <p className="text-lg font-semibold mb-3">Follow Us:</p>
          <div className="flex justify-center md:justify-end space-x-4">
            <a target="_blank" href="https://www.youtube.com/@rysconnect" className="text-white hover:text-red-500 transition">
              <FaYoutube size={28} />
            </a>
            <a target="_blank" href="https://www.linkedin.com/company/rysconnect-io/" className="text-white hover:text-blue-300 transition">
              <FaLinkedin size={28} />
            </a>
            <a target="_blank" href="https://www.facebook.com/profile.php?id=61555537606972" className="text-white hover:text-blue-500 transition">
              <FaFacebookF size={28} />
            </a>
            <a target="_blank" href="https://www.instagram.com/rysconnect.io/" className="text-white hover:text-pink-500 transition">
              <FaInstagram size={28} />
            </a>
          </div>
        </div>
      </div>
      
      {/* Bottom Separator */}
      <div className="mt-10 border-t border-white opacity-20"></div>
      
      {/* Footer Bottom Text */}
      <div className="mt-6 text-center text-sm opacity-70">
        &copy; 2024 Rysconnect. All Rights Reserved.
      </div>
    </div>
  );
};

export default Footer;

