import React from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import ErrorAlert from './ErrorAlert';

const PasswordInput = ({ id, value, onChange, showPassword, togglePasswordVisibility, error }) => (
    <div className="mt-3 relative">
        <label htmlFor={id} className="sr-only">Password</label>
        {error && (
            <ErrorAlert message={error} />
        )}
        <div className="relative">
            <input
                id={id}
                name={id}
                type={showPassword ? "text" : "password"}
                value={value}
                onChange={onChange}
                required
                className="block w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm pr-10"
                placeholder="Password"
            />
            <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 px-3 text-gray-500 z-20 focus:outline-none"
                tabIndex="-1"
            >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
        </div>
    </div>
);

export default PasswordInput;
