import React from 'react';
import Select from 'react-select';

const PerksBenefits = ({form, onHandleClickTags, perksSelect, handleChangeSelects}) => {
    return (
        <div className="col-span-1">
        <label className="block text-gray-700">Perks and Benefits</label>
        <div className='flex flex-column justify-center items-center flex-wrap'>
                {form?.perksAndBenefits.length > 0 && form.perksAndBenefits.map((perk) => {
                    return (
                    <div key={perk} className='bg-blue-600 text-white rounded-xl p-1 m-1 cursor-pointer' onClick={() => onHandleClickTags({data:perk, type:'perksAndBenefits'})}>
                        <span>{perk}</span>
                    </div>
                    )
                })}
        </div>
        <select name="perksAndBenefits" className="block w-full p-2 pl-4 pr-12 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white" onChange={handleChangeSelects} value={perksSelect}>
        <option value="" disabled>Select...</option>
        <option value="Savings fund">Savings fund</option>
        <option value="Paid Time Off (PTO)">Paid Time Off (PTO)</option>
        <option value="Food vouchers">Food vouchers</option>
        <option value="Major medical insurance">Major medical insurance</option>
        <option value="Superior vacations">Superior vacations</option>
        <option value="Productivity bonus">Productivity bonus</option>
        <option value="Life insurance">Life insurance</option>
        <option value="Access to medical services">Access to medical services</option>
        <option value="Access to trainning platforms">Access to trainning platforms</option>
        <option value="Flexible working hours">Flexible working hours</option>
        <option value="Budget for tech books">Budget for tech books</option>
        <option value="Budget for trainning">Budget for trainning</option>
        <option value="Work equipment">Work equipment</option>
        <option value="Paid certifications">Paid certifications</option>
        </select>




    </div>
    );
}

export default PerksBenefits;
